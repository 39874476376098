<template>
  <div>
    <b-form-group
      v-for="filteredComment in commentsFilteredByChannel"
      :key="filteredComment.id"
      ref="commentConsultation">
      <div class="comment-box-height d-flex flex-column">
        <h6>
          <b>{{ commentTitle(filteredComment) }}</b>
          <b-button
            v-if="canEdit(filteredComment.id)"
            variant="outline"
            class="btn-icon"
            @click="updateComment(filteredComment)">
            <b-row>
              <v-icon
                name="pen" />
            </b-row>
          </b-button>
        </h6>
        <div
          v-if="!isEditingComment(filteredComment)"
          class="multiline flex-grow-1 overflow-auto">
          {{ filteredComment.value }}
        </div>
        <b-form-textarea
          v-if="isEditingComment(filteredComment)"
          v-model="currentComment.value"
          rows="3"
          @input="emitInputEvent" />
        <div
          v-if="isEditingComment(filteredComment)"
          class="d-flex justify-content-between mt-3">
          <b-link
            class="fa-pull-left align-middle"
            @click="resetValueToDefault">
            {{ $t('cancel') }}
          </b-link>
          <b-button
            :disabled="isCommentEmpty"
            @click="$emit('save')">
            {{ $t('save') }}
          </b-button>
        </div>
      </div>
    </b-form-group>
    <b-form-group
      v-if="!currentComment && canUpdate && !isUpdateDiagnostic"
      ref="commentEdition"
      :label="$t('formComment')">
      <b-input-group>
        <b-form-textarea
          ref="commentInput"
          v-model="value"
          rows="3"
          @input="emitInputEvent" />
      </b-input-group>
    </b-form-group>
    <b-form-group
      v-if="!currentComment && !isUpdateDiagnostic">
      <b-row class="d-flex justify-content-between align-items-center">
        <b-form-checkbox
          v-model="isFilterCommentsOnActiveChannel">
          {{ $t('FilterOnActiveChannel') }}
        </b-form-checkbox>
        <b-button
          v-if="canUpdate"
          ref="saveButton"
          :disabled="isCommentEmpty"
          @click="$emit('save')">
          {{ $t('save') }}
        </b-button>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';

import Comment from '../../../../models/Comment';
import SecurityMixin from '@/mixins/SecurityMixin';
import { mapFields } from 'vuex-map-fields';

export default {
  mixins: [
    SecurityMixin
  ],
  props: {
    comments: {
      required: true,
      validator: prop => Array.isArray(prop) || prop === null
    },
    wellId: {
      type: Number,
      default: undefined
    },
    isUpdateDiagnostic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCommentEmpty: true,
      currentComment: undefined,
      value: undefined
    };
  },
  computed: {
    ...mapFields('runImport', [
      'isFilterCommentsOnActiveChannel'
    ]),
    commentsFilteredByChannel() {
      return sortBy(this.comments, 'creationDateTime');
    },
    canUpdate() {
      return this.comments?.length ? (this.canUpdateRun() || this.currentComment) : this.canUpdateRun();
    }
  },
  methods: {
    resetValueToDefault() {
      this.currentComment = undefined;
      this.value = undefined;
      this.isCommentEmpty = true;
    },
    canEdit(commentId) {
      return this.comments?.length ? this.canUpdateRun() && this.canEditComment(commentId, this.wellId) : (!this.isCommentEmpty && this.canUpdateRun());
    },
    updateComment(comment) {
      this.currentComment = cloneDeep(comment);
    },
    commentTitle(comment) {
      return comment ? `${comment.technicianName} - ${this.$d(comment.creationDateTime, 'dateTime')}` : '';
    },
    isEditingComment(comment) {
      return this.currentComment?.id === comment?.id;
    },
    emitInputEvent(commentValue) {
      this.isCommentEmpty = commentValue.length === 0;

      const commentToEmit = this.currentComment?.id
        ? new Comment(this.currentComment.id, this.currentComment.technicianName, this.currentComment.creationDateTime, commentValue)
        : Comment.build({ value: commentValue });
      this.$emit('input', commentToEmit);
    }
  }
};
</script>