export default class Laboratory {
  constructor(id, name, active) {
    this.id = id;
    this.name = name;
    this.active = active;
  }

  static build({ id, name, active }) {
    return new Laboratory(id, name, active);
  }

  static parse(value) {
    return value ? Laboratory.build({
      id: value.id,
      name: value.name,
      active: value.active
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      active: this.active !== undefined ? this.active : null
    };
  }
}
