<template>
  <div
      role="button"
      @click="onClick($event)">
    <span class="no-selection">
      <slot />
    </span>
  </div>
</template>

<script>
import PlateSelectableCellMixin from '@/mixins/PlateSelectableCellMixin';

export default {
  mixins: [ PlateSelectableCellMixin ]
};</script>
