import Point from './Point';

export default class CharData {
  constructor(type, chartPoints, peaks) {
    this.type = type;
    this.chartPoints = chartPoints;
    this.peaks = peaks;
  }

  static build({ type, chartPoints, peaks }) {
    return new CharData(type, chartPoints, peaks);
  }

  static parse(value) {
    return value ? CharData.build({
      type: value.type,
      chartPoints: value.chartPoints ? value.chartPoints.map(point => Point.parse(point)) : [],
      peaks: value.peaks ? value.peaks.map(peak => Point.parse(peak)) : []
    }) : null;
  }
}
