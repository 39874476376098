<template>
  <div>
    <b-form-group>
      <div class="text-lg-center">
        <h5>
          {{ $t('diagnosticConfigIntro') }}
        </h5>
      </div>
    </b-form-group>
    <b-form-group>
      <b-form-row
        v-for="(input, index) in diagnosticRows"
        :key="input.uuid">
        <b-col lg="4">
          <b-form-group :label="index === 0 ? $t('formKit') : undefined">
            <app-kit-editable-multiselect
                v-model="input.kit"
                label="name"
                :overriding-options="currentKits"
                @input="resetDiagnostics(index, input)"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group :label="index === 0 ? $t('formDiagnosticHeader') : undefined">
            <app-diagnostic-dropdown
              ref="diagnosticDropdown"
              v-model="input.diagnosticColor"
              label="name"
              :translated="true"
              :overriding-options="input.diagnosticColors"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group :label="index === 0 ? $t('label') : undefined">
            <b-input
              v-model="input.label" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :label="index === 0 ? 'Remove / Add' : undefined">
            <b-button
              ref="removeButton"
              :disabled="diagnosticRows.length === 0"
              variant="outline"
              class="btn--no-outline btn-icon btn-icon-minus"
              @click="removeDiagnosticInputLine(input)">
              <font-awesome-icon
                :icon="['fas', 'minus-circle']" />
            </b-button>
            <b-button
              v-if="index === diagnosticRows.length - 1"
              ref="addButton"
              variant="outline"
              class="btn--no-outline btn-icon btn-icon-plus"
              @click="addDiagnosticInputLine">
              <font-awesome-icon
                :icon="['fas', 'plus-circle']" />
            </b-button>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row
        class="form-button">
        <b-button
          ref="saveButton"
          @click="save">
          {{ $t('save') }}
        </b-button>
      </b-form-row>
    </b-form-group>
  </div>
</template>

<script>

import {findAllKits, getDiagnosticColorsByKit, getDiagnosticLabels, updateDiagnostic} from '@/service/GenefoxService';
import NotificationMixin from '@/mixins/NotificationMixin';
import KitEditableMultiselect from '../../common/KitEditableMultiselect';
import DiagnosticDropdown from '../../common/DiagnosticDropdown';
import {v4 as uuidv4} from 'uuid';
import cloneDeep from "lodash/cloneDeep";

export default {
  name: 'ConfigurationFormDiagnostic',
  components: {
    'app-diagnostic-dropdown': DiagnosticDropdown,
    'app-kit-editable-multiselect': KitEditableMultiselect
  },
  mixins: [ NotificationMixin ],
  data() {
    return {
      diagnosticRows: [ { uuid: uuidv4(), id: null, diagnosticColors: [], kit: null, label: null, diagnosticColor: null } ],
      currentKits: []
    };
  },
  beforeMount() {
    getDiagnosticLabels()
        .then(diagnosticLabels => {
          this.diagnosticRows = this.buildDiagnosticRows(diagnosticLabels);
    });
    findAllKits(true, false).then(kits => this.currentKits = kits);
  },
  methods: {
    addDiagnosticInputLine() {
      this.diagnosticRows.push({ uuid: uuidv4(), id: null, diagnosticColors: [], kit: null, label: null, diagnosticColor: null });
    },
    setDiagnosticColors(input) {
      if(input?.kit?.id) {
        getDiagnosticColorsByKit(input.kit.id)
            .then(diagnosticsColors => {
              input.diagnosticColors = diagnosticsColors;
            })
      }
    },
    resetDiagnostics(index, input) {
      this.diagnosticRows[index].label = null;
      this.diagnosticRows[index].diagnosticColors = [];
      this.setDiagnosticColors(input);

    },
    removeDiagnosticInputLine({ uuid }) {
      const index = this.diagnosticRows.findIndex(row => row.uuid === uuid);
      this.diagnosticRows.splice(index, 1);
      if (this.diagnosticRows.length === 0) {
        this.addDiagnosticInputLine();
      }
    },
    save() {
      let notComplete;
      if(!this.areDiagnosticsUnique()) {
        this.showErrorNotification(this.$t('infoTitleCannotSaveWithNoKitToAddOrDelete'), this.$t('errorDuplicateLabels'));
        return;
      }
      this.diagnosticRows.forEach(row => {
        if (!row.diagnosticColor || !row.label || !row.kit) {
          notComplete = true;
        }
      });
      if (notComplete) {
        if (!this.diagnosticRows[0].diagnosticColor
                  && !this.diagnosticRows[0].label
                  && !this.diagnosticRows[0].kits) {
          this.update(true);
        }
        else {
          this.showInfoNotification(this.$t('infoTitleCannotSaveWithNoKitToAddOrDelete'),
            this.$t('errorCannotSaveEmptyDiagnostic'));
        }
      }
      else {
        this.update(false);
      }
    },
    update(clear) {
      const self = this;
      const diagList = cloneDeep(this.diagnosticRows);
      if (clear) {
        diagList.splice(0, 1);
      }
      updateDiagnostic(diagList)
        .then(diagnosticsLabels => {
          self.diagnosticRows = this.buildDiagnosticRows(diagnosticsLabels);
          if (this.diagnosticRows.length === 0) {
            this.addDiagnosticInputLine();
          }
          this.showSuccessNotification(this.$t('formConfigureDiagnostic'), this.$t('successTitleConfigureDiagnostic'));
        })
        .catch(error => {
          this.showErrorNotification(this.$t('errorConfigureDiagnostic'), error);
        });
    },
    areDiagnosticsUnique() {
      const map = new Map();
      this.diagnosticRows.forEach(label => {
        const diagnosticName = label?.diagnosticColor?.diagnostic?.name;
        if(diagnosticName) {
          if (!map.has(diagnosticName)) {
            map.set(diagnosticName, []);
          }
          map.get(diagnosticName).push(label.kit);
        }
      });
      let isUnique = true;
      map.forEach(kits => {
        const kitNames = new Set();
        for (const kit of kits) {
          if (kitNames.has(kit.name)) {
            isUnique = false;
          }
          kitNames.add(kit.name);
        }
      });
      return isUnique;
    },
    buildDiagnosticRows(diagnosticsLabels) {
      const diagnosticLabelArray = [];
      if (diagnosticsLabels && diagnosticsLabels.length > 0) {
        diagnosticsLabels.forEach(diagnosticLabel => {
          let row = {
            uuid: uuidv4(),
            id: diagnosticLabel.id,
            diagnosticColors: [],
            kit:  diagnosticLabel.kit,
            label: diagnosticLabel.label,
            diagnosticColor: diagnosticLabel.diagnosticColor
          };
          this.setDiagnosticColors(row);
          diagnosticLabelArray.push(row);
        });
      }
      else {
        diagnosticLabelArray.push({
          uuid: uuidv4(),
          id: null,
          diagnosticColors: [],
          kit: null,
          label: null,
          diagnosticColor: null
        });
      }

      return diagnosticLabelArray;
    }
  }
};
</script>
