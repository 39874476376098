<template>
  <b-modal
    :id="id"
    :header-text-variant="headerTextVariant"
    :ok-only="true"
    :title="$t('modalReleaseNoteTitle') + ' v' + version + ' - ' + date"
    size="lg"
    @hide="hideReleaseModal">
    <span v-html="note"></span>
  </b-modal>
</template>

<script>
import ModalMixin from '../../mixins/ModalMixin';
import Release from "@/models/Release";
import {updateSeenRelease} from "@/service/GenefoxService";

export default {
  mixins: [ ModalMixin ],
  props: {
    release: {
      type: Release,
      default: null
    },
    notSeen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    version: '',
    date: '',
    note: ''
  }),
  watch: {
    release(newValue) {
      if (newValue) {
        this.version = newValue.version;
        this.note = newValue.note;
        this.date = newValue.date;
      }
    }
  },
  methods: {
    hideReleaseModal() {
      if (this.notSeen) {
        updateSeenRelease(this.release);
      }
      this.hideModal();
    }
  }
};
</script>