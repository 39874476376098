import Zone from "@/models/Zone";

export default class Combination {
  constructor(id, label, zones) {
    this.id = id;
    this.label = label;
    this.zones = zones;
  }

  static build({ id, label, zones }) {
    return new Combination(id, label, zones);
  }

  static parse(value) {
    return value ? new Combination(
      value.id,
      value.label,
      value.zones?.map(z => Zone.parse(z))
    ) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      label: this.label  !== undefined || this.label !== '' ? this.label : null,
      zones: this.zones  !== undefined || this.zones !== '' ? this.zones : null
    };
  }
}
