<template>
  <div
    v-if="analyzed"
    class="well-sample"
    :class="getWellSampleClass"
    :style="getStyle"
    @click="onClick($event)">
    <div :class="getWellSampleOptionClass"/>
    <div
      v-if="badged"
      class="well-badge"/>
    <div
      v-if="isIncomplete"
      :class="`incomplete-well ${plateType === PlateType.RECTANGULAR_96 ? 'plate-96' : 'plate-384'}`"
      @mouseover="mouseOverMissingParameter"
      @mouseleave="mouseLeaveMissingParameter">
      <span
        :ref="`missingParameterSpan`"
        :class="`missing-parameter ${positionCssClass}`"
        :hidden="true"
        v-html="missingParameter"/>
    </div>
  </div>
  <div
    v-else
    class="well-sample well-not-analyzed"/>
</template>

<script>
import { mapGetters } from 'vuex';
import DiagnosticColor from '@/models/DiagnosticColor';
import { mapFields } from 'vuex-map-fields';
import PlateSelectableCellMixin from '@/mixins/PlateSelectableCellMixin';
import PlateType from '../../../../models/PlateType';

export default {
  mixins: [ PlateSelectableCellMixin ],
  props: {
    well: {
      type: Object,
      default: undefined
    },
    selected: {
      type: Boolean,
      default: false
    },
    analyzed: {
      type: Boolean,
      default: true
    },
    badged: {
      type: Boolean,
      default: false
    },
    // Force sampleType
    forcedSampleType: {
      type: String,
      default: undefined
    },
    // Force diagnostic
    forcedDiagnostic: {
      type: DiagnosticColor | String,
      default: undefined
    },
    plateType: {
      type: PlateType,
      default: () => PlateType.RECTANGULAR_96
    }
  },
  computed: {
    PlateType() {
      return PlateType;
    },
    ...mapGetters('chart', [
      'getDiagnosticByWellId'
    ]),
    ...mapGetters('runImport', [
      'isRunInterpreted'
    ]),
    ...mapFields('runImport', [
      'run',
      'diagnosticColorByChannelRb'
    ]),
    sampleType() {
      return this.forcedSampleType ? this.forcedSampleType : this.well ? this.well.sampleType : null;
    },
    diagnosticColor() {
      return this.forcedDiagnostic ? this.forcedDiagnostic : this.well ? this.getDiagnosticByWellId(this.well.id) : null;
    },
    hasColor() {
      if (this.sampleType === 'POSITIVE_TEST' || this.sampleType === 'NEGATIVE_TEST' || this.sampleType === 'STANDARD') {
        return false;
      }
      const result = this.diagnosticColor;
      return result && result.diagnostic;
    },
    getWellSampleClass() {
      if (this.diagnosticColor?.color === 'transparent') {
        return {
          'well-sample-result-negative': true,
          'focused': this.selected
        };
      }
      let wellSampleClass = {
        'well-sample-positive-test': this.sampleType === 'POSITIVE_TEST',
        'well-sample-negative-test': this.sampleType === 'NEGATIVE_TEST',
        'well-sample-standard-test': this.sampleType === 'STANDARD',
        'focused': this.selected
      };

      if ((!this.isRunInterpreted || this.forcedSampleType)) {
        wellSampleClass = {
          'well-sample-blood': this.sampleType === 'BLOOD',
          'well-sample-dna': this.sampleType === 'DNA',
          ...wellSampleClass
        };
      }

      return wellSampleClass;
    },
    getWellSampleOptionClass() {
      let sampleOptionClass = {};

      if (this.forcedDiagnostic || this.isRunInterpreted) {
        let result = this.diagnosticColor;
        if (result?.diagnostic) {
          result = this.diagnosticName(result);
        }
        sampleOptionClass = {
          'well-sample-crossed': result === 'TEST_KO'
        };
      }

      return sampleOptionClass;
    },
    getStyle() {
      return this.hasColor ? `background: ${this.diagnosticColor.color}` : '';
    },
    isIncomplete() {
      if (this.selected) {
        return false;
      }
      let incomplete = this.well
                       && (this.well.kitVersion || this.well.lot || this.well.reactionBuffer || this.well.sampleType) // if one parameter is set
                       && !(this.well.kitVersion && this.well.lot && this.well.reactionBuffer && this.well.sampleType); // and some are not set
      if (!incomplete && this.well?.sampleType && this.well.sampleType === 'STANDARD') {
        this.run.analyses.forEach(analysis => {
          if (!this.diagnosticColorByChannelRb[analysis.channel]
            || !this.diagnosticColorByChannelRb[analysis.channel][this.well.reactionBuffer.name]?.length) {
            return;
          }
          const w = analysis.wells.get(this.well.position);
          if (!w?.expectedDiagnostic) {
            incomplete = true;
          }
        });
      }
      return incomplete;
    },
    missingParameter() {
      if (!this.well) {
        return '';
      }

      let missingParameter = [];
      if (!this.well.kitVersion) {
        missingParameter.push(this.$t('plate.missing.kit'));
      }
      if (!this.well.lot) {
        missingParameter.push(this.$t('plate.missing.lot'));
      }
      if (!this.well.reactionBuffer) {
        missingParameter.push(this.$t('plate.missing.reactionBuffer'));
      }
      if (!this.well.sampleType) {
        missingParameter.push(this.$t('plate.missing.sampleType'));
      } else if (this.well.sampleType === 'STANDARD') {
        if (this.well.kitVersion?.kit.channelAssignments?.length > 0) {
          this.run.analyses.forEach(analysis => {
            const w = analysis.wells.get(this.well.position);
            if (!w?.expectedDiagnostic) {
              const currentCa = w.kitVersion.kit.channelAssignments.find(ca => ca.channel === analysis.channel);
              if (currentCa?.label) {
                missingParameter.push(this.$t('plate.missing.expectedResult.target', [currentCa.label]));
              } else {
                missingParameter.push(this.$t('plate.missing.expectedResult.common'));
              }
            }
          });
        } else if (!this.well.expectedDiagnostic) {
          missingParameter.push(this.$t('plate.missing.expectedResult.common'));
        }
      }

      return missingParameter.length > 0 ? missingParameter.join('<br/>') : '';
    },
    positionCssClass() {
      let cssClass = parseInt(this.well.position.slice(1, this.well.position.length)) > this.plateType.columnCount / 2 ? 'left' : 'right';
      if (this.well.position.charCodeAt(0) - 64 > this.plateType.rowCount / 2) { // 65 == A, we want that A == 1. For A, charCodeAt returns 65 - 64 = 1
        cssClass += ' top';
      }

      return cssClass;
    }
  },
  methods: {
    diagnosticName(diagnosticColor) {
      if (diagnosticColor.diagnosticLabel) {
        return this.$t(diagnosticColor.diagnostic.name) + ' ' + diagnosticColor.diagnosticLabel;
      }
      return this.$t(diagnosticColor.diagnostic.name);
    },
    mouseOverMissingParameter() {
      this.$refs.missingParameterSpan.hidden = false;
    },
    mouseLeaveMissingParameter() {
      this.$refs.missingParameterSpan.hidden = true;
    }
  }
};
</script>
