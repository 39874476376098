import axios from 'axios';
import * as Qs from 'qs';

require('dotenv/config');

function createAxios(opt) {
  const api = axios.create(opt);
  api.interceptors.response.use(response => response, error => {
    if (error.response.status === 503) {
      window.location.reload();
    }

    return Promise.reject(error);
  });

  return api;
}
export const GenefoxApi = createAxios({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Custom-Information'
  },
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' })
});

export const GenefoxMultipartApi = createAxios({
  baseURL: '/api',
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Custom-Information'
  }
});

export const GenefoxDownloadFile = createAxios({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/zip',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Custom-Information'
  },
  responseType: 'blob'
});
