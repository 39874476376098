import { v4 as uuidv4 } from 'uuid';
import Zone from '@/models/Zone';
import Analyser from '@/models/Analyser';
import KitVersion from '@/models/KitVersion';

export const DEFAULT_SLOPE_THRESHOLD = 4.72;

export default class Phase {
  constructor(id, phaseType, minThreshold, slopeThreshold, minQuality, inversed, zones, analyser, kitVersion, configured) {
    this.id = id;
    this.phaseType = phaseType;
    this.minThreshold = minThreshold;
    this.slopeThreshold = slopeThreshold;
    this.minQuality = minQuality;
    this.inversed = inversed;
    this.zones = zones;
    this.analyser = analyser;
    this.kitVersion = kitVersion;
    this.configured = configured;
  }

  static build({ id, phaseType, minThreshold, slopeThreshold, minQuality, inversed, zones, analyser, kitVersion, configured }) {
    return new Phase(id, phaseType, minThreshold, slopeThreshold, minQuality, inversed, zones, analyser, kitVersion, configured);
  }

  static parse(value) {
    return value ? Phase.build({
      id: value.id,
      phaseType: value.phaseType,
      minThreshold: value.minThreshold,
      slopeThreshold: value.slopeThreshold,
      minQuality: value.minQuality,
      inversed: value.inversed,
      configured: value.configured,
      zones: value.zones?.map(zone => Zone.parse(zone)),
      analyser: Analyser.parse(value.analyser),
      kitVersion: KitVersion.parse(value.kitVersion)
    }) : null;
  }

  static empty() {
    return {
      uuid: uuidv4(),
      id: null,
      phaseType: null,
      minThreshold: null,
      slopeThreshold: DEFAULT_SLOPE_THRESHOLD,
      minQuality: null,
      configured: null,
      zones: [
        {
          uuid: uuidv4(),
          name: null,
          minThreshold: null,
          minQuality: null,
          minTemp: null,
          maxTemp: null,
          label: null,
          dataEnum: null
        }
      ]
    };
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      phaseType: this.phaseType !== undefined && this.phaseType !== '' ? this.phaseType : null,
      minThreshold: this.minThreshold !== undefined && this.minThreshold !== '' ? this.minThreshold : null,
      slopeThreshold: this.slopeThreshold !== undefined && this.slopeThreshold !== '' ? this.slopeThreshold : null,
      minQuality: this.minQuality !== undefined && this.minQuality !== '' ? this.minQuality : null,
      inversed: this.inversed !== undefined && this.inversed !== '' ? this.inversed : null,
      configured: this.configured !== undefined && this.configured !== '' ? this.configured : null,
      zones: this.zones !== undefined && this.zones !== '' ? this.zones : null,
      analyser: this.analyser !== undefined && this.analyser !== '' ? this.analyser : null,
      kitVersion: this.kitVersion  !== undefined || this.kitVersion !== '' ? this.kitVersion : null
    };
  }
}