export default {
  'en-GB': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    dateTime: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
    }
  }
};
