<template>
  <div>
    <b-form-row
      v-for="(input, index) in kitAndLotInputRows"
      :key="input.uuid">
      <b-col lg="6">
        <b-form-group :label="index === 0 ? $t('formKit') : undefined">
          <app-kit-editable-multiselect
            v-model="input.kit"
            :overriding-options="getKitOverridingOptions(input.kit)"
            :state="state"
            @select="findKitVersions($event, input)" />
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="index === 0 ? $t('formLot') : undefined">
          <app-lot-editable-multiselect
            v-model="input.lotSelection"
            :can-add="true"
            :multiple="true"
            :overriding-options="input.lots || []"
            :preselect-single-overriding-option="true"
            :state="state"
            @addLot="addLot($event, input.uuid)"
            @input="emitInputEvent" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="index === 0 ? 'Remove / Add' : undefined">
          <b-button
            ref="removeButton"
            :disabled="kitAndLotInputRows.length < 2"
            class="btn--no-outline btn-icon btn-icon-minus"
            variant="outline"
            @click="removeKitAndLotInputLine(input)">
            <font-awesome-icon
              :icon="['fas', 'minus-circle']" />
          </b-button>
          <b-button
            v-if="index === kitAndLotInputRows.length - 1"
            ref="addButton"
            class="btn--no-outline btn-icon btn-icon-plus"
            variant="outline"
            @click="addKitAndLotInputLine">
            <font-awesome-icon
              :icon="['fas', 'plus-circle']" />
          </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import KitEditableMultiselect from '../../../../../common/KitEditableMultiselect.vue';
import LotEditableMultiselect from '../../../../../common/LotEditableMultiselect';
import {v4 as uuidv4} from 'uuid';
import {mapState} from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import {findAllKitsWithLotByAnalyserId, findKitVersionsByAnalyser, saveLot} from '@/service/GenefoxService';
import Lot from '@/models/Lot';
import NotificationMixin from '@/mixins/NotificationMixin';
import SecurityMixin from '../../../../../../mixins/SecurityMixin';
import {findAllKitsByAnalyserId} from '../../../../../../service/GenefoxService';

export default {
  components: {
    'app-kit-editable-multiselect': KitEditableMultiselect,
    'app-lot-editable-multiselect': LotEditableMultiselect
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  props: {
    state: {
      // Tri-state prop: true, false, null (or undefined)
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      kitAndLotInputRows: [ {
        uuid: uuidv4(),
        kit: null,
        kitVersion: null,
        lotSelection: [],
        kitVersions: null,
        lots: null
      } ],
      kitOptions: []
    };
  },
  computed: {
    ...mapState('runImport', {
      analyserId: state => state.run?.analyser?.id
    })
  },
  beforeMount() {
    const promise = this.canReadKitConfiguration() ? findAllKitsByAnalyserId(this.analyserId) : findAllKitsWithLotByAnalyserId(this.analyserId);

    promise.then(kits => {
      this.kitOptions = kits;
    })
      .catch(error => this.showErrorNotification(this.$t('kitDropdownLoadingErrorTitle'), error));
  },
  methods: {
    addKitAndLotInputLine() {
      this.kitAndLotInputRows.push({
        uuid: uuidv4(),
        kit: null,
        kitVersion: null,
        lotSelection: [],
        kitVersions: null,
        lots: null
      });
    },
    removeKitAndLotInputLine({ uuid }) {
      const index = this.kitAndLotInputRows.findIndex(row => row.uuid === uuid);
      this.kitAndLotInputRows.splice(index, 1);
      this.emitInputEvent();
    },
    getKitOverridingOptions(kit) {
      let kitOverridingOptions = this.kitOptions;

      return kitOverridingOptions = kitOverridingOptions.filter(kitOption => !this.kitAndLotInputRows.map(row => row.kit).includes(kitOption) || kitOption === kit);
    },
    emitInputEvent() {
      this.verifyKitLotCompatibility();
      const completeRows = this.kitAndLotInputRows.filter(row => !!row.kit && !!row.lotSelection && !!row.kitVersion);
      const kitVersions = cloneDeep(completeRows).map(row => row.kitVersion);
      kitVersions.forEach(kitVersion => {
        const nonUniqRows = this.kitAndLotInputRows.filter(row => !!row.kitVersion && row.kitVersion.id === kitVersion.id && !!row.lotSelection);
        kitVersion.lots = nonUniqRows.flatMap(row => row.lotSelection);
      });
      this.$emit('input', kitVersions);
    },
    verifyKitLotCompatibility() {
      this.kitAndLotInputRows.forEach(row => {
        let isLotIncludeInKit = false;
        if (row.kitVersions) {
          if (!Array.isArray(row.lotSelection)) {
            row.lotSelection = row.lotSelection ? [ row.lotSelection ] : [];
          }
          row.kitVersions.forEach(kv => {
            row.lotSelection?.forEach(lot => {
              if (kv.lots?.find(l => l.name === lot.name)) {
                row.kitVersion = kv;
                isLotIncludeInKit = true;
              }
            });
          });
        }
        if (!isLotIncludeInKit) {
          row.lotSelection = null;
        }
      });
    },
    addLot(lotName, uuid) {
      const input = this.kitAndLotInputRows.find(row => row.uuid === uuid);
      if (!lotName) {
        this.showErrorNotification(this.$t('errorTitleAddingLot'), this.$t('errorNoLotName'));
      }
      if (!input.kit) {
        this.showErrorNotification(this.$t('errorTitleAddingLot'), this.$t('errorNoKitSelected'));
      }
      if (this.canReadKitConfiguration()) {
        const versionWithNoLotLinked = input.kitVersions.find(kv => !kv?.validityStartDate);
        input.kitVersion = versionWithNoLotLinked ? versionWithNoLotLinked : input.kitVersions[0];
      } else {
        input.kitVersion = input.kitVersions[0]; // First is the last version because they are sorted in the sql query
      }
      saveLot(input.kitVersion.id, new Lot(null, lotName, new Date(Date.UTC(2099, 11, 31)), true, true, true))
        .then(lot => {
          this.showSuccessNotification(this.$t('formLot'), this.$t('successAddingLot'));
          if(!input.kitVersion.lots.some(lotVersion => lot.name === lotVersion.name)) {
            input.kitVersion.lots.push(lot);
            const newLot = cloneDeep(input.kitVersion.lots.filter(newLot => newLot.name === lotName)[0]);
            newLot.kitVersion = input.kitVersion;
            input.lots.push(newLot);
          }

        })
        .catch(error => {
          console.error(error);
          this.showErrorNotification(this.$t('errorAddingLot'), error);
          input.kitVersion.lots.pop(); // Delete the last pushed element
        });
    },
    findKitVersions(kit, input) {
      findKitVersionsByAnalyser(kit.id, this.analyserId)
        .then(kitVersions => {
          input.kitVersions = kitVersions;
          input.kitVersions?.forEach(kitVersion => kitVersion.kit = kit);
          input.lots = kitVersions.flatMap(kv => {
            const clonedLots = cloneDeep(kv.lots);
            clonedLots.forEach(l => l.kitVersion = kv);

            return clonedLots;
          });
          this.emitInputEvent();
        })
        .catch(error => {
          this.showErrorNotification(this.$t('kitDropdownLoadingErrorTitle'), error);
        });
    },
    canReadKitConfiguration() {
      return this.hasRole('READ_KIT_CONFIG_ADMIN');
    }
  }
};
</script>
