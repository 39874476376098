<template>
  <multiselect
    v-model="model"
    :options="options"
    :custom-label="translatedOption"
    :placeholder="$t('formWellSortDirectionPlaceholder')"
    deselect-label=""
    :allow-empty="false"
    :loading="isLoading"
    :class="stateClass" />
</template>

<script>
import DropdownMixin from '@/mixins/DropdownMixin';

export default {
  mixins: [ DropdownMixin ],
  methods: {
    findOptions() {
      return new Promise(resolve => resolve([ 'ROW', 'COLUMN' ]));
    },
    translatedOption(option) {
      return this.$t(option);
    }
  }
};
</script>
