<template>
  <div
    class="hu-color-picker"
    :class="{ light: isLightTheme }">
    <div class="color-set">
      <Saturation
        ref="saturation"
        :color="rgbString"
        :hsv="hsv"
        :size="hueHeight"
        @selectSaturation="selectSaturation"
      />
      <Hue
        ref="hue"
        :hsv="hsv"
        :width="hueWidth"
        :height="hueHeight"
        @selectHue="selectHue"
      />
      <Alpha
        ref="alpha"
        v-if="!alphaHide"
        :color="rgbString"
        :rgba="rgba"
        :width="hueWidth"
        :height="hueHeight"
        @selectAlpha="selectAlpha"
      />
      <Colors
          ref="colors"
          :color="rgbaString"
          :colors-default="colorsDefault"
          :colors-history-key="colorsHistoryKey"
          @selectColor="selectColor"
      />
    </div>
<!--    <div v-if="!previewHide" :style="{ height: previewHeight + 'px' }" class="color-show">
      <Preview
        v-if="!previewHide"
        :color="rgbaString"
        :width="previewWidth"
        :height="previewHeight"
      />
      <Sucker
        v-if="!suckerHide"
        :sucker-canvas="suckerCanvas"
        :sucker-area="suckerArea"
        @openSucker="openSucker"
        @selectSucker="selectSucker"
      />
    </div>-->
    <div class="mt-2">
      <Box class="float-left" name="HEX" :color="modelHex" @inputColor="inputHex" />
      <Box class="float-right" name="RGBA" :color="modelRgba" @inputColor="inputRgba" />
    </div>
    <!-- custom options -->
    <slot></slot>
  </div>
</template>

<script>
import Saturation from './Saturation.vue'
import Hue from './Hue.vue'
import Alpha from './Alpha.vue'
import Preview from './Preview.vue'
import Sucker from './Sucker.vue'
import Box from './Box.vue'
import Colors from './Colors.vue'
import {setColorValue, rgb2hex} from "@/components/common/color-picker/composible.ts";

export default {
  components: {
    Saturation,
    Hue,
    Alpha,
    Preview,
    Sucker,
    Box,
    Colors,
  },
  props: {
    color: {
      type: String,
      default: 'rgba(0,0,0,0)'
    },
    theme: {
      type: String,
      default: 'dark'
    },
    alphaHide: {
      type: Boolean,
      default: true
    },
    previewHide: {
      type: Boolean,
      default: true
    },
    suckerHide: {
      type: Boolean,
      default: true
    },
    suckerCanvas: {
      type: null, // HTMLCanvasElement
      default: null
    },
    suckerArea: {
      type: Array,
      default: () => []
    },
    colorsDefault: {
      type: Array,
      default: () => [
        '#0000ac',
        '#f1ff2f',
        '#919191',
        '#f5a442',
        '#42f5f5',
        '#ccffff',
        '#00a9a9',
        '#cce4ff',
        '#007ca9',
        '#d142f5',
        '#e4c2ff',
        '#bb72ff',
        '#7a00d4',
        '#ffc2e7',
        '#f557eb',
        '#c200ae',
        'rgba(0,0,0,0)'
      ]
    },
    colorsHistoryKey: {
      type: String,
      default: 'vue-colorpicker-history'
    }
  },
  data() {
    return {
      hueWidth: 15,
      hueHeight: 92,
      previewHeight: 20,
      modelRgba: '',
      modelHex: '',
      r: 0,
      g: 0,
      b: 0,
      a: 1,
      h: 0,
      s: 0,
      v: 0,
    }
  },
  computed: {
    isLightTheme() {
      return this.theme === 'light'
    },
    totalWidth() {
      return this.hueHeight + (this.hueWidth + 8) * 2
    },
    previewWidth() {
      return this.totalWidth - (this.alphaHide ? 20 : 0) - (this.suckerHide ? 0 : this.previewHeight)
    },
    rgba() {
      return {
        r: this.r,
        g: this.g,
        b: this.b,
        a: this.a,
      }
    },
    hsv() {
      return {
        h: this.h,
        s: this.s,
        v: this.v,
      }
    },
    rgbString() {
      return `rgb(${this.r}, ${this.g}, ${this.b})`
    },
    rgbaStringShort() {
      return `${this.r}, ${this.g}, ${this.b}, ${this.a}`
    },
    rgbaString() {
      return `rgba(${this.rgbaStringShort})`
    },
    hexString() {
      return rgb2hex(this.rgba, true)
    },
  },
  methods: {
    initColorPicker() {
      const { r, g, b, a, h, s, v } = setColorValue(this.color);
      Object.assign(this, { r, g, b, a, h, s, v });
      this.setText();
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
      this.$refs.colors.initColors();
      this.$refs.hue.initHue();
      this.$refs.saturation.initSaturation();
      if (!this.alphaHide) {
        this.$refs.alpha.initAlpha();
      }
      this.$watch('rgba', () => {
        this.$emit('changeColor', {
          rgba: this.rgba,
          hsv: this.hsv,
          hex: this.modelHex,
        })
      });
    },
    selectSaturation(color, history) {
      const { r, g, b, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, 1, h, s, v,history);
    },
    selectHue(color, history) {
      const { r, g, b, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, 1, h, s, v, history);
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
      });
    },
    selectAlpha(a) {
      this.a = a;
      this.setText();
    },
    inputHex(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, a, h, s, v, true);
      this.modelHex = color;
      this.modelRgba = this.rgbaStringShort;
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
    },
    inputRgba(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, a, h, s, v, true);
      this.modelHex = this.hexString;
      this.modelRgba = color;
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
    },
    setText() {
      this.modelHex = this.hexString;
      this.modelRgba = this.rgbaStringShort;
    },
    openSucker(isOpen) {
      this.$emit('openSucker', isOpen);
    },
    selectSucker(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, a, h, s, v, true);
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
    },
    selectColor(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, a, h, s, v, true);
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
    },
    assignColor(color, r, g, b, a, h, s, v, history) {
      Object.assign(this, { r, g, b, a, h, s, v });
      this.setText();
      if (history) {
        this.$refs.colors.setColorsHistory(color);
      }
    }
  },
}
</script>

<style lang="scss">
.hu-color-picker {
  width: 359px;
  padding: 10px;
  background: #1d2024;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
  overflow: hidden;

  &.light {
    background: #f7f8f9;
    box-shadow: 0 0 15px 0 #919191;
    .color-show {
      .sucker {
        background: #eceef0;
      }
    }
    .color-type {
      .name {
        background: #e7e8e9;
      }
      .value {
        color: #666;
        background: #eceef0;
      }
    }
    .colors.history {
      border-top: 1px solid #dddddd;
    }
  }
  canvas {
    vertical-align: top;
  }
  .color-set {
    display: flex;
  }
  .color-show {
    margin-top: 8px;
    display: flex;
  }
}
</style>
