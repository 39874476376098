import Comment from './Comment';
import ReactionBuffer from './ReactionBuffer';
import Lot from './Lot';
import KitVersion from '@/models/KitVersion';

export default class Well {
  constructor(id, name, position, analyseWell, sampleType, kitVersion, lot, reactionBuffer, res, score, comments, diagnosticComment, expectedDiagnostic, channel) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.analyseWell = analyseWell;
    this.sampleType = sampleType;
    this.kitVersion = kitVersion;
    this.lot = lot;
    this.reactionBuffer = reactionBuffer;
    this.res = res;
    this.score = score;
    this.comments = comments;
    this.diagnosticComment = diagnosticComment;
    this.expectedDiagnostic = expectedDiagnostic;
    this.channel = channel;
  }

  static build({ id, name, position, analyseWell, sampleType, kitVersion, lot, reactionBuffer, res, score, comments, diagnosticComment, expectedDiagnostic, channel }) {
    return new Well(id, name, position, analyseWell, sampleType, kitVersion, lot, reactionBuffer, res, score, comments, diagnosticComment, expectedDiagnostic, channel);
  }

  static parse(value) {
    return value ? Well.build({
      ...value,
      kitVersion: KitVersion.parse(value.kitVersion),
      lot: Lot.parse(value.lot),
      comments: value.comments ? value.comments.map(comment => Comment.parse(comment)) : [],
      reactionBuffer: ReactionBuffer.parse(value.reactionBuffer)
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      name: this.name !== undefined && this.name !== '' ? this.name : null,
      position: this.position !== undefined && this.position !== '' ? this.position : null,
      analyseWell: this.analyseWell !== undefined && this.analyseWell !== '' ? this.analyseWell : null,
      sampleType: this.sampleType !== undefined && this.sampleType !== '' ? this.sampleType : null,
      kitVersion: this.kitVersion !== undefined && this.kitVersion !== '' ? this.kitVersion : null,
      lot: this.lot !== undefined && this.lot !== '' ? this.lot : null,
      reactionBuffer: this.reactionBuffer !== undefined && this.reactionBuffer !== '' ? this.reactionBuffer : null,
      res: this.res !== undefined && this.res !== '' ? this.res : null,
      score: this.score !== undefined && this.score !== '' ? this.score : null,
      comments: this.comments !== undefined && this.comments !== '' ? this.comments : null,
      expectedDiagnostic: this.expectedDiagnostic !== undefined && this.expectedDiagnostic !== '' ? this.expectedDiagnostic : null,
      channel: this.channel !== undefined && this.channel !== '' ? this.channel : null
    };
  }
}
