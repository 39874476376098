<template>
  <div>
    <b-container fluid>
      <br>
      <b-card
        no-body
        bg-variant="light">
        <b-card-body id="CongurationAdmin">
          <b-tabs
            v-model="tabIndex"
            active-nav-item-class="bg-secondary"
            content-class="mt-3"
            justified
            pills
            lazy
            @activate-tab="resetData($event)">
            <hr>
            <b-tab
              v-if="isLotConfigurationAuthorized"
              :title="$t('formConfigureLot')"
              active>
              <b-row>
                <b-col cols="12">
                  <app-configuration-form-lot />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="isKitConfigurationAuthorized"
              :title="$t('formConfigureKit')">
              <b-row>
                <b-col cols="12">
                  <app-configuration-form-kit ref="appConfigurationKit" />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="isDoctor"
              :title="$t('formConfigureDiagnostic')">
              <b-row>
                <b-col cols="12">
                  <app-configuration-form-diagnostic />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="isDoctor"
              :title="$t('formConfigureFTP')">
              <b-row>
                <b-col cols="12">
                  <app-configuration-form-ftp />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="isImportExportAuthorized"
              :title="$t('formConfigureImportExport')">
              <b-row>
                <b-col cols="12">
                  <app-configuration-import-export />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>

import ConfigurationLot from './tabs/ConfigurationLot';
import ConfigurationDiagnostic from './tabs/ConfigurationDiagnostic';
import SecurityMixin from '../../mixins/SecurityMixin';
import ConfigurationKit from '@/components/configuration/tabs/ConfigurationKit';
import {mapActions} from 'vuex';
import ConfigurationFTP from '@/components/configuration/tabs/ConfigurationFTP';
import ConfigurationImportExport from './tabs/ConfigurationImportExport.vue';

export default {
  name: 'Configuration',
  components: {
    'app-configuration-form-lot': ConfigurationLot,
    'app-configuration-form-diagnostic': ConfigurationDiagnostic,
    'app-configuration-form-kit': ConfigurationKit,
    'app-configuration-form-ftp': ConfigurationFTP,
    'app-configuration-import-export': ConfigurationImportExport
  },
  mixins: [
    SecurityMixin
  ],
  data() {
    return {
      tabIndex: 0
    };
  },
  computed: {
    isLotConfigurationAuthorized() {
      return this.hasRole('READ_LOT_CONFIG_ADMIN');
    },
    isKitConfigurationAuthorized() {
      return this.hasRole('READ_KIT_CONFIG_ADMIN');
    },
    isImportExportAuthorized() {
      return this.hasRole('IMPORT_KIT') || this.hasRole('IMPORT_LOTS') || this.hasRole('EXPORT_KIT')
          || this.hasRole('EXPORT_LOTS');
    },
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    }
  },
  methods: {
    ...mapActions('configuration', [
      'resetKitData'
    ]),
    resetData(newTabIndex) {
      if (newTabIndex === 1) {
        this.$store.dispatch('configuration/resetKitData');
        if (this.$refs.appConfigurationKit) {
          this.$refs.appConfigurationKit.resetData();
        }
      }
    }
  }
};
</script>
