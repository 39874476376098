export function afterDate(date) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  // invalid date
  if (Number.isNaN(date.valueOf())) {
    return () => false;
  }

  return value => {
    if (!(value instanceof Date)) {
      value = new Date(value);
    }
    // invalid date
    if (Number.isNaN(value.valueOf())) {
      return true;
    }

    return value.getTime() >= date.getTime();
  };
}