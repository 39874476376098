import Well from './Well';

export default class PlateTemplate {
  constructor(id, name, laboratoryId, wells) {
    this.id = id;
    this.name = name;
    this.laboratoryId = laboratoryId;
    this.wells = wells;
  }

  static build({ id, name, laboratoryId, wells }) {
    return new PlateTemplate(id, name, laboratoryId, wells);
  }

  static parse(value) {
    return value ? PlateTemplate.build({
      id: value.id,
      name: value.name,
      laboratoryId: value.laboratoryId,
      wells : value.wells ? value.wells.map(well => Well.parse(well)) : []
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      laboratoryId: this.laboratoryId !== undefined || this.laboratoryId !== '' ? this.laboratoryId : null,
      wells: this.wells !== undefined ? this.wells : null
    };
  }
}
