import Vue from 'vue';
import App from './App.vue';

import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect';
import ECharts from 'vue-echarts';
import Icon from 'vue-awesome/components/Icon';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { store } from './store/store';

// Application specific
import dateTimeFormats from './translation/dateTimeFormats';
import messages from './translation/message';
import numberFormats from './translation/numberFormats';
import router from './router';

// CSS
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './assets/css/custom.scss';
import 'vue-awesome/icons';

library.add(far);
library.add(fas);

Vue.use(Vuex);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);
Vue.use(Vuelidate);

// register globally
Vue.component('Multiselect', Multiselect);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('VChart', ECharts);
Vue.component('VIcon', Icon);
Vue.component('DatePicker', DatePicker);

// Plugins configuration
Vue.config.productionTip = false;

const i18n = new VueI18n({
  fallbackLocale: 'en-GB',
  locale: 'en-GB',
  messages,
  dateTimeFormats,
  numberFormats
});

Vue.filter('capitalize', function (value) {
  return value ? String(value).charAt(0).toUpperCase() + String(value).slice(1) : value;
});

Vue.filter('capitalizeAll', function (value) {
  return value.replace(/\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
});

Vue.filter('uppercase', function (value) {
  return value ? String(value).toUpperCase() : value;
});

export default new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
});
