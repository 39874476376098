<template>
  <div>
    <b-form-row
      v-for="(zone, index) in zones"
      :key="zone.uuid">
      <b-col lg="2">
        <b-form-group :label="index === 0 ? $t('formZone') : undefined">
          <app-string-array-dropdown
            :value="zone.name"
            :overriding-options="filteredZones()"
            :placeholder="$t('formSelectZone')"
            :allow-empty="true"
            :show-labels="false"
            :disabled="disabled || !isVersionModifiable"
            @input="nameChanged(zone.uuid, $event)" />
        </b-form-group>
      </b-col>
      <b-col :lg="isVersionModifiable ? 1 : 2">
        <b-form-group :label="index === 0 ? $t('formMinTemp') : undefined">
          <b-form-input
            type="number"
            :value="zone.minTemp"
            :step="0.1"
            :disabled="disabled"
            @input="minTempChanged(zone.uuid, $event)" />
        </b-form-group>
      </b-col>
      <b-col :lg="isVersionModifiable ? 1 : 2">
        <b-form-group :label="index === 0 ? $t('formMaxTemp') : undefined">
          <b-form-input
            type="number"
            :value="zone.maxTemp"
            :step="0.1"
            :disabled="disabled"
            @input="maxTempChanged(zone.uuid, $event)" />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group :label="index === 0 ? $t('formLabel') : undefined">
          <b-form-input
            type="text"
            :value="zone.label"
            :disabled="disabled || !isVersionModifiable"
            @input="labelChanged(zone.uuid, $event)" />
        </b-form-group>
      </b-col>
      <!--      <b-col lg="2">
              <b-form-group :label="index === 0 ? $t('formData') : undefined">
                <b-form-input
                    v-model="zone.dataEnum"
                    type="text"/>
              </b-form-group>
            </b-col>-->
      <b-col v-if="isVersionModifiable">
        <b-form-group
          :label="index === 0 ? 'Remove / Add' : undefined">
          <b-button
            ref="removeButton"
            :disabled="zones.length < 2 || disabled"
            variant="outline"
            class="btn--no-outline btn-icon btn-icon-minus"
            @click="checkRemoveZoneInputLine(zone.uuid)">
            <font-awesome-icon
              :icon="['fas', 'minus-circle']" />
          </b-button>
          <b-button
            v-if="index === zones.length - 1"
            ref="addButton"
            :disabled="disabled"
            variant="outline"
            class="btn--no-outline btn-icon btn-icon-plus"
            @click="addZoneInputLine">
            <font-awesome-icon
              :icon="['fas', 'plus-circle']" />
          </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
    <app-confirmation-modal
      ref="removeZoneModal"
      :title="$t('modal.zone.remove.title')"
      :message="$t('modal.zone.remove.message')"
      :ok-button-name="$t('yes')"
      :cancel-button-name="$t('no')"
      @ok="removeZoneInputLine()" />
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import StringArrayDropdown from '@/components/common/StringArrayDropdown';
import {ZoneEnum} from '@/enums/Zone.enum';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import {mapFields} from 'vuex-map-fields';
import SecurityMixin from "@/mixins/SecurityMixin";


export default {
  components: {
    'app-string-array-dropdown': StringArrayDropdown,
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [SecurityMixin],
  computed: {
    ...mapFields('configuration', [
      'isKitVersionModifiable',
      'kitVersion'
    ]),
    isVersionModifiable() {
      return this.kitVersion?.kit?.id ? this.kitVersion?.id && this.kitVersion.isEditable ?
          this.isKitVersionModifiable && this.canWriteKitConfiguration : false : true;
    },
    canWriteKitConfiguration() {
      return this.hasRole('WRITE_KIT_CONFIG_ADMIN');
    }
  },
  props: {
    zones: {
      type: Array,
      default: () => [ {
        uuid: uuidv4(),
        name: null,
        minThreshold: null,
        minQuality: null,
        minTemp: null,
        maxTemp: null,
        label: null,
        dataEnum: null
      } ]
    },
    usedZones: {
      type: Array,
      default: () => [ { name: null } ]
    },
    phaseUuid: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ZoneEnum,
      removeZoneUuid: undefined
    };
  },
  methods: {
    addZoneInputLine() {
      this.$store.dispatch('configuration/addNewZone', { phaseUuid: this.phaseUuid });
    },
    checkRemoveZoneInputLine(uuid) {
      this.removeZoneUuid = uuid;
      const zone = this.zones.find(z => z.uuid === uuid);
      if (zone.name) {
        this.$refs.removeZoneModal.showModal();
      } else {
        this.removeZoneInputLine();
      }
    },
    removeZoneInputLine() {
      this.$store.dispatch('configuration/removeZone', { phaseUuid: this.phaseUuid, zoneUuid: this.removeZoneUuid });
      this.$emit('changed');
    },
    filteredZones() {
      return ZoneEnum.filter(z => !this.usedZones.includes(z)).slice(0, 5);
    },
    nameChanged(uuid, value) {
      this.$store.dispatch('configuration/setZoneName', { phaseUuid: this.phaseUuid, zoneUuid: uuid, name: value });
      this.$emit('changed', value);
    },
    minTempChanged(uuid, value) {
      this.$store.dispatch('configuration/setZoneMinTemp', { phaseUuid: this.phaseUuid, zoneUuid: uuid, minTemp: value });
    },
    maxTempChanged(uuid, value) {
      this.$store.dispatch('configuration/setZoneMaxTemp', { phaseUuid: this.phaseUuid, zoneUuid: uuid, maxTemp: value });
    },
    labelChanged(uuid, value) {
      this.$store.dispatch('configuration/setZoneLabel', { phaseUuid: this.phaseUuid, zoneUuid: uuid, label: value });
    }
  }
};
</script>
