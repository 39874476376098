export default class ChannelAssignment {
  constructor(channel, label) {
    this.channel = channel;
    this.label = label;
  }

  static build({ channel, label }) {
    return new ChannelAssignment(channel, label);
  }

  static parse(value) {
    return value ? ChannelAssignment.build({
      channel: value.channel,
      label: value.label
    }) : null;
  }

  toJSON() {
    return {
      channel: this.channel !== undefined || this.channel !== '' ? this.channel : null,
      label: this.label !== undefined || this.label !== '' ? this.label : null
    };
  }
}