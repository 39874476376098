export default class Zone {
  constructor(id, name, minTemp, maxTemp, label, minThreshold, minQuality, dataEnum) {
    this.id = id;
    this.name = name;
    this.minTemp = minTemp;
    this.maxTemp = maxTemp;
    this.label = label;
    this.minThreshold = minThreshold;
    this.minQuality = minQuality;
    this.dataEnum = dataEnum;
  }

  static build({ id, name, minTemp, maxTemp, label, minThreshold, minQuality, dataEnum }) {
    return new Zone(id, name, minTemp, maxTemp, label, minThreshold, minQuality, dataEnum);
  }

  static parse(value) {
    return value ? Zone.build({
      id: value.id,
      name: value.name,
      minTemp: value.minTemp,
      maxTemp: value.maxTemp,
      label: value.label,
      minThreshold: value.minThreshold,
      minQuality: value.minQuality,
      dataEnum: value.dataEnum
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      name: this.name !== undefined && this.name !== '' ? this.name : null,
      minTemp: this.minTemp !== undefined && this.minTemp !== '' ? this.minTemp : null,
      maxTemp: this.maxTemp !== undefined && this.maxTemp !== '' ? this.maxTemp : null,
      label: this.label !== undefined && this.label !== '' ? this.label : null,
      minThreshold: this.minThreshold !== undefined && this.minThreshold !== '' ? this.minThreshold : null,
      minQuality: this.minQuality !== undefined && this.minQuality !== '' ? this.minQuality : null,
      dataEnum: this.dataEnum !== undefined && this.dataEnum !== '' ? this.dataEnum : null
    };
  }
}