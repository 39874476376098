<template>
  <span v-if="icon">
    <b-button
      variant="outline"
      class="btn-icon"
      @click="showRunDeletionModal()">
      <b-row>
        <font-awesome-icon :icon="['fas', 'trash']" />
      </b-row>
    </b-button>
    <app-confirmation-modal
      ref="runDeletionModal"
      :title="$t('runInterpretation.deletionModal.title')"
      :message="$t('runInterpretation.deletionModal.message')"
      :ok-button-name="$t('runInterpretation.deletionModal.okButtonText')"
      :ok-button-classes="['run-deletion-modal-ok-button']"
      ok-button-prevent-hiding
      ok-button-spinners-enabled
      ok-button-variant="outline-danger"
      :cancel-button-name="$t('runInterpretation.deletionModal.cancelButtonText')"
      header-text-variant="danger"
      @ok="deleteRun()" />
  </span>
  <span v-else>
    <b-button
      class="p-2 bd-highlight"
      variant="outline-danger"
      :disabled="!canDelete"
      @click="showRunDeletionModal">
      {{ $t('runInterpretationFooterDeleteRunButtonText') | capitalizeAll }}
    </b-button>
    <app-confirmation-modal
      ref="runDeletionModal"
      :title="$t('runInterpretation.deletionModal.title')"
      :message="$t('runInterpretation.deletionModal.message')"
      :ok-button-name="$t('runInterpretation.deletionModal.okButtonText')"
      :ok-button-classes="['run-deletion-modal-ok-button']"
      ok-button-prevent-hiding
      ok-button-spinners-enabled
      ok-button-variant="outline-danger"
      :cancel-button-name="$t('runInterpretation.deletionModal.cancelButtonText')"
      header-text-variant="danger"
      @ok="deleteRun()" />
  </span>
</template>

<script>
import ConfirmationModal from './ConfirmationModal.vue';
import { deleteRun } from '../../service/GenefoxService';
import NotificationMixin from '../../mixins/NotificationMixin';

export default {
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin
  ],
  props: {
    icon: {
      type: Boolean,
      default: false
    },
    runId: {
      type: Number,
      default: undefined
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showRunDeletionModal() {
      this.$refs.runDeletionModal.showModal();
    },
    deleteRun() {
      deleteRun(this.runId)
        .then(run => {
          this.showSuccessNotification(
            this.$t('runInterpretation.deleteRun.successNotification.title'),
            this.$t('runInterpretation.deleteRun.successNotification.message', [ run.businessId ]),
            { root: true });
          this.$emit('runDelete');
          this.$refs.runDeletionModal.hideModal();

        })
        .catch(error => {
          this.$refs.runDeletionModal.hideModal();
          this.showErrorNotification(this.$t('runInterpretation.deleteRun.errorNotification.title'), error);
        });

    }
  }
};
</script>