<template>
  <div>
    <app-information-header
      :business-id="businessId"
      :created-by-name="createdByName"
      :file-name="fileName"
      :instrument-name="instrumentName"
      :plate-id="plateId" />
    <b-container>
      <b-form-row>
        <b-col sm="6">
          <b-container class="px-0">
            <b-form-group>
              <h3>{{ $t('formRunConfigurationTitle') }}</h3>
            </b-form-group>
            <div class="overflow-auto">
              <app-plate
                :plate-type="plateType"
                :wells="wells"
                @select="handleWellSelection" />
            </div>
            <app-plate-caption-sample-type />
            <h6> {{ $t('templateTitle') }}</h6>
            <b-form-row>
              <b-dropdown
                :text="$t('runWellMetadataDropdown')"
                no-flip>
                <b-dropdown-item
                  ref="loadPlateTemplateDropdownItem"
                  @click="openLoadTemplate">
                  {{ $t('buttonLoadTemplate') }}
                </b-dropdown-item>
                <b-dropdown-item
                  ref="savePlateTemplateDropdownItem"
                  :disabled="!isOneParamSampleConfigured"
                  @click="openSaveTemplate">
                  {{ $t('buttonSaveTemplate') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-form-row>
          </b-container>
        </b-col>
        <b-col sm="1" />
        <b-col sm="5">
          <b-container
            v-if="isTemplateWindowOpen"
            class="pr-0">
            <b-form-group>
              <h3>{{ $t('runWellMetadataConfigurePlateTemplateTitle') }}</h3>
            </b-form-group>
            <transition
              appear
              mode="out-in"
              name="fade">
              <app-plate-template-creation-window
                :available-templates="availableTemplates"
                :wells="anonymizedWells"
                @close="closeTemplateWindowAndReloadTemplates"
                @templateSaved="closeTemplateWindowAndReloadTemplates" />
            </transition>
          </b-container>
          <b-container
            v-if="isTemplateDropdownOpen"
            class="pr-0">
            <b-form-group>
              <h3>{{ $t('runWellMetadataLoadPlateTemplateTitle') }}</h3>
            </b-form-group>
            <transition
              appear
              mode="out-in"
              name="fade">
              <app-plate-template-dropdown-window
                ref="plateTemplateDropdownWindow"
                @close="closeTemplateWindowAndReloadTemplates" />
            </transition>
          </b-container>
          <b-container
            v-if="!isTemplateWindowOpen && !isTemplateDropdownOpen && !isSelectedWellsEmpty"
            class="pr-0">
            <b-form-group>
              <h3>{{ $t('runWellMetadataConfigurePlateTitle') }}</h3>
            </b-form-group>
            <transition
              appear
              mode="out-in"
              name="fade">
              <app-well-configuration-window
                ref="plateTemplateConfigurationWindow"
                :vuex-model="configurableWells"
                @close="selectedWells = []" />
            </transition>
          </b-container>
        </b-col>
      </b-form-row>
    </b-container>
    <div>
      <b-container class="d-flex flex-row align-items-center justify-content-between p-4">
        <app-cancel-run />
        <b-button
          v-if="!isAlgorithmRunning"
          ref="saveWellConfig"
          :disabled="!areAllSampleConfigured"
          @click="saveWellConfiguration">
          {{ $t('startInterpretation') }}
        </b-button>
        <b-button
          v-if="isAlgorithmRunning"
          disabled>
          <b-spinner
            small
            type="grow" />
          Loading...
        </b-button>
      </b-container>
    </div>
  </div>
</template>

<script>
import RunInformationHeader from '../../../RunInformationHeader';
import { mapFields } from 'vuex-map-fields';
import Plate from '../../../plate/Plate';
import WellConfigurationWindow from './well-configuration/WellConfigurationWindow';
import PlateCaptionSampleType from '../../../plate/PlateCaptionSampleType';
import NotificationMixin from '../../../../../../mixins/NotificationMixin';
import { checkRunParsed } from '../../../../../../service/GenefoxService';
import { mapActions, mapGetters, mapState } from 'vuex';
import CancelRun from '../../../CancelRun';
import PlateTemplateCreationWindow from './template-creation/PlateTemplateCreationWindow';
import PlateTemplateDropdownWindow from './template-load/PlateTemplateDropdownWindow';

export default {
  components: {
    'app-information-header': RunInformationHeader,
    'app-plate': Plate,
    'app-well-configuration-window': WellConfigurationWindow,
    'app-plate-caption-sample-type': PlateCaptionSampleType,
    'app-cancel-run': CancelRun,
    'app-plate-template-creation-window': PlateTemplateCreationWindow,
    'app-plate-template-dropdown-window': PlateTemplateDropdownWindow
  },
  mixins: [ NotificationMixin ],
  data() {
    return {
      selectedWells: [],
      isAlgorithmRunning: false,
      isTemplateWindowOpen: false,
      isTemplateDropdownOpen: false
    };
  },
  beforeMount() {
    this.$store.dispatch('template/findAllWellsTemplate', { kidIds: this.availableKits.map(kitVersion => kitVersion.kit.id) });
  },
  computed: {
    ...mapState('runImport', {
      plateType: state => state.run.plateType
    }),
    ...mapState('template', [
      'availableTemplates'
    ]),
    ...mapGetters('runImport', [
      'anonymizedWells',
      'wells'
    ]),
    ...mapFields('runImport', [
      'run.analyses',
      'run.id',
      'run.instrumentName',
      'run.plateId',
      'run.createdByName',
      'run.fileName',
      'run.businessId',
      'availableKits',
      'diagnosticColorByChannelRb'
    ]),
    isSelectedWellsEmpty() {
      return Array.isArray(this.selectedWells) && this.selectedWells.length === 0;
    },
    configurableWells() {
      if (!this.selectedWells) {
        return [];
      }

      return this.selectedWells.map(w => this.wells.get(w.position));
    },
    areAllSampleConfigured() {
      // TODO: refactor this in a better way (in WellConfiguration)
      let allEmpty = true;
      for (const well of this.wells.values()) {
        if (well.sampleType || well.kitVersion?.kit || well.lot || well.reactionBuffer) {
          allEmpty = false;
          // Check if all four are configured
          if (!well.sampleType || !well.kitVersion?.kit || !well.lot || !well.reactionBuffer) {
            console.log(`The following well is not fully configured (sample type, kit, lot or RB): ${well.position}`);

            return false;
          }
          // Check if expected diagnostic is selected if sample type = STANDARD
          else if (well.sampleType && well.sampleType === 'STANDARD') {
            for (const analysis of this.analyses) {
              if (!this.diagnosticColorByChannelRb[analysis.channel]
                  || !this.diagnosticColorByChannelRb[analysis.channel][well.reactionBuffer.name]?.length) {
                continue;
              }
              if (!this.$store.getters['runImport/getWellByAnalysisAndPosition'](analysis, well.position).expectedDiagnostic) {
                console.log(`The following well is not fully configured (expected diagnostic) : ${well.position}`);

                return false;
              }
            }
          }
        }
      }

      return !allEmpty;
    },
    isOneParamSampleConfigured() {
      if (!this.wells) {
        return false;
      }
      for (const well of this.wells.values()) {
        if (well.sampleType || well.kitVersion || well.reactionBuffer) {
          return true;
        }
      }

      return false;
    }
  },
  methods: {
    ...mapActions('runImport', [
      'updateWellsKitVersion'
    ]),
    saveWellConfiguration() {
      this.isAlgorithmRunning = true;
      this.updateWellsKitVersion();
      checkRunParsed(this.id)
        .then(() => this.$store.dispatch('runImport/saveRun'))
        .then(() => this.$router.push({ name: 'run-interpretation' }))
        .catch(error => {
          this.showErrorNotification(this.$t('errorTitleCannotSaveRunAndStartInterpretation'), error);
          this.isAlgorithmRunning = false;
        });
    },
    handleWellSelection(wells) {
      this.isTemplateWindowOpen = false;
      this.isTemplateDropdownOpen = false;
      this.selectedWells = wells;
    },
    openSaveTemplate() {
      this.isTemplateWindowOpen = true;
      this.isTemplateDropdownOpen = false;
    },
    openLoadTemplate() {
      this.isTemplateWindowOpen = false;
      this.isTemplateDropdownOpen = true;
    },
    closeTemplateWindowAndReloadTemplates() {
      this.isTemplateWindowOpen = false;
      this.isTemplateDropdownOpen = false;
    }
  }
};
</script>
