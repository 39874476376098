import {getField, updateField} from 'vuex-map-fields';
import Run from '../../models/Run';
import {
    createCommentForWells,
    createRunComment,
    createWellComment,
    findRun,
    getRbAnalyserChannelDiagnosticColors,
    getRbChannelDiagnosticColors,
    getTestDiagnosticColors,
    updateRun
} from '@/service/GenefoxService';
import Well from '../../models/Well';

import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import {submitResults, updateStatus} from '../../service/GenefoxService';

const state = {
  run: new Run(null,
    null,
    null,
    null,
    null,
    null,
    null,
    [],
    null,
    null,
    null,
    null,
    null,
    null,
    null),
  runFormStep: 1,
  availableKits: [],
  files: [],
  diagnosticColors: [],
  rbChannelDiagnosticColors: [],
  diagnosticColorByChannelRb: [],
  isFilterCommentsOnActiveChannel: false,
  selectedAnalysis: undefined,
};

const getters = {
  getField,
  isRunInterpreted: state => !!state.run.interpretationRunByName,
  wells: state => state.run?.analyses ? state.run?.analyses[0]?.wells : [],
  allWells: state => state.run?.analyses ? state.run?.analyses.map(analysis => Array.from(analysis.wells.values())).flat() : [],
  anonymizedWells: state => Array.from(state.run?.analyses[0].wells?.values()).map(well => ({ ...well, id: null, lot: null })) ?? [],
  wellChannelByWellId: state => wellId => {
    let channel = undefined;

    state.run.analyses.forEach(analysis => {
      const wellForId = find([ ...analysis.wells.values() ], { 'id': wellId });
      if (wellForId) {
        channel = analysis.channel;
      }
    });

    return channel;
  },
  wellsByPosition: state => position => {
    const wells = [];

    state.run.analyses.forEach(analysis => {
      const wellForPosition = analysis.wells?.get(position);
      if (wellForPosition) {
        wells.push(wellForPosition);
      }
    });

    return wells;
  },
  commentsByPosition: state => (position, filterOnActiveChannel) => {
    if (filterOnActiveChannel === undefined) {
      filterOnActiveChannel = state.isFilterCommentsOnActiveChannel;
    }

    const wells = filterOnActiveChannel
                  ? [state.selectedAnalysis?.wells?.get(position)]
                  : (state.run?.analyses || []).map(a => a.wells?.get(position)).filter(a => a);
    
    const comments = wells.map(w => w?.comments || []).flat();

    return comments;
  },
  wellsByWellsPosition: state => wells => {
    const wellsByPosition = [];

    wells.forEach(wellToInclude => {
      state.run.analyses.forEach(analysis => {
        const foundWellByPosition = analysis.wells?.get(wellToInclude.position);
        if (foundWellByPosition) {
          wellsByPosition.push(foundWellByPosition);
        }
      });
    });

    return wellsByPosition;
  },
  getRunStatus: state => state.run.status,
  getFiles: state => state.files,
  commentByIdAndRun: state => commentId => find(state.run.comments, { 'id': commentId }),
  commentByIdAndWellId: state => (commentId, wellId) => {
    let comment = undefined;

    state.run.analyses.forEach(analysis => {
      const well = find([ ...analysis.wells.values() ], { 'id': wellId });
      if (well) {
        comment = find(well.comments, { 'id': commentId });
      }
    });

    return comment;
  },
  getWellByAnalysisAndPosition: state => (analysis, position) => {
    const currentAnalysis = state.run.analyses.find(currentAnalysis => analysis === currentAnalysis);

    return currentAnalysis?.wells?.get(position);
  }
};

export const mutations = {
  updateField,
  setWells(state, { wells, applyUpdate }) {
    state.run.analyses.forEach(analysis => {
      const updates = new Map(analysis.wells.entries());
      for (const well of wells) {
        if (analysis.wells.has(well.position)) {
          const clonedWell = Well.build(analysis.wells.get(well.position));
          applyUpdate(clonedWell, well);
          updates.set(well.position, clonedWell);
        }
      }
      analysis.wells = updates;
    });
  },
  setWellsExpectedDiagnostic(state, { wells, expectedDiagnostic, isDuplex }) {
    let analyses = state.run.analyses;
    if (isDuplex) {
      analyses = [ state.run.analyses.find(analysis => analysis.channel === wells[0].channel) ];
    }
    analyses.forEach(analysis => {
      const updates = new Map(analysis.wells.entries());
      for (const well of wells) {
        if (analysis.wells.has(well.position)) {
          // set diagnostic for channel two (second analysis)
          const clonedWell = Well.build(analysis.wells.get(well.position));
          clonedWell.expectedDiagnostic = expectedDiagnostic;
          updates.set(well.position, clonedWell);
        }
      }
      analysis.wells = updates;
    });
  },
  setWellsSampleType(state, { wells, sampleType }) {
    mutations.setWells(state, { wells, applyUpdate: well => well.sampleType = sampleType });
  },
  setWellsKitVersion(state, { wells, kitVersion }) {
    mutations.setWells(state, {
      wells, applyUpdate: well => {
        well.kitVersion = kitVersion;
        well.reactionBuffer = undefined;
        well.lot = undefined;
        well.expectedDiagnostic = undefined;
      }
    });
  },
  updateWellsKitVersion(state) {
    state.run.analyses.forEach(analysis => {
      const updates = new Map(analysis.wells.entries());
      for (const [ position, well ] of updates) {
        const clonedWell = Well.build(analysis.wells.get(position));
        if (well.lot?.kitVersion) {
          clonedWell.kitVersion = well.lot.kitVersion;
        }
        updates.set(position, clonedWell);
      }
      analysis.wells = updates;
    });
  },
  setWellsLot(state, { wells, lot }) {
    mutations.setWells(state, { wells, applyUpdate: well => well.lot = lot });
  },
  setWellsReactionBuffer(state, { wells, reactionBuffer }) {
    mutations.setWells(state, { wells, applyUpdate: well => well.reactionBuffer = reactionBuffer });
  },
  setWellsName(state, { wells, name }) {
    mutations.setWells(state, { wells, applyUpdate: well => well.name = name });
  },
  setWellComment(state, { wellId, comments }) {
    state.run.analyses.forEach(analysis => {
      const well = find([ ...analysis.wells.values() ], { 'id': wellId });

      if (well) {
        well.comments = comments;
      }
    });
  },
  setWellDiagnosticComment(state, { wellId, comment }) {
    state.run.analyses.forEach(analysis => {
      const well = find([ ...analysis.wells.values() ], { 'id': wellId });
      if (well) {
        well.diagnosticComment = comment;
      }
    });
  },
  setPlateTemplate(state, { template }) {
    const templateWells = template.wells.filter(well => well.kitVersion);
    state.run.analyses.forEach(analysis => {
      const wells = Array.from(analysis.wells.values());
      const clonedWells = new Map();
      for (const well of wells) {
        const clonedWell = Well.build(well);
        let templateWellToUpdate = templateWells.find(tw => tw.position === clonedWell.position && tw.channel === clonedWell.channel);
        if (!templateWellToUpdate) {
          templateWellToUpdate = templateWells.find(tw => tw.position === clonedWell.position);
          if (templateWellToUpdate && state.run.analyses.length > 1) {
            templateWells.splice(templateWells.indexOf(templateWellToUpdate), 1);
          }
        }
        if (templateWellToUpdate) {
          // get the available kits from RunMetaData
          if (state.availableKits.length === 1) {
            clonedWell.kitVersion = state.availableKits[0];
            if (clonedWell.kitVersion.lots.length === 1) {
              clonedWell.lot = clonedWell.kitVersion.lots[0];
            }
          } else {
            const kitVersions = state.availableKits.filter(kv => kv.kit.id === templateWellToUpdate.kitVersion.kit.id);
            if (kitVersions?.length === 1) {
              clonedWell.kitVersion = kitVersions[0];
              if (clonedWell.kitVersion.lots.length === 1) {
                clonedWell.lot = clonedWell.kitVersion.lots[0];
              }
            }
          }
          clonedWell.sampleType = templateWellToUpdate.sampleType;
          clonedWell.reactionBuffer = templateWellToUpdate.reactionBuffer;

          if (templateWellToUpdate.expectedDiagnostic) {
            const dcs = state.diagnosticColorByChannelRb && state.diagnosticColorByChannelRb[analysis.channel]
              ? state.diagnosticColorByChannelRb[analysis.channel][clonedWell.reactionBuffer.name]
              : undefined;
            if (dcs) {
              const dc = dcs.filter(dc => dc.id === templateWellToUpdate.expectedDiagnostic.id
                                          || (dc.diagnostic.name === templateWellToUpdate.expectedDiagnostic.diagnostic.name
                                              && dc.diagnosticLabel === templateWellToUpdate.expectedDiagnostic.diagnosticLabel));
              if (dc && dc.length === 1) {
                clonedWell.expectedDiagnostic = dc[0];
              }
            }
          }
        }
        clonedWells.set(clonedWell.position, clonedWell);
      }
      analysis.wells = clonedWells;
    });
  },
  resetPlateTemplate(state) {
    const wells = Array.from(state.run.analyses[0].wells.values());
    mutations.setWells(state, {
      wells, applyUpdate: well => {
        well.kitVersion = undefined;
        well.lot = undefined;
        well.sampleType = undefined;
        well.reactionBuffer = undefined;
      }
    });
  },
  setFiles(state, files) {
    state.files = files;
  }
};

export const actions = {
  resetRunImportState({ commit }) {
    commit('updateField', { path: 'run', value: new Run() });

    return new Run();
  },
  findRun({ commit }, id) {
    return new Promise((resolve, reject) => {
      findRun(id)
        .then(run => {
          commit('updateField', { path: 'run', value: run });
          resolve(cloneDeep(run));
        })
        .catch(error => reject(error));
    });
  },
  saveRun({ dispatch }) {
    return new Promise((resolve, reject) => {
      const promise = dispatch('updateRun');
      promise.then(run => {
        dispatch('setCurrentRun', run);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  updateRun({ state }) {
    return updateRun(state.run);
  },
  setCurrentRun({ commit }, run) {
    commit('updateField', { path: 'run', value: run });
  },
  setFiles({ commit }, files) {
    commit('setFiles', files);
  },
  setKitOptions({ state, commit }) {
    commit('updateField', { path: 'kitOptions', value: state.run.kitName });
    commit('updateField', { path: 'run.kitName', value: state.run.kitName ? state.run.kitName[0] : null });
  },
  updateStatus(_, { id, status }) {
    return updateStatus(id, status);
  },
  submitResults(_, { id }) {
    return submitResults(id);
  },
  setWellsExpectedDiagnostic({ commit }, { wells, expectedDiagnostic, isDuplex }) {
    commit('setWellsExpectedDiagnostic', { wells, expectedDiagnostic, isDuplex });
  },
  setWellsSampleType({ commit }, { wells, sampleType }) {
    commit('setWellsSampleType', { wells, sampleType });
  },
  setWellsKitVersion({ commit }, { wells, kitVersion }) {
    commit('setWellsKitVersion', { wells, kitVersion });
  },
  updateWellsKitVersion({ commit }) {
    commit('updateWellsKitVersion');
  },
  setWellsLot({ commit }, { wells, lot }) {
    commit('setWellsLot', { wells, lot });
  },
  setWellsReactionBuffer({ commit }, { wells, reactionBuffer }) {
    commit('setWellsReactionBuffer', { wells, reactionBuffer });
  },
  setWellsName({ commit }, { wells, name }) {
    commit('setWellsName', { wells, name });
  },
  setPlateTemplate({ commit }, { template }) {
    commit('setPlateTemplate', { template });
  },
  resetPlateTemplate({ commit }) {
    commit('resetPlateTemplate');
  },
  createRunComment({ state, commit }, comment) {
    return new Promise((resolve, reject) =>
      createRunComment(state.run.id, comment)
        .then(runComments => {
          commit('updateField', { path: 'run.comments', value: runComments });
          resolve();
        })
        .catch(error => reject(error))
    );
  },
  createWellComment({ state, commit }, { wellId, comment }) {
    return new Promise((resolve, reject) =>
      createWellComment(state.run.id, wellId, comment)
        .then(wellComments => {
          commit('setWellComment', { wellId, comments: wellComments });
          resolve();
        })
        .catch(error => reject(error))
    );
  },
  createCommentForWells({ state, commit }, { wellIds, comment }) {
    return new Promise((resolve, reject) =>
      createCommentForWells(state.run.id, wellIds, comment)
        .then(map => {
          Object.entries(map).forEach(entry => commit('setWellComment', entry[1]));
          resolve();
        })
        .catch(error => reject(error))
    );
  },
  getRbAnalyserChannelCombinations({ state, commit }) {
    if (!state.run?.analyses.size && !state.run.analyses[0].wells.size && !state.run.analyser) {
      return;
    }
    let kitId = undefined;
    let kitVersionId = undefined;
    const rbIds = [];
    const channelNames = [];
    state.run.analyses.forEach(analysis => {
      if (!channelNames.length || !channelNames.find(cn => cn === analysis.channel)) {
        channelNames.push(analysis.channel);
      }
      for (const well of analysis.wells.values()) {
        if (!kitId && well.kitVersion?.kit?.id) {
          kitId = well.kitVersion.kit.id;
          kitVersionId = well.kitVersion.id;
        }
        if (well.reactionBuffer && (!rbIds.length || !rbIds.find(id => id === well.reactionBuffer.id))) {
          rbIds.push(well.reactionBuffer.id);
        }
      }
    });

    return new Promise((resolve, reject) =>
      getRbAnalyserChannelDiagnosticColors(kitId, rbIds, state.run.analyser.id, channelNames)
        .then(diagnosticColors => {
          commit('updateField', { path: 'diagnosticColors', value: diagnosticColors });
          resolve(diagnosticColors);
        })
        .catch(error => reject(error))
    );
  },
  getRbChannelDiagnosticColors({ state, commit }, { kitId, rbId, wellId, currentDiagnostic }) {
    if (!state.run?.analyses?.size && !state.run.analyses[0].wells?.size && !state.run.analyser) {
      return;
    }

    if (currentDiagnostic?.diagnostic?.name === 'TEST_OK' || currentDiagnostic?.diagnostic?.name === 'TEST_KO') {
      return new Promise((resolve, reject) =>
        getTestDiagnosticColors()
          .then(rbChannelDiagnosticColors => {
            commit('updateField', {
              path: 'rbChannelDiagnosticColors',
              value: rbChannelDiagnosticColors
            });
            resolve(rbChannelDiagnosticColors);
          })
          .catch(error => reject(error))
      );
    }

    let channelName = undefined;
    state.run.analyses.forEach(analysis => {
      const well = find([ ...analysis.wells.values() ], { 'id': wellId });
      if (well) {
        channelName = analysis.channel;
      }
    });

    return new Promise((resolve, reject) =>
      getRbChannelDiagnosticColors(kitId, rbId, state.run.analyser.id, channelName)
        .then(rbChannelDiagnosticColors => {
          commit('updateField', {
            path: 'rbChannelDiagnosticColors',
            value: rbChannelDiagnosticColors
          });
          resolve(rbChannelDiagnosticColors);
        })
        .catch(error => reject(error))
    );
  },
  getDiagnosticColorsByChannelRb({ state, commit }) {
    const dcs = [];

    state.run?.analyses.map(a => a.channel).forEach(c => {
      dcs[c] = [];
      state.availableKits.forEach(kv => {
        kv.kit.reactionBuffers.forEach(rb => {
          getRbChannelDiagnosticColors(kv.kit.id, rb.id, state.run.analyser.id, c)
            .then(diagnosticColors => {
              dcs[c][rb.name] = diagnosticColors;
              commit('updateField', { path: 'diagnosticColorByChannelRb', value: dcs });
            });
        });
      });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
