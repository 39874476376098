<template>
  <div>
    <b-jumbotron
      bg-variant="transparent"
      border-variant="dark"
      class="pb-4 well-configuration-jumbotron">
      <transition
        mode="out-in"
        name="slide-fade">
        <b-container
          v-if="!updateConfirmationFor"
          key="configuration"
          ref="configuration">
          <b-form-group
            :label="$t('formKit')">
            <app-kit-editable-multiselect
              :value="kitVersion"
              :overriding-options="availableKits"
              :show-labels="false"
              @select="setKitVersion($event)"
              @remove="setKitVersion(undefined)" />
          </b-form-group>
          <div class="form-group-dropdown-container">
            <transition name="fade">
              <b-form-group
                v-if="isSingleKitSelected"
                :label="$t('formLot')">
                <app-lot-editable-multiselect
                  :value="lot"
                  :overriding-options="kitVersion ? kitVersion.lots : []"
                  :preselect-single-overriding-option="true"
                  :show-labels="false"
                  @select="setLot($event)"
                  @remove="setLot(undefined)" />
              </b-form-group>
            </transition>
          </div>
          <div class="form-group-dropdown-container">
            <transition name="fade">
              <b-form-group
                v-if="isSingleKitSelected"
                :label="$t('formReactionBuffer')">
                <app-reaction-buffer-dropdown
                  :value="reactionBuffer"
                  :overriding-options="kitVersion ? kitVersion.kit.reactionBuffers : []"
                  :preselect-single-overriding-option="true"
                  :show-labels="false"
                  @select="setRb($event)"
                  @remove="setRb(undefined)" />
              </b-form-group>
            </transition>
          </div>
          <div class="form-group-dropdown-container">
            <transition name="fade">
              <b-form-group :label="$t('formSampleType')">
                <app-sample-type-dropdown
                  v-model="sampleType"
                  :show-labels="false" />
              </b-form-group>
            </transition>
          </div>
          <template
            v-if="isSingleKitSelected && sampleType === 'STANDARD' && kitVersion && reactionBuffer">
            <template
              v-if="kitVersion.kit.channelAssignments && kitVersion.kit.channelAssignments.length > 1">
              <transition-group name="fade">
                <b-form-group
                  v-for="channelAssignment in kitVersion.kit.channelAssignments"
                  :key="channelAssignment.channel"
                  :label="$t('wellConfigurationWindow.expectedDiagnosticLabelWithChannel', [ channelAssignment.label ])">
                  <app-diagnostic-dropdown
                    :value="expectedDiagnostic[channelAssignment.channel]"
                    :disable="reactionBuffer.name === multipleDropDownValue"
                    :overriding-options="diagnosticColorByChannelRb[channelAssignment.channel][reactionBuffer.name]"
                    :preselect-single-overriding-option="true"
                    :show-labels="false"
                    @select="setExpectedDiagnostic($event, channelAssignment.channel)" />
                </b-form-group>
              </transition-group>
            </template>
            <template
              v-else>
              <transition-group name="fade">
                <template
                  v-for="analyse in run.analyses">
                  <b-form-group
                    v-if="hasDiagnostic(analyse.channel, reactionBuffer.name)"
                    :key="analyse.channel"
                    :label="$t('wellConfigurationWindow.expectedDiagnosticLabel')">
                    <app-diagnostic-dropdown
                      :value="expectedDiagnostic[analyse.channel]"
                      :disable="reactionBuffer.name === multipleDropDownValue"
                      :overriding-options="diagnosticColorByChannelRb[analyse.channel][reactionBuffer.name]"
                      :preselect-single-overriding-option="true"
                      :show-labels="false"
                      @select="setExpectedDiagnostic($event, analyse.channel)" />
                  </b-form-group>
                </template>
              </transition-group>
            </template>
          </template>
          <div class="form-group-container">
            <transition name="fade">
              <b-form-group
                v-if="isSingleSelect"
                :label="$t('formWellName')"
                class="form-group-fixed">
                <b-form-input
                  ref="wellNameInput"
                  v-model="name"
                  type="text" />
              </b-form-group>
            </transition>
          </div>
          <div class="text-center mt-4">
            <b-button @click="$emit('close')">
              {{ $t('close') }}
            </b-button>
          </div>
        </b-container>
        <b-container
          v-else
          key="confirmation"
          ref="confirmation">
          <p class="text-center">
            {{ $t('wellUpdateConfirmationMessage') }}
          </p>
          <b-form-row class="mt-4">
            <b-container class="d-flex flex-row align-items-center justify-content-center">
              <b-link
                class="mr-5"
                @click="updateConfirmationFor = null">
                {{ $t('no') }}
              </b-link>
              <b-button
                class="ml-5"
                @click="updateValue">
                {{ $t('yes') }}
              </b-button>
            </b-container>
          </b-form-row>
        </b-container>
      </transition>
    </b-jumbotron>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { mapActions, mapState } from 'vuex';
import KitEditableMultiselect from '../../../../../../common/KitEditableMultiselect.vue';
import SampleTypeDropdown from '../../../../../../common/SampleTypeDropdown';
import ReactionBufferDropdown from '../../../../../../common/ReactionBufferDropdown';
import LotEditableMultiselect from '../../../../../../common/LotEditableMultiselect';
import DiagnosticDropdown from '../../../../../../common/DiagnosticDropdown';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'WellConfiguration',
  components: {
    'app-sample-type-dropdown': SampleTypeDropdown,
    'app-kit-editable-multiselect': KitEditableMultiselect,
    'app-lot-editable-multiselect': LotEditableMultiselect,
    'app-reaction-buffer-dropdown': ReactionBufferDropdown,
    'app-diagnostic-dropdown': DiagnosticDropdown
  },
  props: {
    vuexModel: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      updateConfirmationFor: null,
      newValue: null,
      multipleDropDownValue: this.$t('dropdownMultiple'),
      reactionBuffers: undefined
    };
  },
  computed: {
    ...mapState('runImport', [
      'availableKits'
    ]),
    ...mapFields('runImport', [
      'run',
      'diagnosticColorByChannelRb'
    ]),
    well() {
      return this.vuexModel[0];
    },
    kitVersion() {
      let kitVersion = { name: this.multipleDropDownValue };
      if (uniqBy(this.vuexModel, 'kitVersion.id').length === 1) {
        kitVersion = this.vuexModel[0].kitVersion;
      }

      return kitVersion;
    },
    lot() {
      let lot = { name: this.multipleDropDownValue };
      if (uniqBy(this.vuexModel, 'lot.id').length === 1) {
        lot = this.vuexModel[0].lot;
      }

      return lot;
    },
    reactionBuffer() {
      let reactionBuffer = { name: this.multipleDropDownValue };
      if (uniqBy(this.vuexModel, 'reactionBuffer.id').length === 1) {
        reactionBuffer = this.vuexModel[0].reactionBuffer;
      }

      return reactionBuffer;
    },
    expectedDiagnostic() {
      const expectedDiagnostic = {};
      this.run.analyses.map(a => a.channel).forEach(channelName => {
        expectedDiagnostic[channelName] = this.multipleDropDownValue;
      });
      if (this.vuexModel[0].kitVersion?.kit?.channelAssignments?.length > 1) {
        this.vuexModel[0].kitVersion.kit.channelAssignments.forEach(channelAssignment => {
          const analysis = this.run.analyses.find(analysis => analysis.channel === channelAssignment.channel);
          const wellsFromChannel = [];
          this.vuexModel.forEach(well => wellsFromChannel.push(this.$store.getters['runImport/getWellByAnalysisAndPosition'](analysis, well.position)));
          if (uniqBy(wellsFromChannel, 'expectedDiagnostic.id').length === 1 && analysis) {
            expectedDiagnostic[analysis.channel] = wellsFromChannel[0].expectedDiagnostic;
          }
        });
      } else {
        if (uniqBy(this.vuexModel, 'expectedDiagnostic.id').length === 1) {
          this.run.analyses.forEach(analyse => {
            expectedDiagnostic[analyse.channel] = this.$store.getters['runImport/getWellByAnalysisAndPosition'](analyse, this.vuexModel[0].position).expectedDiagnostic;
          });
        }
      }

      return expectedDiagnostic;
    },
    sampleType: {
      get() {
        let sampleType = this.multipleDropDownValue;
        if (uniqBy(this.vuexModel, 'sampleType').length === 1) {
          sampleType = this.vuexModel[0].sampleType;
        }

        return sampleType;
      },
      set(sampleType) {
        if (uniqBy(this.vuexModel, 'sampleType').length === 1) {
          this.setWellsSampleType({ 'wells': this.vuexModel, sampleType });
        } else {
          this.newValue = sampleType;
          this.updateConfirmationFor = 'sampleType';
        }
      }
    },
    name: {
      get() {
        return this.vuexModel[0].name;
      },
      set(name) {
        this.setWellsName({ wells: this.vuexModel, name });
      }
    },
    isSingleSelect() {
      return this.vuexModel.length === 1;
    },
    isSingleKitSelected() {
      return this.isSingleSelect || !isEqual(this.kitVersion, { name: this.$t('dropdownMultiple') });
    }
  },
  methods: {
    ...mapActions('runImport', [
      'setWellsSampleType',
      'setWellsKitVersion',
      'setWellsLot',
      'setWellsReactionBuffer',
      'setWellsName',
      'setWellsExpectedDiagnostic'
    ]),
    updateValue() {
      if (this.updateConfirmationFor === 'sampleType') {
        this.setWellsSampleType({ 'wells': this.vuexModel, 'sampleType': this.newValue });
      } else if (this.updateConfirmationFor === 'kitVersion') {
        this.setWellsKitVersion({ 'wells': this.vuexModel, 'kitVersion': this.newValue });
      } else if (this.updateConfirmationFor === 'lot') {
        this.setWellsLot({ 'wells': this.vuexModel, 'lot': this.newValue });
      } else if (this.updateConfirmationFor === 'reactionBuffer') {
        this.setWellsReactionBuffer({ 'wells': this.vuexModel, 'reactionBuffer': this.newValue });
        this.setWellsExpectedDiagnostic({ 'wells': this.vuexModel, 'expectedDiagnostic': undefined });
      } else if (this.updateConfirmationFor === 'expectedDiagnostic') {
        this.setWellsExpectedDiagnostic({ 'wells': this.vuexModel, 'expectedDiagnostic': this.newValue });
      }
      this.updateConfirmationFor = null;
    },
    setKitVersion(kitVersion) {
      if (uniqBy(this.vuexModel, 'kitVersion').length === 1) {
        this.setWellsKitVersion({ 'wells': this.vuexModel, kitVersion });
        this.reactionBuffers = kitVersion?.kit?.reactionBuffers;
        if (kitVersion?.lots?.length === 1) {
          this.setLot(kitVersion.lots[0]);
        }
      } else {
        this.newValue = kitVersion;
        this.updateConfirmationFor = 'kitVersion';
      }
    },
    setLot(lot) {
      if (uniqBy(this.vuexModel, 'lot').length === 1) {
        this.setWellsLot({ 'wells': this.vuexModel, lot });
        if (this.reactionBuffers?.length === 1 && lot) {
          this.setRb(this.reactionBuffers[0]);
        }
      } else {
        this.newValue = lot;
        this.updateConfirmationFor = 'lot';
      }
    },
    setRb(reactionBuffer) {
      if (uniqBy(this.vuexModel, 'reactionBuffer').length === 1) {
        this.setWellsReactionBuffer({ 'wells': this.vuexModel, reactionBuffer });
        this.setWellsExpectedDiagnostic({ 'wells': this.vuexModel, 'expectedDiagnostic': undefined });
      } else {
        this.newValue = reactionBuffer;
        this.updateConfirmationFor = 'reactionBuffer';
      }
    },
    setExpectedDiagnostic(expectedDiagnostic, channel) {
      if (this.vuexModel[0].kitVersion.kit.channelAssignments.length > 1) {
        const wells = [];
        const wellsMap = this.run.analyses.find(analysis => analysis.channel === channel).wells;
        this.vuexModel.forEach(well => {
          wells.push(wellsMap.get(well.position));
        });
        this.setWellsExpectedDiagnostic({ wells, expectedDiagnostic, isDuplex: true });
      } else {
        this.setWellsExpectedDiagnostic({ 'wells': this.vuexModel, expectedDiagnostic, isDuplex: false });
      }
    },
    hasDiagnostic(channel, rbName) {
      return this.diagnosticColorByChannelRb[channel] && this.diagnosticColorByChannelRb[channel][rbName]?.length > 0;
    }
  }
};
</script>
