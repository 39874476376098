<template>
  <div class="h-100 d-flex">
    <div class="col-3 p-0 border-right h-100">
      <b-form-input
          :placeholder="$t('laboratory.search') | capitalize"
          class="form-control laboratory-search"
          type="text"
          :value="search"
          @input="updateSearch" />
      <b-table
          responsive
          striped
          hover
          show-empty
          selectable
          thead-class="laboratories-table-header"
          @row-selected="selectLaboratory"
          :select-mode="'single'"
          :fields="fields"
          :items="laboratories"
          :empty-filtered-text="$t('noDataFound')"
          :empty-text="$t('noDataFound')"
          :no-local-sorting=true
          sort-by="name"
          @sort-changed="reloadSort">
      </b-table>
      <b-button
          id="addLaboratory"
          class="btn-sm list-add-button"
          variant="success"
          @click="addLaboratory">
        <font-awesome-icon :icon="['fas', 'plus']" />
        {{ $t('common.add') }}
      </b-button>
      <b-pagination
          v-if="laboratories.length"
          v-model="currentPage"
          align="center"
          :total-rows="laboratoryCount"
          :per-page="laboratoryPerPage"
          @change="pageChange"/>
    </div>
    <div class="col-9 p-0 h-100">
      <app-laboratory
          ref="appLaboratory"
          @updated="pageChange(currentPage)"
          @deleted="pageChange(currentPage)"></app-laboratory>
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import Laboratory from "@/components/admin/laboratory/Laboratory";

export default {
  name: "Laboratories",
  components: {
    'app-laboratory': Laboratory
  },
  data() {
    return {
      search: undefined,
      currentPage: 1,
      sort: 'name',
      sortDirection: 'ASC',
      timer: undefined
    };
  },
  computed: {
    ...mapFields('laboratory', [
      'laboratories',
      'laboratoryCount',
      'laboratoryPerPage',
      'laboratory'
    ]),
    fields() {
      return [
        {
          key: 'name',
          label: this.$options.filters.capitalize(this.$t('laboratory.name')),
          sortable: true
        }
      ];
    }
  },
  beforeMount() {
    // clean the selection before displaying the screen
    this.$store.dispatch('laboratory/selectLaboratory', null)
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
        });
  },
  mounted() {
    this.pageChange(1);
  },
  methods: {
    reloadSort(ctx) {
      this.sort = ctx.sortBy;
      this.sortDirection = ctx.sortDesc ? 'DESC' : 'ASC';
      this.pageChange(1);
    },
    updateSearch(search) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (search.length >= 1) {
        this.search = search;
      } else {
        this.search = undefined;
      }
      this.timer = setTimeout(() => this.pageChange(1), 1000);
    },
    pageChange(page) {
      if (page === 1) {
        this.setLaboratoriesPerPage();
      }
      const search = this.search;
      const sort = this.sort;
      const sortDirection = this.sortDirection;
      this.$store.dispatch('laboratory/findLaboratories', {page, search, sort, sortDirection})
          .then(() => {
            this.currentPage = page;
          })
          .catch(error => {
            this.$bvToast.toast(error.message, {
              title: this.$options.filters.capitalize(this.$t('error')),
              variant: 'danger',
              autoHideDelay: '3000',
              toaster: 'b-toaster-bottom-left'
            });
          });
    },
    selectLaboratory(laboratories) {
      this.$store.dispatch('laboratory/selectLaboratory', laboratories[0])
          .then(() => {
            this.$refs?.appLaboratory?.$refs?.appLaboratoryUsers?.pageChange(1);
          })
          .catch(error => {
            this.$bvToast.toast(error.message, {
              title: this.$options.filters.capitalize(this.$t('error')),
              variant: 'danger',
              autoHideDelay: '3000',
              toaster: 'b-toaster-bottom-left'
            });
          });
    },
    addLaboratory() {
      this.$router.push({ name: 'laboratory-add' });
    },
    setLaboratoriesPerPage() {
      if (!this.$el.getElementsByClassName('laboratories-table-header').length) {
        return;
      }
      const tableHeaderHeight = this.$el.getElementsByClassName('laboratories-table-header')[0].clientHeight;
      const totalHeight = document.getElementsByClassName('content')[0].clientHeight - tableHeaderHeight - 38 - 38; // -38 because of paginator -38 search input
      this.$store.dispatch('laboratory/setLaboratoriesPerPage', Math.floor(totalHeight / 56.5));
    }
  }
};
</script>