<template>
  <b-tabs
    :value="tabIndex"
    active-nav-item-class="bg-secondary"
    content-class="mt-3"
    justified
    pills
    lazy>
    <b-tab
      v-if="isExportAuthorized"
      :title="$t('formConfigureExport')">
      <b-row>
        <b-col cols="12">
          <app-export-kit v-if="isExportKitAuthorized"/>
          <hr />
          <app-export-lot v-if="isExportLotAuthorized"/>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab
      v-if="isImportAuthorized"
      :title="$t('formConfigureImport')">
      <b-row>
        <b-col cols="12">
          <app-import-kit v-if="isImportKitAuthorized"/>
          <hr />
          <app-import-lot v-if="isImportLotAuthorized"/>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>

import ExportKit from './export/ExportKit.vue';
import SecurityMixin from '../../../mixins/SecurityMixin';
import ImportKit from "@/components/configuration/tabs/import/ImportKit.vue";
import ExportLot from "@/components/configuration/tabs/export/ExportLot.vue";
import ImportLot from "@/components/configuration/tabs/import/ImportLot.vue";

export default {
  components: {
    'app-export-kit': ExportKit,
    'app-export-lot': ExportLot,
    'app-import-kit': ImportKit,
    'app-import-lot': ImportLot
  },
  mixins: [
    SecurityMixin
  ],
  data() {
    return {
      tabIndex: 0
    };
  },
  computed: {
    isExportAuthorized() {
      return this.isExportKitAuthorized || this.isExportLotAuthorized;
    },
    isExportLotAuthorized() {
      return this.hasRole('EXPORT_LOTS');
    },
    isExportKitAuthorized() {
      return this.hasRole('EXPORT_KIT');
    },
    isImportAuthorized() {
      return this.isImportLotAuthorized || this.isImportKitAuthorized;
    },
    isImportLotAuthorized() {
      return this.hasRole('IMPORT_LOTS');
    },
    isImportKitAuthorized() {
      return this.hasRole('IMPORT_KIT');
    }
  }
};
</script>


