<template>
  <multiselect
    class="diagnostic-dropdown"
    v-model="model"
    :allow-empty="true"
    :class="stateClass"
    :disabled="disable"
    :loading="isLoading"
    :options="options"
    label="name"
    :placeholder="$t('diagnosticDropdownPlaceholder')"
    :show-labels="false"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)">
    <template
      slot="singleLabel"
      slot-scope="props">
      <div class="diagnostic-dropdown-content-container">
        <app-well
          :forced-diagnostic="props.option"
          :forced-sample-type="sampleType"
          class="align-middle"/>
        <span class="align-middle"> {{ props.option.name }}</span>
      </div>
    </template>
    <template
      slot="option"
      slot-scope="props">
      <div class="diagnostic-dropdown-content-container">
        <app-well
          :forced-diagnostic="props.option"
          :forced-sample-type="sampleType"
          class="align-middle"/>
        <span class="align-middle"> {{ props.option.name }}</span>
      </div>
    </template>
    <template
      slot="placeholder">
      <div class="diagnostic-dropdown-content-container">
        <span class="align-middle">{{ $t('diagnosticDropdownPlaceholder') }}</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import Well from '../run/editor/plate/Well';

export default {
  components: {
    'app-well': Well
  },
  mixins: [DropdownMixin],
  props: {
    sampleType: {
      type: String,
      default: undefined
    }
  },
  computed: {
    options() {
      return this.overridingOptions?.map(option => {return {...option, name: this.getDiagnosticTranslation(option)}});
    },
    model: {
      get() {
        return this.value && {...this.value, name: this.getDiagnosticTranslation(this.value)};
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    getDiagnosticTranslation(diagnostic) {
      let translation;

      if (this.sampleType === 'POSITIVE_TEST' || this.sampleType === 'NEGATIVE_TEST' || this.sampleType === 'STANDARD') {
        if (diagnostic.diagnostic) { // diagnosticColor
          translation = (this.$t(`${this.sampleType}_${diagnostic.diagnostic.name}`));
        } else { // string
          translation = (this.$t(`${this.sampleType}_${diagnostic}`));
        }
      } else {
        if (diagnostic.diagnostic) { // diagnosticColor
          translation = this.$t(diagnostic.diagnostic.name);
          if (diagnostic.diagnosticLabel) {
            translation += ' ' + diagnostic.diagnosticLabel;
          }
        } else { // string
          translation = this.$t(diagnostic);
        }
      }

      return translation;
    }
  }
};
</script>
