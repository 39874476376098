<template>
  <div>
    <b-jumbotron
      bg-variant="transparent"
      border-variant="dark"
      class="pb-4 well-configuration-jumbotron">
      <transition
        mode="out-in"
        name="slide-fade">
        <b-container>
          <b-form-group
            :label="$t('formTemplateName')"
            class="form-group-fixed">
            <b-form-input
              ref="templateNameInput"
              v-model="name"
              :state="validateState('name')"
              type="text" />
          </b-form-group>
          <b-form-row>
            <b-container class="d-flex flex-row align-items-center justify-content-between p-4">
              <b-link
                ref="cancelLink"
                class="fa-pull-left align-middle"
                @click="$emit('close')">
                {{ $t('cancel') }}
              </b-link>
              <b-button
                ref="savePlateTemplateButton"
                @click.once="openModalIfNecessaryOrSaveTemplate">
                {{ $t('save') }}
              </b-button>
            </b-container>
          </b-form-row>
        </b-container>
      </transition>
    </b-jumbotron>
    <app-confirmation-modal
      ref="cancelModal"
      :cancel-button-name="$t('no')"
      :message="$t('modalCreateTemplateMessage', [name])"
      :ok-button-name="$t('yes')"
      :title="$t('modalCreateTemplateTitle')"
      ok-button-prevent-hiding
      ok-button-spinners-enabled
      @ok="updatePlateTemplate" />
  </div>
</template>

<script>
import PlateTemplate from '../../../../../../../models/PlateTemplate';
import NotificationMixin from '../../../../../../../mixins/NotificationMixin';
import ValidationMixin from '../../../../../../../mixins/ValidationMixin';
import { required } from 'vuelidate/lib/validators';
import ConfirmationModal from '../../../../../../common/ConfirmationModal';
import cloneDeep from 'lodash/cloneDeep';
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin,
    ValidationMixin
  ],
  props: {
    wells: {
      type: Array,
      required: true
    },
    availableTemplates: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      name: undefined
    };
  },
  validations: {
    name: {
      required
    }
  },
  computed: {
    ...mapFields('runImport', [
      'run.analyses'
    ])
  },
  methods: {
    openModalIfNecessaryOrSaveTemplate() {
      const isTemplateNameExist = !!this.availableTemplates.find(template => template.name === this.name);
      if (isTemplateNameExist) {
        this.$refs.cancelModal.showModal();
      } else {
        this.savePlateTemplate();
      }
    },
    updatePlateTemplate() {
      const templateToUpdate = cloneDeep(this.availableTemplates.find(template => template.name === this.name));

      // Set channel for wells in plate template (because they don't have analysis yet)
      templateToUpdate.wells = this.setChannels(this.wells);

      this.$store.dispatch('template/updatePlateTemplate', templateToUpdate)
        .then(() => {
          this.showSuccessNotification(
            this.$t('plateTemplateCreatedTitle'),
            this.$t('plateTemplateCreatedBody'),
            {
              root: true
            });
          this.$emit('templateSaved');
        })
        .catch(error => {
          this.showErrorNotification(this.$t('plateTemplateCreatedErrorTitle'), error);
        });
    },
    savePlateTemplate() {
      if (this.isValid()) {
        const templateToSave = PlateTemplate.build({
          id: null,
          laboratory: null,
          name: this.name,
          wells: []
        });

        // Set channel for wells in plate template (because they don't have analysis yet)
        templateToSave.wells = this.setChannels(this.wells);

        this.$store.dispatch('template/createPlateTemplate', templateToSave)
          .then(() => {
            this.showSuccessNotification(
              this.$t('plateTemplateCreatedTitle'),
              this.$t('plateTemplateCreatedBody'),
              {
                root: true
              });
            this.$emit('templateSaved');
          })
          .catch(error => {
            this.showErrorNotification(this.$t('plateTemplateCreatedErrorTitle'), error);
          });
      }
    },
    setChannels(wells) {
      const templateWithAllWells = [];
      this.analyses.forEach(analysis => {
        wells.forEach(well => {
          const wellTemplate = cloneDeep(this.$store.getters['runImport/getWellByAnalysisAndPosition'](analysis, well.position));
          wellTemplate.channel = analysis.channel;
          templateWithAllWells.push(wellTemplate);
        });
      });

      return templateWithAllWells;
    }
  }
};
</script>
