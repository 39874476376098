<template>
  <div class="position-relative">
    <b-button
      :aria-expanded="kitVisible ? 'true' : 'false'"
      :class="kitVisible ? 'collapse-button' : 'collapse-button collapsed'"
      aria-controls="collapse-kit"
      @click="kitVisible = !kitVisible">
      <i
        v-if="kitVisible"
        aria-hidden="true"
        class="fa fa-chevron-up" />
      <i
        v-if="!kitVisible"
        aria-hidden="true"
        class="fa fa-chevron-down" />
    </b-button>
    <h5 :class="kitVisible ? 'collapse-title-hidden' : 'collapse-title-show'">
      {{
        $t('configuration.kit.kit') + " - " + $t('configuration.kit.analyzer') + " - " + $t('configuration.kit.reaction_buffers')
      }}
    </h5>
    <b-collapse
      id="collapse-kit"
      v-model="kitVisible">
      <b-form-row>
        <b-col
          class="pr-xl-1"
          lg="8">
          <div class="text-lg-center">
            <h5>
              {{ $t('configuration.kit.kit') }}
            </h5>
          </div>
          <b-form-row>
            <b-col lg="3">
              <b-form-group :label="$t('formKit')">
                <app-kit-editable-multiselect
                  ref="appKitEditableMultiselect"
                  v-model="kit"
                  :can-add="true"
                  :disabled="kitEdited()"
                  @addKit="addKit"
                  @optionsLoaded="kitOptionsLoaded"
                  @remove="kitRemoved"
                  @select="kitSelected" />
              </b-form-group>
            </b-col>
            <b-col lg="1">
              <div class="plus-symbol">
                +
              </div>
            </b-col>
            <b-col lg="3">
              <b-form-group :label="$t('formAnalyser')">
                <app-analyser-dropdown
                  v-model="analyser"
                  :allow-empty="true"
                  :disabled="analyserEdited()"
                  :show-labels="false"
                  :overriding-options="analyserConfigured"
                  :display-badge="displayBadgeForAnalyser()"
                  @remove="analyserRemoved"
                  @select="analyserSelected" />
              </b-form-group>
            </b-col>
            <b-col lg="1">
              <div class="equal-symbol">
                =
              </div>
            </b-col>
            <b-col lg="2">
              <b-form-group :label="$t('formKitStatus')">
                <app-string-array-dropdown
                  v-model="status"
                  :allow-empty="true"
                  :disabled="!kit || !analyser || !analyser.id || disabled"
                  :overriding-options="KitStatusEnum"
                  :placeholder="$t('formSelectStatus')"
                  :show-labels="false"
                  :translate="true" />
              </b-form-group>
            </b-col>
            <b-col lg="2">
              <b-form-group :label="$t('formKitVersion')">
                <app-kit-version-dropdown
                  ref="appKitVersionDropdown"
                  v-model="kitVersion"
                  :can-add="canAdd() && !disabled"
                  :disabled="kitVersionEdited() || !kit"
                  :kit="kit"
                  @addKv="addKv"
                  @canModify="isKitVersionModifiable = $event"
                  @remove="kitVersionRemoved"
                  @select="kitVersionSelected"
                  @isNewVersionAddable="isNewVersionAddable = $event" />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-col>
        <b-col
          class="pl-xl-1"
          lg="4">
          <div class="text-lg-center">
            <h5>
              {{ $t('configuration.kit.reaction_buffers') }}
            </h5>
          </div>
          <b-form-row>
            <b-col lg="6">
              <b-form-group :label="$t('formReactionBuffer')">
                <app-reaction-buffer-dropdown
                  v-model="reactionBuffer"
                  :can-add="isVersionModifiable"
                  :disabled="rbAndChannelEdited() || !kit"
                  :overriding-options="reactionBuffersConfigured"
                  :preselect-single-overriding-option="true"
                  :display-badge="displayBadgeForRb()"
                  @addRb="addRb"
                  @remove="rbRemoved"
                  @select="rbSelected" />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group :label="$t('formChannel')">
                <app-channel-dropdown
                  :value="channel.name"
                  :allow-empty="true"
                  :disabled="rbAndChannelEdited() || !kit ||!reactionBuffer"
                  :overriding-options="ChannelEnum"
                  :channels="configuredChannel"
                  :placeholder="$t('formSelectChannel')"
                  :show-labels="false"
                  :preselect-single-overriding-option="true"
                  :display-badge="displayBadgeForChannel()"
                  @remove="channelRemoved"
                  @select="channelSelected" />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-col>
      </b-form-row>
    </b-collapse>
  </div>
</template>

<script>
import StringArrayDropdown from '@/components/common/StringArrayDropdown';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {KitStatusEnum} from '@/enums/KitStatus.enum';
import {ChannelEnum} from '@/enums/Channel.enum';
import {
  findAllAnalysers,
  findAllPhasesByReactionBufferAndAnalyserAndChannel,
  findAllPhasesByReactionBufferAndAnalyserAndChannelLastVersion,
  findAllRbAdmin,
  findKitChannels,
  findKitStatusByAnalyser,
  getConfiguredAnalyser
} from '@/service/GenefoxService';
import KitEditableMultiselect from '@/components/common/KitEditableMultiselect';
import ReactionBufferDropdown from '@/components/common/ReactionBufferDropdown';
import AnalyserDropdown from '@/components/common/AnalyserDropdown';
import ChannelDropdown from '@/components/common/ChannelDropdown';
import Kit from '@/models/Kit';
import ReactionBuffer from '@/models/ReactionBuffer';
import {mapFields} from 'vuex-map-fields';
import KitVersionDropdown from '@/components/common/KitVersionDropdown';

export default {
  components: {
    'app-kit-editable-multiselect': KitEditableMultiselect,
    'app-kit-version-dropdown': KitVersionDropdown,
    'app-string-array-dropdown': StringArrayDropdown,
    'app-reaction-buffer-dropdown': ReactionBufferDropdown,
    'app-analyser-dropdown': AnalyserDropdown,
    'app-channel-dropdown': ChannelDropdown
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapFields('configuration', [
      'kitVersion',
      'kit',
      'reactionBuffer',
      'analyser',
      'channel',
      'phases',
      'isKitVersionModifiable'
    ]),
    configuredChannel() {
      return this.reactionBuffer?.channels;
    },
    isVersionModifiable() {
      return this.kitVersion?.kit?.id ? this.kitVersion?.id && this.kitVersion.isEditable ? this.isKitVersionModifiable : false : true;
    }
  },
  data() {
    return {
      kitVisible: true,
      status: undefined,
      channels: [],
      currentKit: undefined,
      currentKitVersion: undefined,
      currentStatus: undefined,
      analyserConfigured: [],
      reactionBuffersConfigured: [],
      isNewVersionAddable: false,
      KitStatusEnum,
      ChannelEnum
    };
  },
  methods: {
    canAdd() {
      return (this.isNewVersionAddable && !!this.kitVersion?.id) || (!this.kitVersion?.kit?.id && !this.kitVersion?.id && !this.kitVersion?.version);
    },
    resetData() {
      this.currentKit = undefined;
      this.currentKitVersion = undefined;
      this.currentStatus = undefined;
    },
    kitSelected(kit) {
      this.kit = kit;
      this.currentKit = cloneDeep(kit);
      this.kitVersion = undefined;
      this.currentKitVersion = undefined;
      this.findStatus();
    },
    kitVersionSelected(kitVersion) {
      this.kitVersion = kitVersion;
      this.currentKitVersion = cloneDeep(kitVersion);
      this.findConfiguredAnalyser();
      this.findRb();
      this.findPhases();
    },
    rbSelected(rb) {
      this.reactionBuffer = rb;
      this.findChannels();
    },
    analyserSelected(analyser) {
      this.analyser = analyser;
      this.findStatus();
      this.findRb();
      if (!this.channel?.id && this.channels?.length > 0) {
        this.channelSelected(this.channels[0].name);
      }
      else {
        this.findPhases();
      }
    },
    channelSelected(channelName) {
      this.channel = this.channels.find(c => c.name === channelName);
      if (!this.channel) {
        this.channel = {};
        this.channel.name = channelName;
      }
      this.findPhases();
    },
    kitRemoved() {
      this.kit = undefined;
      this.currentKit = undefined;
      this.kitVersion = undefined;
      this.currentKitVersion = undefined;
      this.status = undefined;
      this.currentStatus = undefined;
      this.rbRemoved();
      this.channel = {};
      this.channels = [];
      this.analyserRemoved();
      this.$parent.$refs.appCombinationConfiguration.resetData();
    },
    kitVersionRemoved() {
      this.kitVersion = null;
      this.currentKitVersion = null;
      this.analyserConfigured = [];
      this.reactionBuffersConfigured = [];
      this.$parent.$refs.appPhaseConfiguration.initPhases(undefined);
    },
    rbRemoved() {
      this.reactionBuffer = undefined;
      this.channels = [];
      this.channelRemoved();
    },
    analyserRemoved() {
      this.analyser = undefined;
      this.reactionBuffersConfigured = [];
      this.$parent.$refs.appPhaseConfiguration.initPhases(undefined);
    },
    channelRemoved() {
      const channel = {};
      this.$store.dispatch('configuration/setChannel', { channel });
      this.$parent.$refs.appPhaseConfiguration.initPhases(undefined);
    },
    addKit(kitName) {
      if (!kitName) {
        this.showErrorNotification(this.$t('errorTitleAddingKit'), this.$t('errorNoKitName'));

        return;
      }
      this.kitRemoved();
      this.kit = new Kit(null, kitName, false, false, [], [], [], null, true);
      this.findConfiguredAnalyser();
    },
    addKv(kv) {
      if (!kv) {
        this.showErrorNotification(this.$t('errorTitleAddingKitVersion'), this.$t('errorNoKitVersionName'));

        return;
      }
      if (!this.currentKitVersion) {
        kv.isFirst = true;
      }
      this.kitVersion = kv;
      this.isNewVersionAddable = false;
      this.currentKitVersion = cloneDeep(this.kitVersion);
      this.findPhases();
    },
    addRb(rbName) {
      if (!rbName) {
        this.showErrorNotification(this.$t('errorTitleAddingRb'), this.$t('errorNoRbName'));

        return;
      }
      if (!this.kit.name) {
        this.showErrorNotification(this.$t('errorTitleAddingRb'), this.$t('errorNoKitSelected'));

        return;
      }
      const reactionBuffer = new ReactionBuffer(null, rbName, true);
      this.rbSelected(reactionBuffer);
      this.$store.dispatch('configuration/addReactionBufferToKit', { reactionBuffer });
    },
    findStatus() {
      if (!this.kit?.id || !this.analyser) {
        this.status = undefined;
        this.currentStatus = undefined;

        return;
      }

      return new Promise((resolve, reject) => {
        findKitStatusByAnalyser(this.kit.id, this.analyser.id)
          .then(status => {
            this.status = status;
            this.currentStatus = cloneDeep(status);
            resolve(status);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    findConfiguredAnalyser() {
      if (this.kitVersion?.id) {
        getConfiguredAnalyser(this.kitVersion?.id).then(analysers => {
          this.analyserConfigured = analysers;
        }).catch(error => console.error(error));
      } else {
        findAllAnalysers().then(analysers => {
          this.analyserConfigured = analysers;
        }).catch(error => console.error(error));
      }
    },
    findRb() {
      if (this.kitVersion?.id && this.analyser?.id) {
        findAllRbAdmin(this.kitVersion?.id, this.analyser.id)
          .then(reactionBuffers => {
            this.reactionBuffersConfigured = reactionBuffers;

            const rbFound = this.reactionBuffersConfigured.find(rb => rb.id === this.reactionBuffer?.id);
            this.rbSelected(rbFound ?
              rbFound : this.reactionBuffersConfigured.length ?
                this.reactionBuffersConfigured[0] : undefined);
          }).catch(error => console.error(error));
      } else {
        this.rbSelected(this.kitVersion?.kit?.reactionBuffers ? this.kitVersion.kit.reactionBuffers[0] : undefined);
        this.reactionBuffersConfigured = this.kitVersion?.kit?.reactionBuffers ? this.kitVersion.kit.reactionBuffers : [];
      }
    },
    findChannels() {
      if (!this.kit?.id || !this.reactionBuffer?.id) {
        this.channel = {};
        this.channels = [];
        this.$parent.$refs.appPhaseConfiguration.initPhases(undefined);

        return;
      }

      return new Promise((resolve, reject) => {
        findKitChannels(this.kit.id, this.reactionBuffer.id)
          .then(channels => {
            this.channels = channels;
            if (this.channel && this.channels?.length) {
              this.channel = this.channels.find(c => c.name === this.channel.name) || {};
            } else {
              this.channel = {};
            }
            this.findPhases();
            resolve(channels);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    findPhases() {
      if (!this.reactionBuffer?.id || !this.analyser || !this.channel?.name || !this.kit?.id || !this.kitVersion) {
        this.$parent.$refs.appPhaseConfiguration.initPhases(undefined);

        return;
      }

      return new Promise((resolve, reject) => {
        let promise;
        if (this.kitVersion.id) {
          promise = findAllPhasesByReactionBufferAndAnalyserAndChannel(this.kitVersion.id, this.reactionBuffer.id, this.analyser.id, this.channel.name);
        } else {
          promise = findAllPhasesByReactionBufferAndAnalyserAndChannelLastVersion(this.kit.id, this.reactionBuffer.id, this.analyser.id, this.channel.name);
        }
        promise
          .then(phases => {
            this.$parent.$refs.appPhaseConfiguration.initPhases(phases);
            resolve(phases);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    kitEdited() {
      return (this.currentKit && !isEqual(this.currentKit.id, this.kit.id))
             || this.kitVersionEdited()
             || (this.kitVersion && !this.kitVersion.id)
             || this.analyserEdited();
    },
    kitVersionEdited() {
      return !isEqual(this.currentKitVersion, this.kitVersion)
             || this.rbAndChannelEdited();
    },
    analyserEdited() {
      return (this.currentStatus && !isEqual(this.currentStatus, this.status))
             || (this.$parent.$refs.appPhaseConfiguration ? this.$parent.$refs.appPhaseConfiguration.edited() : false);
    },
    rbAndChannelEdited() {
      return (this.$parent.$refs.appPhaseConfiguration ? this.$parent.$refs.appPhaseConfiguration.edited() : false);
    },
    displayBadgeForAnalyser() {
      return !!this.kitVersion && !!this.kitVersion.id;
    },
    displayBadgeForRb() {
      return this.displayBadgeForAnalyser() && !!this.analyser;
    },
    displayBadgeForChannel() {
      return this.displayBadgeForRb() && !!this.reactionBuffer && !!this.reactionBuffer.id;
    },
    getChannels() {
      if (!this.channel?.name) {
        return [];
      }
      if (!this.channels) {
        this.channels = [];
      }

      if (!this.channel.phases) {
        this.$store.dispatch('configuration/setChannelPhases', { phases: this.phases });
      } else {
        const copiedPhases = cloneDeep(this.channel.phases.filter(p => p.id));
        const finalKitVersion = this.kitVersion;
        const finalAnalyser = this.analyser;
        this.phases?.forEach(p => {
          if (!p.id) {
            const newPhase = cloneDeep(p);
            if (!newPhase.kitVersion) {
              newPhase.kitVersion = finalKitVersion;
            }
            if (!newPhase.analyser) {
              newPhase.analyser = finalAnalyser;
            }
            if (!newPhase.inversed) {
              newPhase.inversed = false;
            }
            copiedPhases.push(newPhase);
          } else {
            const idx = copiedPhases.findIndex(cp => cp.id === p.id);
            if (idx !== -1) {
              copiedPhases.splice(idx, 1, p);
            }
          }
        });

        // Remove deleted
        copiedPhases?.filter(cp => cp.analyser?.id === this.analyser?.id && cp.kitVersion?.id === this.kitVersion?.id).forEach(cp => {
          const deletedIdx = this.phases.findIndex(p => p.id === cp.id);
          if (deletedIdx === -1) {
            const realPosition = copiedPhases.findIndex(c => c.id === cp.id);
            copiedPhases.splice(realPosition, 1);
          }
        });

        this.$store.dispatch('configuration/setChannelPhases', { phases: copiedPhases });
      }

      const index = this.channels.findIndex(c => c.name === this.channel.name);
      if (index !== -1) {
        this.channels.splice(index, 1, this.channel);
      } else {
        this.channels.push(this.channel);
      }

      return this.channels;
    },
    kitSaved(kitToSave) {
      this.$store.dispatch('configuration/setKitVersion', { kitVersion: kitToSave.kitVersion });
      this.$refs.appKitVersionDropdown.reloadKitVersions(this.kitVersion.kit.id, this.currentKitVersion);
      this.currentKitVersion = cloneDeep(this.kitVersion);
      this.currentKit = cloneDeep(this.kit);
      this.status = kitToSave.status;
      this.currentStatus = cloneDeep(this.status);
      this.reactionBuffer = kitToSave.reactionBuffer;
      this.$store.dispatch('configuration/setReactionBuffer', { reactionBuffer: kitToSave.reactionBuffer });
      this.analyser = kitToSave.analyser;
      this.channels = cloneDeep(kitToSave.channels);
      const channel = kitToSave.channels.find(c => c.name === this.channel.name);
      this.$store.dispatch('configuration/setChannel', { channel });
    },
    cancel() {
      const kv = this.currentKitVersion;
      this.$refs.appKitEditableMultiselect.initOptions();
      this.$refs.appKitVersionDropdown.reloadKitVersions(this.kitVersion.kit.id, this.currentKitVersion);
      this.kitSelected(this.currentKit);
      this.kitVersionSelected(this.currentKit?.id && kv?.id ? kv : undefined);
    },
    kitOptionsLoaded() {
      if (!this.currentKit) {
        return;
      }
      if (!this.$refs.appKitEditableMultiselect.options.find(o => o.id === this.currentKit.id)) {
        this.kitSelected(undefined);
        this.kitVersionSelected(undefined);
      }
    }
  }
};
</script>
