import ReactionBuffer from '@/models/ReactionBuffer';
import Analyser from '@/models/Analyser';
import KitVersion from "@/models/KitVersion";
import Channel from "@/models/Channel";
import CombinationDiagnostic from "@/models/CombinationDiagnostic";

export default class KitToSave {
  constructor(kitVersion, reactionBuffer, analyser, status, channels, combinationDiagnostics) {
    this.kitVersion = kitVersion;
    this.reactionBuffer = reactionBuffer;
    this.analyser = analyser;
    this.status = status;
    this.channels = channels;
    this.combinationDiagnostics = combinationDiagnostics;
  }

  static build({ kitVersion, reactionBuffer, analyser, status, channels, combinationDiagnostics }) {
    return new KitToSave(kitVersion, reactionBuffer, analyser, status, channels, combinationDiagnostics);
  }

  static parse(value) {
    return value ? KitToSave.build({
      kitVersion: KitVersion.parse(value.kitVersion),
      reactionBuffer: ReactionBuffer.parse(value.reactionBuffer),
      analyser: Analyser.parse(value.analyser),
      status: value.status,
      channels: value.channels?.length > 0 ? value.channels.map(c => Channel.parse(c)) : [],
      combinationDiagnostics: value.combinationDiagnostics?.length > 0 ? value.combinationDiagnostics.map(cd => CombinationDiagnostic.parse(cd)) : []
    }) : null;
  }

  toJSON() {
    return {
      kitVersion: this.kitVersion !== undefined || this.kitVersion !== '' ? this.kitVersion : null,
      reactionBuffer: this.reactionBuffer !== undefined ? this.reactionBuffer : null,
      analyser: this.analyser !== undefined ? this.analyser : null,
      status: this.status !== undefined || this.status !== '' ? this.status : null,
      channels: this.channels !== undefined || this.channels !== '' ? this.channels : null,
      combinationDiagnostics: this.combinationDiagnostics !== undefined || this.combinationDiagnostics !== '' ? this.combinationDiagnostics : null
    };
  }
}