<template>
  <div>
    <span class="align-middle">{{ name }}</span>
    <b-badge
      pill
      :variant="isReactionBufferConfigured ? 'success' : 'danger'"
      class="badge-outlined ml-1">
      {{ isReactionBufferConfigured ? $t('analyserConfigured') : $t('analyserNotConfigured') }}
    </b-badge>
  </div>
</template>

<script>
export default {
  props: {
    reactionBuffer: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    isReactionBufferConfigured: {
      type: Boolean,
      default: false
    }
  }
};
</script>
