<template>
  <div>
    <span class="align-middle">{{ channel }}</span>
    <b-badge
      v-if="displayPill"
      pill
      :variant="isChannelConfigured ? 'success' : 'danger'"
      class="badge-outlined ml-1">
      {{ isChannelConfigured ? $t('analyserConfigured') : $t('analyserNotConfigured') }}
    </b-badge>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      type: String,
      default: null
    },
    channels: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    displayPill() {
      return this.channels?.map(channel => channel.name).includes(this.channel);
    },
    isChannelConfigured() {
      return this.channels?.some(channel => channel.name === this.channel && channel.configured);
    }
  }
};
</script>
