import ReactionBuffer from './ReactionBuffer';
import ChannelAssignment from './ChannelAssignment';

export default class Kit {
  constructor(id, name, melt, anneal, reactionBuffers, channelAssignments, diagnostics, status, active) {
    this.id = id;
    this.name = name;
    this.melt = melt;
    this.anneal = anneal;
    this.reactionBuffers = reactionBuffers ? reactionBuffers : [];
    this.channelAssignments = channelAssignments ? channelAssignments : [];
    this.diagnostics = diagnostics ? diagnostics : [];
    this.status = status;
    this.active = active;
  }

  static build({ id, name, status, melt, anneal, reactionBuffers, channelAssignments, diagnostics, active }) {
    return new Kit(id, name, melt, anneal, reactionBuffers, channelAssignments, diagnostics, status, active);
  }

  static parse(value) {
    return value ? Kit.build({
      id: value.id,
      name: value.name,
      status: value.status,
      melt: value.melt,
      anneal: value.anneal,
      reactionBuffers: value?.reactionBuffers?.map(reactionBuffer => ReactionBuffer.parse(reactionBuffer)),
      channelAssignments: value.channelAssignments?.map(channelAssignment => ChannelAssignment.parse(channelAssignment)),
      diagnostics: value.diagnostics,
      active: value.active
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      status: this.status !== undefined || this.status !== '' ? this.status : null,
      melt: this.melt !== undefined || this.melt !== '' ? this.melt : null,
      anneal: this.anneal !== undefined || this.anneal !== '' ? this.anneal : null,
      reactionBuffers: this.reactionBuffers !== undefined ? this.reactionBuffers : null,
      channelAssignments: this.channelAssignments !== undefined ? this.channelAssignments : null,
      diagnostics: this.diagnostics !== undefined ? this.diagnostics : null,
      active: this.active !== undefined || this.active !== '' ? this.active : null
    };
  }
}
