<template>
  <div>
    <app-information-header
      :business-id="businessId"
      :file-name="fileName"
      :created-by-name="createdByName" />
    <b-container>
      <b-form-group>
        <h3>{{ $t('subTitlePageRun') }}</h3>
      </b-form-group>
      <b-form-row>
        <b-col sm="4">
          <b-form-group
            :label="$t('formAnalyser')">
            <b-input-group>
              <b-form-input
                v-model="instrumentName"
                :state="validateState('instrumentName')"
                type="text" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            :label="$t('formPlateId')">
            <b-input-group>
              <b-form-input
                v-model="plateId"
                type="text" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            :label="$t('formRunDate')">
            <b-input-group>
              <b-form-input
                v-model="created"
                disabled
                type="date" />
              <h3 class="ml-2">
                <font-awesome-icon :icon="['far', 'calendar-alt']" />
              </h3>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form-row>
      <hr color="lightgrey">
      <app-kit-and-lot-input-rows
        :state="validateState('availableKits')"
        @input="availableKits = $event" />
      <br><br><br>
    </b-container>
    <b-form-row>
      <b-container class="d-flex flex-row align-items-center justify-content-between p-4">
        <app-cancel-run />
        <b-button
          ref="nextButton"
          @click="validateRun">
          {{ $t('next') }}
        </b-button>
      </b-container>
    </b-form-row>
    <app-confirmation-modal
      ref="selectedLotsIsExpiredModal"
      :message="$t('modalSelectedLotsExpired.message')"
      :ok-button-name="$t('modalSelectedLotsExpired.okButtonText')"
      :ok-button-classes="['run-deletion-modal-ok-button']"
      :cancel-button-enabled="false"
      ok-button-variant="outline-danger"
      header-text-variant="danger"
      @ok="saveRun" />
  </div>
</template>

<script>

import { mapFields } from 'vuex-map-fields';
import { required } from 'vuelidate/lib/validators';
import RunInformationHeader from '../../../RunInformationHeader';
import KitAndLotInputRows from './KitAndLotInputRows';
import ValidationMixin from '../../../../../../mixins/ValidationMixin';
import NotificationMixin from '../../../../../../mixins/NotificationMixin';
import CancelRun from '../../../CancelRun';
import ConfirmationModal from '../../../../../common/ConfirmationModal';
import { saveAcquisitions } from '../../../../../../service/GenefoxService';
import {mapActions} from "vuex";

export default {
  components: {
    'app-information-header': RunInformationHeader,
    'app-kit-and-lot-input-rows': KitAndLotInputRows,
    'app-cancel-run': CancelRun,
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin,
    ValidationMixin
  ],
  computed: {
    ...mapFields('runImport', [
      'run',
      'run.fileName',
      'run.instrumentName',
      'run.plateId',
      'run.created',
      'run.createdByName',
      'run.lotName',
      'runFormStep',
      'run.businessId',
      'run.analyser',
      'availableKits'
    ]),
    ...mapActions('runImport', [
      'getDiagnosticColorsByChannelRb'
    ])
  },
  validations: {
    instrumentName: {
      required
    },
    availableKits: {
      atLeastOneKit: value => value.length > 0
    }
  },
  methods: {
    saveRun() {
      this.runFormStep = 2;
      const files = this.$store.getters['runImport/getFiles'];
      saveAcquisitions(this.run.id, files, this.run.analyser, this.availableKits.map(kitVersion => kitVersion.kit.id))
        .then(() => this.$store.dispatch('runImport/getDiagnosticColorsByChannelRb'))
        .catch(error => {
          if (!error.response)
            this.showErrorNotification(this.$t('error'), error.message);
          else if (error.response.status === 500)
            this.showErrorNotification(this.$t('error'), this.$t('invalidFileError', [ error.response?.data?.message ]));
          else if (error.response.status === 422)
            this.showErrorNotification(this.$t('error'), this.$t('corruptedFileError'));
          else
            this.showErrorNotification(this.$t('error'), error.message);
        });
    },
    validateRun() {
      if (this.isValid()) {
        const isExpired = this.isLotDateValid();

        if (isExpired) {
          this.$refs.selectedLotsIsExpiredModal.showModal();
        } else {
          this.saveRun();
        }
      }
    },
    isLotDateValid() {
      let isExpired = false;
      const now = new Date();
      now.setHours(0,0,0,0);
      this.availableKits.forEach(kitVersion => {
        kitVersion.lots.forEach(lot => {
          if (lot.expirationDate && now > lot.expirationDate) {
            isExpired = true;
          }
        });
      });

      return isExpired;
    }
  }
};
</script>
