export default class Release {
  constructor(version, date, note, algoVersion) {
    this.version = version;
    this.date = date;
    this.note = note;
    this.algoVersion = algoVersion;
  }

  static build({ version, date, note, algoVersion }) {
    return new Release(version, date, note, algoVersion);
  }

  static parse(value) {
    return value ? Release.build({
      version: value.version,
      date: value.date,
      note: value.note,
      algoVersion: value.algoVersion
    }) : null;
  }

  toJSON() {
    return {
      version: this.version !== undefined || this.version !== '' ? this.version : null,
      date: this.date !== undefined || this.date !== '' ? this.date : null,
      note: this.note !== undefined || this.note !== '' ? this.note : null,
      algoVersion: this.algoVersion !== undefined || this.algoVersion !== '' ? this.algoVersion : null
    };
  }
}
