export default class Analyser {
  constructor(id, name, version, maxNumberOfFilesByRun, active, configured) {
    this.id = id;
    this.name = name;
    this.version = version;
    this.maxNumberOfFilesByRun = maxNumberOfFilesByRun;
    this.active = active;
    this.configured = configured;
  }

  static build({ id, name, version, maxNumberOfFilesByRun, active, configured }) {
    return new Analyser(id, name, version, maxNumberOfFilesByRun, active, configured);
  }

  static parse(value) {
    return value ? new Analyser(
      value.id,
      value.name,
      value.version,
      value.maxNumberOfFilesByRun,
      value.active,
      value.configured
    ) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name  !== undefined || this.name !== '' ? this.name : null,
      version: this.version  !== undefined || this.version !== '' ? this.version : null,
      maxNumberOfFilesByRun: this.maxNumberOfFilesByRun  !== undefined || this.maxNumberOfFilesByRun !== '' ? this.maxNumberOfFilesByRun : null,
      active: this.active  !== undefined || this.active !== '' ? this.active : null,
      configured: this.configured  !== undefined || this.configured !== '' ? this.configured : null
    };
  }
}
