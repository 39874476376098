<template>
  <multiselect
      v-model="model"
      :options="options"
      :custom-label="customLabel"
      :placeholder="multiselectPlaceholder"
      :loading="isLoading"
      :allow-empty="allowEmpty"
      :deselect-label="!allowEmpty ? '' : undefined"
      :show-labels="showLabels"
      :class="cssClasses"
      :disabled="disabled"
      @select="$emit('select', $event)"
      @remove="$emit('remove', $event)"/>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';

export default {
  mixins: [DropdownMixin],
  props: {
    value: {
      type: [Object, String],
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorNotificationTitle() {
      return this.$t('phaseDropdownLoadingErrorTitle');
    },
    multiselectPlaceholder() {
      return this.placeholder !== undefined ? this.placeholder : this.$t('formSelectPhase');
    }
  },
  methods: {
    customLabel(value) {
      return value.id ? value.phaseType : value;
    }
  }
};
</script>
