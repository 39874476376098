import { getField, updateField } from 'vuex-map-fields';
import { findRunCharts, updateWellsDiagnostic } from '../../service/GenefoxService';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';

const state = {
  charts: []
};

const getters = {
  getField,
  filteredByWellIds: state => ids => filter(state.charts, chart => includes(ids, chart.wellId)),
  getDiagnosticByWellId: state => id => {
    let diagnosticColor;

    const chart = find(state.charts, { 'wellId': id });
    if (chart) {
      diagnosticColor = chart.interpretedWellDiagnostics.find(interpretedWellDiagnostic => interpretedWellDiagnostic.stateDiagnostic === 'UPDATED')?.diagnosticColor;
      if (!diagnosticColor) {
        diagnosticColor = chart.interpretedWellDiagnostics
          .find(interpretedWellDiagnostic => interpretedWellDiagnostic.stateDiagnostic === 'INITIAL')
          ?.diagnosticColor;
      }
    }

    return diagnosticColor;
  },
  getInterpretedDiagnosticCommentByWellId: state => id => {
    const chart = find(state.charts, { 'wellId': id });
    const comments = [];
    if (chart && chart.interpretedWellDiagnostics?.length > 1) {
      const diagnosticComment = chart.interpretedWellDiagnostics.find(interpretedWellDiagnostic => interpretedWellDiagnostic.stateDiagnostic === 'UPDATED')?.comment;
      comments.push(diagnosticComment);
    }

    return comments;
  },
  isWellAnalyzed: state => well => !!find(state.charts, { 'wellId': well.id }),
  existsAnalyzedWellForWellIds: state => wellIds => state.charts.some(chart => wellIds.includes(chart.wellId))
};

export const mutations = {
  updateField,
  updateChartDiagnostic(state, interpretedWellUpdated) {
    const i = state.charts.findIndex(chart => chart.wellId === interpretedWellUpdated.wellId);
    if (i >= 0) {
      state.charts.splice(i, 1, interpretedWellUpdated);
    }
  }
};

export const actions = {
  findRunCharts({ commit }, runId) {
    return new Promise((resolve, reject) => {
      findRunCharts(runId)
        .then(charts => {
          commit('updateField', { path: 'charts', value: charts });
          resolve();
        })
        .catch(error => reject(error));
    });
  },
  updateWellDiagnostic({ commit }, { runId, wellId, diagnosticColor }) {
    return new Promise((resolve, reject) => {
      updateWellsDiagnostic(runId, [ wellId ], diagnosticColor)
        .then(interpretedWellsUpdated => {
          interpretedWellsUpdated.forEach(interpretedWellUpdated => {
            commit('updateChartDiagnostic', interpretedWellUpdated);
            commit('runImport/setWellDiagnosticComment', { wellId: interpretedWellUpdated.wellId, comment: interpretedWellUpdated.interpretedWellDiagnostics[1].comment }, { root: true });
          });
          resolve(interpretedWellsUpdated[0]);
        })
        .catch(error => reject(error));
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
