<template>
  <div>
    <transition
      appear
      name="fade"
      mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import NotificationMixin from '../../../mixins/NotificationMixin';
import { store } from '@/store/store';

export default {
  mixins: [ NotificationMixin ],
  beforeRouteEnter(to, from, next) {
    if (from.name !== 'run-import') {
      store.dispatch('runImport/findRun', to.params.id)
        .then(() => {
          next();
        })
        .catch(error => {
          console.log('run navigation error', error);
          next({ name: 'run-list' });
        });
    } else {
      next();
    }
  }
};
</script>
