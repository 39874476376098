export default class Diagnostic {
  constructor(name, order, active, kits, label) {
    this.name = name;
    this.order = order;
    this.active = active;
    this.kits = kits;
    this.label = label;
  }

  static build({ name, order, active, kits, label }) {
    return new Diagnostic(name, order, active, kits, label);
  }

  static parse(value) {
    return value ? Diagnostic.build({
      name: value.name,
      order: value.order,
      active: value.active,
      kits: value.kits,
      label: value.label
    }) : null;
  }

  toJSON() {
    return {
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      order: this.order !== undefined || this.order !== '' ? this.order : null,
      active: this.active !== undefined || this.active !== '' ? this.active : null,
      kits: this.kits !== undefined || this.kits !== '' ? this.kits : null,
      label: this.label !== undefined || this.label !== '' ? this.label : null
    };
  }
}