export default class FTPConfiguration {
  constructor(id, sendFilesOnApproval, protocol, host, port, directory, authentication, username, password, ownerId) {
    this.id = id;
    this.sendFilesOnApproval = sendFilesOnApproval;
    this.protocol = protocol;
    this.host = host;
    this.port = port;
    this.directory = directory;
    this.authentication = authentication;
    this.username = username;
    this.password = password;
    this.ownerId = ownerId;
  }

  static build({ id, sendFilesOnApproval, protocol, host, port, directory, authentication, username, password, ownerId }) {
    return new FTPConfiguration(id, sendFilesOnApproval, protocol, host, port, directory, authentication, username, password, ownerId);
  }

  static parse(value) {
    return value ? new FTPConfiguration(
      value.id,
      value.sendFilesOnApproval,
      value.protocol,
      value.host,
      value.port,
      value.directory,
      value.authentication,
      value.username,
      value.password,
      value.ownerId
    ) : null;
  }

  toJSON() {
    return {
      id: this.id,
      sendFilesOnApproval: this.sendFilesOnApproval,
      protocol: this.protocol,
      host: this.host,
      port: this.port,
      directory: this.directory,
      authentication: this.authentication,
      username: this.username,
      password: this.password,
      ownerId: this.ownerId
    };
  }
}