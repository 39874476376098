<template>
  <v-chart
    :option="option"
    :update-options="updateOptions"
    class="chart-container"
    autoresize/>
</template>

<script>
import {CanvasRenderer} from 'echarts/renderers';

import {LineChart} from 'echarts/charts';

import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  LegendScrollComponent,
  MarkAreaComponent,
  MarkLineComponent,
  MarkPointComponent,
  TooltipComponent
} from 'echarts/components';

import {use} from 'echarts/core';
import find from 'lodash/find';

use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  MarkPointComponent,
  MarkLineComponent,
  MarkAreaComponent,
  LegendComponent,
  LegendScrollComponent,
  TooltipComponent,
  DataZoomComponent
]);

export default {
  props: {
    charts: {
      type: Array,
      default: () => []
    },
    phase: {
      type: String,
      default: 'ANNEAL'
    }
  },
  data() {
    return {
      updateOptions: {
        replaceMerge: [ 'series' ]
      },
      color: [
        '#c23531', '#2f4554', '#61a0a8', '#d48265',
        '#91c7ae', '#749f83', '#ca8622', '#bda29a',
        '#6e7074', '#546570', '#c4ccd3'
      ]
    };
  },
  computed: {
    constantPhaseInfos() {
      return this.charts?.length ? this.charts[0].constantPhaseInfos : null;
    },
    option() {
      return {
        color: this.color,
        tooltip: {
          show: true,
          trigger: 'item',
          formatter: params => params.componentType === 'series' ? params.seriesName : undefined
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          right: 10,
          top: 20,
          bottom: 20
        },
        grid: {
          left: '14%',
          top: '25%'
        },
        xAxis: {
          name: 'Temperature (°C)',
          nameLocation: 'middle',
          nameGap: 20,
          type: 'value',
          scale: true
        },
        yAxis: {
          name: 'd/dt Fluor',
          nameLocation: 'middle',
          nameGap: 60,
          type: 'value',
          boundaryGap: [ 0, '30%' ]
        },
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: [ 0 ],
            filterMode: 'none',
            bottom: 15,
            height: 20
          },
          {
            type: 'inside',
            xAxisIndex: [ 0 ],
            filterMode: 'none'
          },
          {
            type: 'slider',
            yAxisIndex: [ 0 ],
            filterMode: 'none',
            right: 15,
            width: 20
          },
          {
            type: 'inside',
            yAxisIndex: [ 0 ],
            filterMode: 'none'
          }
        ],
        series: [
          ...this.constantPeakInfoSeries,
          ...this.interpretationLines
        ]
      };
    },
    constantPeakInfoSeries() {
      return this.constantPhaseInfos?.length ? [ {
        type: 'line',
        markLine: {
          symbol: [ 'none', 'none' ],
          emphasis: {
            label: {
              show: true
            }
          },
          label: {
            show: false
          },
          data: this.linesData
        },
        markArea: {
          label: {
            rotate: 20,
            align: 'left'
          },
          data: this.areasData
        }
      } ] : [];
    },
    linesData() {
      const lines = [];

      this.constantPhaseInfos?.forEach(cpi => {
        if (this.phase === cpi.type) {
          lines.push({ yAxis: cpi.threshold });
          cpi.constantZoneInfos.forEach(czi => {
            lines.push({
              xAxis: czi.minTemp,
              label: {
                position: 'insideEndTop'
              }
            }, {
              xAxis: czi.maxTemp,
              label: {
                position: 'insideEndBottom'
              }
            });
          });
        }
      });

      return lines;
    },
    areasData() {
      const areas = [];

      this.constantPhaseInfos?.forEach(cpi => {
        if (this.phase === cpi.type) {
          cpi.constantZoneInfos.forEach(czi => {
            areas.push([ {
              name: czi.label,
              xAxis: czi.minTemp
            }, {
              xAxis: czi.maxTemp
            } ]);
          });
        }
      });

      return areas;
    },
    interpretationLines() {
      return this.charts.map(chart => {
        const dataForPhase = find(chart.data, { type: this.phase });

        return {
          name: chart.position,
          type: 'line',
          smooth: 0.6,
          symbol: 'circle',
          symbolSize: 2,
          markPoint: dataForPhase.peaks.length > 0 && this.charts.length === 1 ? {
            data: dataForPhase.peaks.map(peak => ({ value: this.roundAccurately(peak.x, 2), xAxis: peak.x, yAxis: peak.y }))
          } : undefined,
          data: dataForPhase.chartPoints?.map(point => [ point.x, point.y ])
        };
      });
    }
  },
  methods: {
    roundAccurately(number, decimalPlaces) {
      return Number(`${Math.round(Number(`${number}e${decimalPlaces}`))}e-${decimalPlaces}`);
    }
  }
};
</script>
