import Vue from 'vue';
import Router from 'vue-router';
import RunImport from './components/run/import/RunImport';
import Run from './components/run/editor/Run';
import RunList from './components/list/RunList';
import RunInterpretation from './components/run/editor/interpretation/RunInterpretation';
import RunConfiguration from './components/run/editor/configuration/RunConfiguration';
import Configuration from './components/configuration/Configuration';
import Laboratories from "@/components/admin/laboratory/Laboratories";
import LaboratoryAdd from "@/components/admin/laboratory/LaboratoryAdd";
import Laboratory from "@/components/admin/laboratory/Laboratory";
import Admin from "@/components/admin/Admin";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/*&state*'
    },
    {
      path: '/run/import',
      name: 'run-import',
      component: RunImport
    },
    {
      path: '/run/:id',
      component: Run,
      children: [
        {
          path: 'configuration',
          name: 'run-creation',
          component: RunConfiguration,
          props: {
            initialFormStep: 1
          }
        },
        {
          path: 'interpretation',
          name: 'run-interpretation',
          component: RunInterpretation
        }
      ]
    },
    {
      path: '/',
      name: 'run-list',
      component: RunList
    },
    {
      path: '/laboratory',
      name: 'laboratory',
      component: Laboratory
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      children: [
        {
          path: 'configuration',
          name: 'configure',
          component: Configuration
        },
        {
          path: 'laboratories',
          name: 'laboratories',
          component: Laboratories
        },
        {
          path: 'add',
          name: 'laboratory-add',
          component: LaboratoryAdd
        }
      ]
    },
    {
      path: '/*',
      redirect: '/404'
    }
  ]
});
