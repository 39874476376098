import Well from './Well';

export default class Analysis {
  constructor(id, name, channel, wells) {
    this.id = id;
    this.name = name;
    this.channel = channel;
    this.wells = wells ? wells : new Map();
  }

  static build({ id, name, channel, wells }) {
    return new Analysis(id, name, channel, wells);
  }

  static parse(value) {
    return value ? Analysis.build({
      id: value.id,
      name: value.name,
      channel: value.channel,
      wells: value.wells ? value.wells.reduce((map, well) => {
        map.set(well.position, Well.parse(well));
        return map;
      }, new Map()) : new Map(),
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      channel: this.channel !== undefined || this.channel !== '' ? this.channel : null,
      wells: this.wells !== undefined || this.wells !== '' ? Array.from(this.wells.values()) : null,
    };
  }
}
