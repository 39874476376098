<template>
  <div class="mt-3">
    <h6> {{ $t('captionTitle') }}</h6>
    <b-jumbotron
      bg-variant="transparent"
      border-variant="dark"
      class="init-jumbotron p-2">
      <b-row>
        <b-col class="p-2">
          <template v-for="diagnosticColor in filteredDiagnosticColors">
            <div
              role="button"
              @click="emitClickEvent(diagnosticColor)">
              <app-well
                :forced-diagnostic="diagnosticColor"
                class="align-middle" /> <span class="align-middle">{{ diagnosticName(diagnosticColor) }}</span>
            </div>
          </template>
        </b-col>
        <b-col class="p-2">
          <div
            role="button"
            @click="emitClickEvent('TEST_OK','POSITIVE_TEST')">
            <app-well
              forced-sample-type="POSITIVE_TEST"
              forced-diagnostic="TEST_OK"
              class="align-middle" /> <span class="align-middle">{{ $t('captionValidPositiveTestWell') }}</span>
          </div>
          <div
            role="button"
            @click="emitClickEvent('TEST_KO','POSITIVE_TEST')">
            <app-well
              forced-sample-type="POSITIVE_TEST"
              forced-diagnostic="TEST_KO"
              class="align-middle" /> <span class="align-middle">{{ $t('captionBadPositiveTestWell') }}</span>
          </div>
          <div
            role="button"
            @click="emitClickEvent('TEST_OK','STANDARD')">
            <app-well
              forced-sample-type="STANDARD"
              forced-diagnostic="TEST_OK"
              class="align-middle" /> <span class="align-middle">{{ $t('captionValidStandardWell') }}</span>
          </div>
          <div
            role="button"
            @click="emitClickEvent('TEST_KO','STANDARD')">
            <app-well
              forced-sample-type="STANDARD"
              forced-diagnostic="TEST_KO"
              class="align-middle" /> <span class="align-middle">{{ $t('captionBadStandardWell') }}</span>
          </div>
          <div
            role="button"
            @click="emitClickEvent('TEST_OK','NEGATIVE_TEST')">
            <app-well
              forced-sample-type="NEGATIVE_TEST"
              forced-diagnostic="TEST_OK"
              class="align-middle" /> <span class="align-middle">{{ $t('captionValidNegativeTestWell') }}</span>
          </div>
          <div
            role="button"
            @click="emitClickEvent('TEST_KO','NEGATIVE_TEST')">
            <app-well
              forced-sample-type="NEGATIVE_TEST"
              forced-diagnostic="TEST_KO"
              class="align-middle" /> <span class="align-middle">{{ $t('captionBadNegativeTestWell') }}</span>
          </div>
          <template v-for="abnormalCol in includes('ABNORMAL')">
            <div
              role="button"
              @click="emitClickEvent(abnormalCol)">
              <app-well
                :forced-diagnostic="abnormalCol"
                class="align-middle" /> <span class="align-middle">{{ $t('captionAbnormalWell') }}</span>
            </div>
          </template>
          <template v-for="negative in includes('NEGATIVE')">
            <div
              role="button"
              @click="emitClickEvent(negative)">
              <app-well
                :forced-diagnostic="negative"
                class="align-middle" /> <span class="align-middle">{{ $t('captionNegativeWell') }}</span>
            </div>
          </template>
          <app-well
            :analyzed="false"
            class="align-middle" /> <span class="align-middle">{{ $t('captionNonAnalysedWell') }}</span>
        </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
import Well from '@/components/run/editor/plate/Well';
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    'app-well': Well
  },
  data() {
    return {
      filteredDiagnosticColors: []
    };
  },
  computed: {
    ...mapFields('runImport', [
      'diagnosticColors'
    ])
  },
  beforeMount() {
    this.$store.dispatch('runImport/getRbAnalyserChannelCombinations')
      .then(diagnosticColors => {
        this.filteredDiagnosticColors = diagnosticColors.filter(dc => dc.diagnostic.name !== 'TEST_OK'
                                                                      && dc.diagnostic.name !== 'TEST_KO'
                                                                      && dc.diagnostic.name !== 'NEGATIVE'
                                                                      && dc.diagnostic.name !== 'ABNORMAL');
      });
  },
  methods: {
    includes(diagnostic) {
      return this.diagnosticColors.filter(dc => dc.diagnostic.name === diagnostic);
    },
    emitClickEvent(diagnosticColor, sampleType) {
      this.$emit('click', {
        diagnosticColor,
        sampleType
      });
    },
    diagnosticName(diagnosticColor) {
      if (diagnosticColor.diagnosticLabel) {
        return `${this.$t(diagnosticColor.diagnostic.name)} ${diagnosticColor.diagnosticLabel}`;
      }

      return this.$t(diagnosticColor.diagnostic.name);
    }
  }
};
</script>
