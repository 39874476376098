<template>
  <b-jumbotron
    class="pb-4 pt-4"
    border-variant="dark"
    bg-variant="transparent">
    <h3>{{ $t('runCommentTitle') }}</h3>
    <app-comment
      ref="appComment"
      :comments="comments"
      @input="nonCommittedComment = $event"
      @save="saveComment" />
  </b-jumbotron>
</template>

<script>
import Comment from '@/components/run/editor/comment/Comment';
import { mapActions, mapState } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';

export default {
  components: {
    'app-comment': Comment
  },
  mixins: [ NotificationMixin ],
  data() {
    return {
      nonCommittedComment: null
    };
  },
  computed: {
    ...mapState('runImport', {
      comments: state => state.run.comments
    })
  },
  methods: {
    ...mapActions('runImport', [
      'createRunComment'
    ]),
    emitCloseEvent() {
      this.$emit('close');
    },
    saveComment() {
      if (this.nonCommittedComment) {
        this.createRunComment(this.nonCommittedComment)
          .then(() => this.$refs.appComment.resetValueToDefault())
          .catch(error => this.showErrorNotification(this.$t('errorCommentCreation'), error));
      }
    }
  }
};
</script>
