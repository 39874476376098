import Phase from '@/models/Phase';

export default class Channel {
  constructor(id, name, phases, configured) {
    this.id = id;
    this.name = name;
    this.phases = phases;
    this.configured = configured;
  }

  static build({ id, name, phases, configured }) {
    return new Channel(id, name, phases);
  }

  static parse(value) {
    return value ? Channel.build({
      id: value.id,
      name: value.name,
      phases: value.phases?.map(phase => Phase.parse(phase)),
      configured: value.configured
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      name: this.name !== undefined && this.name !== '' ? this.name : null,
      phases: this.phases !== undefined && this.phases !== '' ? this.phases : null,
      configured: this.configured !== undefined && this.configured !== '' ? this.configured : null
    };
  }
}