export default class ConstantZoneInfo {
  constructor(id, name, label, minTemp, maxTemp) {
    this.id = id;
    this.name = name;
    this.label = label;
    this.minTemp = minTemp;
    this.maxTemp = maxTemp;
  }

  static build({ id, name, label, minTemp, maxTemp }) {
    return new ConstantZoneInfo(id, name, label, minTemp, maxTemp);
  }

  static parse(value) {
    return value ? ConstantZoneInfo.build({
                                            id: value.id,
                                            name: value.name,
                                            label: value.label,
                                            minTemp: value.minTemp,
                                            maxTemp: value.maxTemp
                                         }) : null;
  }
}