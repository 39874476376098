<template>
  <div
    class="lot"
    :class="getLotClass">
    <span class="align-middle">{{ lot.name }}</span>
    <b-badge
      v-if="lot.kitVersion"
      pill
      :variant="'success'"
      class="badge-outlined ml-1">
      {{ lot.kitVersion.version }}
    </b-badge>
  </div>
</template>

<script>
export default {
  props: {
    lot: {
      type: Object,
      default: undefined
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getLotClass() {
      const date = new Date();
      date.setHours(0,0,0,0);

      return {
        'lot-expired': this.lot.expirationDate < date,
        'focused': this.selected,
        'text-strike-through': !this.lot.active
      };
    }
  }
};
</script>