import Diagnostic from '@/models/Diagnostic';

export default class DiagnosticColor {
  constructor(id, diagnostic, diagnosticLabel, color) {
    this.id = id;
    this.diagnostic = diagnostic;
    this.diagnosticLabel = diagnosticLabel;
    this.color = color;
  }

  static build({ id, diagnostic, diagnosticLabel, color }) {
    return new DiagnosticColor(id, diagnostic, diagnosticLabel, color);
  }

  static parse(value) {
    return value ? new DiagnosticColor(
      value.id,
      Diagnostic.parse(value.diagnostic),
      value.diagnosticLabel,
      value.color
    ) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      diagnostic: this.diagnostic  !== undefined || this.diagnostic !== '' ? this.diagnostic : null,
      diagnosticLabel: this.diagnosticLabel  !== undefined || this.diagnosticLabel !== '' ? this.diagnosticLabel : null,
      color: this.color  !== undefined || this.color !== '' ? this.color : null
    };
  }
}