import { render, staticRenderFns } from "./CombinationConfiguration.vue?vue&type=template&id=0974889f&"
import script from "./CombinationConfiguration.vue?vue&type=script&lang=js&"
export * from "./CombinationConfiguration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports