<template>
  <editable-multiselect
    v-model="model"
    track-by="id"
    :options="options"
    :custom-label="extractNameAndVersion"
    :placeholder="$t('formSelectAnalyser')"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :class="stateClass"
    :disabled="disabled"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)">
    <template
      v-if="displayBadge"
      #singleLabel="props">
      <app-analyser
        :analyser="props.option"
        :name="extractNameAndVersion(props.option)"
        :is-analyser-configured="props.option.configured" />
    </template>
    <template
      v-if="displayBadge"
      #option="props">
      <app-analyser
        :analyser="props.option"
        :name="extractNameAndVersion(props.option) "
        :is-analyser-configured="props.option.configured" />
    </template>
  </editable-multiselect>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import { findAllAnalysers } from '../../service/GenefoxService';
import EditableMultiselect from './EditableMultiselect.vue';
import Analyser from './Analyser.vue';

export default {
  components: {
    'editable-multiselect': EditableMultiselect,
    'app-analyser': Analyser
  },
  mixins: [ DropdownMixin ],
  props: {
    value: {
      type: Object,
      default: undefined
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayBadge: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorNotificationTitle() {
      return this.$t('analyserDropdownLoadingErrorTitle');
    }
  },
  watch: {
    overridingOptions(newOptions) {
      if (this.displayBadge) {
        this.model = newOptions?.find(option => option?.id === this.model?.id);
      }
    }
  },
  methods: {
    findOptions() {
      return findAllAnalysers();
    },
    extractNameAndVersion(option) {
      return option.version ? `${option.name} - ${option.version}` : option.name;
    }
  }
};
</script>
