import get from 'lodash/get';

export default {
  methods: {
    touch(path) {
      const { $touch } = get(this.$v, path);
      $touch();
    },
    validateState(path) {
      const { $dirty, $error } = get(this.$v, path);

      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.$touch();

      return !this.$v.$invalid;
    },
    resetValidation() {
      this.$v.$reset();
    }
  }
};
