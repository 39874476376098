export default class ReactionBuffer {
  constructor(id, name, active, channels, configured) {
    this.id = id;
    this.name = name;
    this.active = active;
    this.channels = channels;
    this.configured = configured;
  }

  static build({ id, name, active, channels, configured }) {
    return new ReactionBuffer(id, name, active, channels, configured);
  }

  static parse(value) {
    return value ? ReactionBuffer.build({
      id: value.id,
      name: value.name,
      active: value.active,
      channels: value.channels,
      configured: value.configured
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      name: this.name !== undefined && this.name !== '' ? this.name : null,
      active: this.active !== undefined && this.active !== '' ? this.active : null,
      channels: this.channels !== undefined && this.channels !== '' ? this.channels : null,
      configured: this.configured !== undefined && this.configured !== '' ? this.configured : null
    };
  }
}