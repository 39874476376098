<template>
  <div>
    <h5>{{ $t('configuration.export.kit.title') }}</h5>
    <b-row align-h="center">
      <b-col
        sm="4"
        class="m-0 p-0">
        <app-kit-editable-multiselect
          v-model="kit"
        :overriding-options="kits"/>
      </b-col>
      <b-col
        sm="4"
        class="m-0 p-0">
        <app-kit-version-dropdown
          v-model="kitVersion"
          :overriding-options="kitVersions" />
      </b-col>
      <b-col
        sm="1"
        class="m-0 px-1">
        <b-button
          @click="exportKit"
          :disabled="disableExportKit">
          {{ $t('exportButton') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KitEditableMultiselect from '../../../common/KitEditableMultiselect.vue';
import {exportKitVersion, findExportableKit, findExportableKitVersions} from '../../../../service/GenefoxService';
import NotificationMixin from '../../../../mixins/NotificationMixin';
import KitVersionDropdown from '../../../common/KitVersionDropdown.vue';

export default {
  components: {
    'app-kit-editable-multiselect': KitEditableMultiselect,
    'app-kit-version-dropdown': KitVersionDropdown
  },
  mixins: [
    NotificationMixin
  ],
  data() {
    return {
      kit: undefined,
      kits: [],
      kitVersion: undefined,
      kitVersions: []
    };
  },
  beforeMount() {
    findExportableKit(false, false, true)
        .then(kits => this.kits = kits)
        .catch(error => this.showErrorNotification(this.$t('error'), error));
  },
  computed: {
    disableExportKit() {
      return !this.kit?.id || !this.kitVersion?.id;
    }
  },
  watch: {
    kit(newKit) {
      findExportableKitVersions(newKit.id, false, false, true)
          .then(kitVersions => {
            this.kitVersions = kitVersions
            if (kitVersions.length) {
              this.kitVersion = kitVersions[kitVersions.length - 1];
            }
          })
          .catch(error => this.showErrorNotification(this.$t('error'), error));
    }
  },
  methods: {
    exportKit() {
      if (!this.kitVersion?.id || !this.kit?.id) {
        this.showErrorNotification(this.$t('configuration.export.kit.notification.error.title'), this.$t('configuration.export.kit.notification.error.message'));

        return;
      }
      exportKitVersion(this.kit.id, this.kitVersion)
        .then(() => this.showSuccessNotification(this.$t('configuration.export.kit.notification.success.title'), this.$t('configuration.export.kit.notification.success.message')))
        .catch(error => this.showErrorNotification(this.$t('configuration.export.kit.notification.error.title'), error));
    }
  }
};
</script>
