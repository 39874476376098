<template>
  <div class="position-relative ml-2">
    <ul class="colors">
      <li
        v-for="(item, index) in colorsDefault"
        :key="item"
        :class="`item ${index < 9 ? 'first-row' : ''}`"
        @click="selectColor(item)"
      >
        <div :style="{ background: `url(${imgAlphaBase64})` }" class="alpha" />
        <div :style="{ background: item }" class="color" />
      </li>
    </ul>
    <ul v-if="colorsHistory && colorsHistory.length" class="colors history">
      <li
        v-for="item in colorsHistory"
        :key="item"
        class="item"
        @click="selectColor(item)"
      >
        <div :style="{ background: `url(${imgAlphaBase64})` }" class="alpha" />
        <div :style="{ background: item }" class="color" />
      </li>
    </ul>
  </div>
</template>

<script>
import {createAlphaSquare, setColorValue} from '@/components/common/color-picker/composible.ts'
export default {
  name: 'ColorPicker',
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    colorsDefault: {
      type: Array,
      default: () => [],
    },
    colorsHistoryKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imgAlphaBase64: undefined,
      colorsHistory: []
    }
  },
  methods: {
    initColors() {
      if (this.colorsHistoryKey && localStorage) {
        this.colorsHistory = JSON.parse(localStorage.getItem(this.colorsHistoryKey)) || [];
      }
      this.imgAlphaBase64 = createAlphaSquare(4).toDataURL();
    },
    setColorsHistory(color) {
      if (!color) {
        return;
      }
      const { r, g, b, a, h, s, v } = setColorValue(color);
      color = `rgba(${r}, ${g}, ${b}, ${a})`;
      if (!this.colorsHistory?.length) {
        this.colorsHistory = JSON.parse(localStorage.getItem(this.colorsHistoryKey)) || [];
      }
      const colors = this.colorsHistory;
      const index = colors.indexOf(color);
      if (index >= 0) {
        colors.splice(index, 1);
      }
      if (colors.length >= 18) {
        colors.length = 17;
      }
      colors.unshift(color);
      this.colorsHistory = colors || []
      if (localStorage && this.colorsHistoryKey) {
        localStorage.setItem(this.colorsHistoryKey, JSON.stringify(colors));
      }
    },
    selectColor(color) {
      this.$emit('selectColor', color);
    }
  }
}
</script>
<style lang="scss">
.colors {
  padding: 0;
  margin: 0;
  &.history {
    border-top: 1px solid #2e333a;
    position: absolute;
    bottom: 0;
    width: 100%;

    .item {
      margin: 8px 0 0 8px;
    }
  }
  .item {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 0 8px 8px;
    border-radius: 3px;
    box-sizing: border-box;
    vertical-align: top;
    display: inline-block;
    transition: all 0.1s;
    cursor: pointer;
    &:nth-child(9n + 1) {
      margin-left: 0;
    }
    &:hover {
      transform: scale(1.4);
    }
    .alpha {
      height: 100%;
      border-radius: 4px;
    }
    .color {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }
  }
}
</style>
