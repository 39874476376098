<template>
  <div>
    <h5>{{ $t('configuration.export.lot.title') }}</h5>
    <b-row align-h="center">
      <b-col
        sm="2"
        class="m-0 p-0">
        <app-kit-dropdown
          v-model="kit"
        :overriding-options="kits"/>
      </b-col>
      <b-col
        sm="2"
        class="m-0 p-0">
        <app-kit-version-dropdown
          v-model="kitVersion"
          :overriding-options="kitVersions" />
      </b-col>
      <b-col
        sm="4"
        class="m-0 p-0">
        <app-lot-editable-multiselect
          v-model="lots"
          :kit-version="kitVersion"
          :multiple="true"
          :fetch-all-lot="false" />
      </b-col>
      <b-col
        sm="1"
        class="m-0 px-1">
        <b-button
          :disabled="disableExportLot"
          @click="exportLots">
          {{ $t('exportButton') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LotEditableMultiselect from '../../../common/LotEditableMultiselect.vue';
import {exportLots, findExportableKit, findExportableKitVersions} from '../../../../service/GenefoxService';
import NotificationMixin from '../../../../mixins/NotificationMixin';
import KitVersionDropdown from '@/components/common/KitVersionDropdown.vue';
import KitEditableMultiselect from '@/components/common/KitEditableMultiselect.vue';

export default {
  components: {
    'app-kit-dropdown' : KitEditableMultiselect,
    'app-kit-version-dropdown' : KitVersionDropdown,
    'app-lot-editable-multiselect' : LotEditableMultiselect
  },
  mixins: [
    NotificationMixin
  ],
  data() {
    return {
      lots: [],
      kit: undefined,
      kits: [],
      kitVersion: undefined,
      kitVersions: []
    };
  },
  computed: {
    disableExportLot() {
      return !this.kit?.id || !this.kitVersion?.id || (!this.lots || this.lots.length < 1);
    }
  },
  mounted() {
    findExportableKit(true, true, false)
        .then(kits => this.kits = kits)
        .catch(error => this.showErrorNotification(this.$t('error'), error));
  },
  watch: {
    kit(newKit) {
      if(newKit) {
        findExportableKitVersions(newKit.id, true, true, false)
            .then(kitVersions => {
              this.kitVersions = kitVersions
              if (kitVersions.length) {
                this.kitVersion = kitVersions[kitVersions.length - 1];
              }
            })
            .catch(error => this.showErrorNotification(this.$t('error'), error));
      }
    }
  },
  methods: {
    exportLots() {
      if (!this.lots || this.lots.length < 1) {
        this.showErrorNotification(this.$t('configuration.export.lot.notification.error.title'), this.$t('configuration.export.lot.notification.error.message'));

        return;
      }
      exportLots(this.kitVersion, this.lots.map(lot => lot.id))
        .then(() => this.showSuccessNotification(this.$t('configuration.export.lot.notification.success.title'), this.$t('configuration.export.lot.notification.success.message')))
        .catch(error => this.showErrorNotification(this.$t('configuration.export.lot.notification.error.title'), error));
    }
  }
};
</script>
