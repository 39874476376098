export default class PublicProperties {
  constructor(genefox) {
    this.genefox = genefox;
  }

  static build({ genefox }) {
    return new PublicProperties(genefox);
  }

  static parse(value) {
    return value ? PublicProperties.build({
      genefox: value.genefox
    }) : null;
  }
}