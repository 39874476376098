export default class Expression {
  constructor(id, firstOperand, operator, secondOperand) {
    this.id = id;
    this.firstOperand = firstOperand;
    this.operator = operator;
    this.secondOperand = secondOperand;
  }

  static build({ id, firstOperand, operator, secondOperand }) {
    return new Expression(id, firstOperand, operator, secondOperand);
  }

  static parse(value) {
    return value ? new Expression(
      value.id,
      value.firstOperand,
      value.operator,
      value.secondOperand
    ) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      firstOperand: this.firstOperand  !== undefined || this.firstOperand !== '' ? this.firstOperand : null,
      operator: this.operator  !== undefined || this.operator !== '' ? this.operator : null,
      secondOperand: this.secondOperand  !== undefined || this.secondOperand !== '' ? this.secondOperand : null
    };
  }
}
