<template>
  <div>
    <b-form-input
      :placeholder="$t('user.search') | capitalize"
      class="form-control input-search"
      type="text"
      :value="search"
      @input="updateSearch" />
    <div class="d-flex">
      <div class="col-4 p-0 border-right">
        <b-table
          responsive
          striped
          hover
          show-empty
          selectable
          thead-class="users-table-header"
          :select-mode="'single'"
          :busy="isBusy"
          :fields="fields"
          :items="laboratoryUsers"
          :empty-filtered-text="$t('noDataFound')"
          :empty-text="$t('noDataFound')"
          :no-local-sorting="true"
          @row-selected="selectUser">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
              <strong>{{ $t('common.loading') }}</strong>
            </div>
          </template>
        </b-table>
        <b-button
          id="addUser"
          class="btn-sm list-add-button"
          variant="success"
          @click="addUser">
          <font-awesome-icon :icon="['fas', 'plus']" />
          {{ $t('common.add') }}
        </b-button>
        <b-pagination
          v-if="laboratoryUsers.length"
          v-model="currentPage"
          align="center"
          :total-rows="laboratoryUsersCount"
          :per-page="laboratoryUsersPerPage"
          @change="pageChange" />
      </div>
      <div class="col-8 p-4">
        <app-laboratory-user
          @updated="pageChange(currentPage)"
          @deleted="pageChange(currentPage)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import LaboratoryUser from '@/components/admin/laboratory/user/LaboratoryUser';

export default {
  name: 'LaboratoryUsers',
  components: {
    'app-laboratory-user': LaboratoryUser
  },
  data() {
    return {
      search: undefined,
      currentPage: 1,
      isBusy: false,
      timer: undefined
    };
  },
  computed: {
    ...mapFields('laboratory', [
      'laboratory',
      'laboratoryUsers',
      'laboratoryUsersCount',
      'laboratoryUsersPerPage',
      'laboratoryUser'
    ]),
    fields() {
      return [
        {
          key: 'login',
          label: this.$options.filters.capitalize(this.$t('user.login'))
        },
        {
          key: 'lastname',
          label: this.$options.filters.capitalize(this.$t('user.lastname'))
        },
        {
          key: 'firstname',
          label: this.$options.filters.capitalize(this.$t('user.firstname'))
        }
      ];
    }
  },
  watch: {
    laboratory() {
      this.search = undefined;
      this.currentPage = 1;
      if (this.timer) {
        clearTimeout(this.timer);
      }
    }
  },
  beforeMount() {
    // clean the selection before displaying the screen
    this.$store.dispatch('laboratory/selectLaboratoryUser', null)
      .catch(error => {
        this.$bvToast.toast(error.message, {
          title: this.$options.filters.capitalize(this.$t('error')),
          variant: 'danger',
          autoHideDelay: '3000',
          toaster: 'b-toaster-bottom-left'
        });
      });
  },
  methods: {
    updateSearch(search) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (search.length >= 1) {
        this.search = search;
      } else {
        this.search = undefined;
      }
      this.timer = setTimeout(() => this.pageChange(1), 1000);
    },
    pageChange(page) {
      if (page === 1) {
        this.setUsersPerPage();
      }
      this.isBusy = !this.isBusy;
      const search = this.search || '';
      this.$store.dispatch('laboratory/findLaboratoryUsers', { page, search })
        .then(() => {
          this.currentPage = page;
          this.isBusy = !this.isBusy;
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
          this.isBusy = !this.isBusy;
        });
    },
    selectUser(users) {
      this.$store.dispatch('laboratory/selectLaboratoryUser', users[0])
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
        });
    },
    addUser() {
      this.$store.dispatch('laboratory/selectLaboratoryUser', { enabled: true })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
        });
    },
    setUsersPerPage() {
      if (!this.$el.getElementsByClassName('users-table-header').length) {
        return;
      }
      const tableHeaderHeight = this.$el.getElementsByClassName('users-table-header')[0].clientHeight;
      const totalHeight = document.getElementsByClassName('content')[0].clientHeight - tableHeaderHeight - 38 - 151 - 15 - 38; // -38 because of paginator -151 laboratory header -15 hr -38 search input
      this.$store.dispatch('laboratory/setUsersPerPage', Math.floor(totalHeight / 56.5));
    }
  }
};
</script>