<template>
  <b-container>
    <b-row><b class="mr-1">{{ $t('wellMetaDataWellName') }}:</b>{{ wellName }}</b-row>
    <b-row><b class="mr-1">{{ $t('wellMetaDataKitRB') }}:</b>{{ kitAndReactionBufferNames }}</b-row>
    <b-row><b class="mr-1">{{ $t('wellMetaDataLot') }}:</b>{{ lotName }}</b-row>
    <b-row><b class="mr-1">{{ $t('wellMetaDataSampleType') }}:</b>{{ sampleTypeName }} {{ $t(wellExpectedDiagnostic) }}</b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import find from 'lodash/find';

export default {
  props: {
    well: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('runImport', [
      'wellChannelByWellId'
    ]),
    wellName() {
      return this.well?.name;
    },
    kitAndReactionBufferNames() {
      const kitName = this.well?.kitVersion?.kit?.name ? this.well.kitVersion.kit.name : '';
      const channelName = this.channelName ? ` (${this.channelName})` : '';
      const reactionBufferName = this.well?.reactionBuffer?.name ? this.well?.reactionBuffer?.name : '';

      return `${kitName} - ${reactionBufferName}${channelName}`;
    },
    lotName() {
      return this.well?.lot?.name;
    },
    wellExpectedDiagnostic() {
      if (this.well?.expectedDiagnostic?.diagnosticLabel) {
        return this.$t(this.well?.expectedDiagnostic.diagnostic.name) + ' ' + this.well?.expectedDiagnostic.diagnosticLabel;
      }
      return this.$t(this.well?.expectedDiagnostic?.diagnostic.name);
    },
    channelName() {
      let name;

      if (this.well?.kitVersion?.kit?.channelAssignments?.length > 1) {
        name = find(this.well.kitVersion.kit.channelAssignments, { 'channel': this.wellChannelByWellId(this.well.id) })?.label;
      }

      return name;
    },
    sampleTypeName() {
      return this.well?.sampleType ? this.$options.filters.capitalize(this.$t(this.well?.sampleType)) : '';
    }
  }
};
</script>
