<template>
  <div>
    <div class="text-lg-center">
      <h5>
        {{ $t('configuration.kit.combinations') }}
      </h5>
    </div>
    <div
      class="combination-grid"
      :style="gridOverlay() ? 'overflow-x: overlay; padding-bottom: 30px;' : ''">
      <legend
        v-if="combinations.length"
        tabindex="-1"
        class="bv-no-focus-ring col-form-label pt-0"
        :style="`grid-column: 1/2; grid-row: 1/2;`">
        {{ $t('configuration.kit.combination') }}
      </legend>
      <legend
        v-if="combinations.length"
        tabindex="-1"
        class="bv-no-focus-ring col-form-label pt-0 color-label"
        :style="`grid-column: 2/3; grid-row: 1/2; width: 30px;`">
        {{ $t('configuration.kit.color') }}
      </legend>
      <legend
        v-if="combinations.length"
        tabindex="-1"
        class="bv-no-focus-ring col-form-label pt-0"
        :style="`grid-column: 3/4; grid-row: 1/2; width: 300px;`">
        {{ $t('configuration.kit.diagnostic') }}
      </legend>
      <legend
        v-if="combinations.length"
        tabindex="-1"
        class="bv-no-focus-ring col-form-label pt-0"
        :style="`grid-column: 4/5; grid-row: 1/2; width: 300px;`">
        {{ $t('configuration.kit.mutation') }}
      </legend>
      <legend
        v-if="combinations.length"
        tabindex="-1"
        class="bv-no-focus-ring col-form-label pt-0"
        :style="`grid-column: 5/6; grid-row: 1/2;`">
        {{ $t('configuration.kit.conditions') }}
      </legend>

      <div
        v-for="(combination, index) in combinations"
        :key="combination.uuid"
        :style="`display: contents; grid-row: ${index+2}/${index+3};`">
        <div
          class="combination-name"
          :style="`grid-column: 1/2;`">
          <div class="col-1 p-0 button-div">
            <b-button
              v-if="displayRemoveCombinationButton(combination) && isVersionModifiable"
              ref="removeButton"
              variant="outline"
              class="btn--no-outline btn-icon btn-icon-minus p-0"
              @click="removeCombination(index)">
              <font-awesome-icon
                :icon="['fas', 'minus-circle']" />
            </b-button>
            <b-button
              v-if="displayAddCombinationButton(combination, index)  && isVersionModifiable"
              ref="addButton"
              variant="outline"
              class="btn--no-outline btn-icon btn-icon-plus p-0 float-right"
              @click="addCombination(index+1, combination.combination.label)">
              <font-awesome-icon
                :icon="['fas', 'plus-circle']" />
            </b-button>
          </div>
          <h5 class="col-11">
            {{ combination.combination.label }}
          </h5>
        </div>
        <div :style="`grid-column: 2/3; width: 30px;`">
          <span
            :id="`cpSpan${index}`"
            :ref="`cpSpan`"
            :class="`color-picker-container`"
            :hidden="true"
            @mouseover="mouseOverColorPicker(index)"
            @mouseleave="mouseLeaveColorPicker(index)">
            <app-color-picker
              :id="`colorPicker${index}`"
              :ref="`colorPicker`"
              :theme="'light'"
              :color="combination.diagnosticColor.color"
              @changeColor="changeColor(combination, $event)" />
          </span>
          <div
            class="well-sample ml-1"
            :style="`background: ${combination.diagnosticColor.color}; ${combination.diagnosticColor.color === 'transparent' ? 'box-shadow: inset 0 0 0 2px black;' : ''}`"
            @mouseover="showColorPickerSpan(index)"
            @mouseleave="showColorPickerSpan(index)" />
        </div>
        <div :style="`grid-column: 3/4;`">
          <app-string-array-dropdown
            v-model="combination.diagnosticColor.diagnostic.name"
            :translate="true"
            :open-direction="diagnosticDirection(index)"
            :overriding-options="DiagnosticEnum"
            :placeholder="$t('formSelectDiagnostic')"
            :allow-empty="true"
            :show-labels="false"
            :disabled="disable() || !isVersionModifiable || disabled" />
        </div>
        <div :style="`grid-column: 4/5;`">
          <b-form-input
            v-model="combination.diagnosticColor.diagnosticLabel"
            type="text"
            :disabled="disable() || !isVersionModifiable || disabled" />
        </div>
        <div
          v-for="(condition, indexCondition) in combination.expressions"
          :key="condition.uuid"
          :style="`grid-column: ${5+indexCondition}/${6+indexCondition}; display: flex;`">
          <div>
            <app-string-array-dropdown
              v-model="condition.value"
              :field="'name'"
              :overriding-options="getFilteredExpressions(combination, condition.value.name)"
              :style="`width: 165px;`"
              :placeholder="$t('formSelectCondition')"
              :allow-empty="true"
              :show-labels="false"
              :disabled="disable() || !isVersionModifiable || disabled" />
          </div>
          <div>
            <b-button
              v-if="(combination.expressions.length > 0 && !disable()) && isVersionModifiable"
              ref="removeButton"
              variant="outline"
              class="btn--no-outline btn-icon btn-icon-minus"
              @click="removeCondition(combination, condition.uuid)">
              <font-awesome-icon
                :icon="['fas', 'minus-circle']" />
            </b-button>
          </div>
        </div>
        <b-button
          v-if="displayAddConditionButton(combination) && isVersionModifiable"
          ref="addButton"
          :style="`grid-column: ${5+combination.expressions.length}/${6+combination.expressions.length}; width: 30px;`"
          variant="outline"
          class="btn--no-outline btn-icon btn-icon-plus"
          @click="addCondition(combination)">
          <font-awesome-icon
            :icon="['fas', 'plus-circle']" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import StringArrayDropdown from '@/components/common/StringArrayDropdown';
import {v4 as uuidv4} from 'uuid';
import {mapFields} from 'vuex-map-fields';
import {getKitAvailableExpressions, getKitCombinations, getKitCombinationsLastVersion} from '@/service/GenefoxService';
import cloneDeep from 'lodash/cloneDeep';
import {OperatorEnum} from '@/enums/Operator.enum';
import ColorPicker from '@/components/common/color-picker/ColorPicker';
import {DiagnosticEnum} from '@/enums/Diagnostic.enum';

export default {
  components: {
    'app-string-array-dropdown': StringArrayDropdown,
    'app-color-picker': ColorPicker
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapFields('configuration', [
      'kitVersion',
      'reactionBuffer',
      'analyser',
      'channel',
      'phases',
      'isKitVersionModifiable'
    ]),
    isVersionModifiable() {
      return this.kitVersion?.kit?.id ? this.kitVersion?.id && this.kitVersion.isEditable ? this.isKitVersionModifiable : false : true;
    }
  },
  data() {
    return {
      combinations: [],
      currentCombinations: [],
      expressions: [],
      DiagnosticEnum
    };
  },
  watch: {
    kitVersion (newKitVersion) {
      this.findCombinations(newKitVersion?.kit, newKitVersion?.id, this.reactionBuffer, this.analyser?.id, this.channel);
    },
    channel (newChannel, oldChannel) { // No needs to watch reaction buffer, because when changing reaction buffer a new channel is selected
      if (oldChannel?.id === newChannel?.id) { // When saving, new phases are added to channel and find combination is called, but shouldn't
        return;
      }
      this.findCombinations(this.kitVersion?.kit, this.kitVersion?.id, this.reactionBuffer, this.analyser?.id, newChannel);
    },
    analyser (newAnalyser) {
      this.findCombinations(this.kitVersion?.kit, this.kitVersion?.id, this.reactionBuffer, newAnalyser?.id, this.channel);
    }
  },
  methods: {
    resetData() {
      this.currentCombinations = [];
    },
    initCombinations(zoneNames) {
      if (!this.kitVersion || (this.kitVersion.isFirst && this.kitVersion.isEditable)) {
        const newCombinations = [];
        if (zoneNames.length === 0 || zoneNames[0] === null || zoneNames[0] === undefined) {
          this.combinations = [];

          return;
        }
        for (let index = 0; index < zoneNames.length; index++) {
          const lengthBeforeFilter = this.combinations.length;
          // Remove elements found for next filters, improve performance
          this.combinations = this.combinations.filter(c => {
            if (c.combination.label === zoneNames[index]) {
              newCombinations.push(c);

              return false;
            }

            return true;
          });
          // If length are the same, we didn't find an element for this combination. Add a new one to the new array
          if (this.combinations.length === lengthBeforeFilter) {
            newCombinations.push({
              uuid: uuidv4(),
              id: null,
              combination: { label: zoneNames[index] },
              diagnosticColor: { diagnostic: {}, color: 'transparent' },
              numberOfZones: 1,
              expressions: [],
              channel: this.channel
            });
          }
          this.computeCombinations(newCombinations, zoneNames[index], zoneNames.slice(index + 1));
        }
        const none = this.combinations.find(c => c.combination.label === 'NONE');
        this.combinations = newCombinations;
        if (this.combinations.length > 1) {
          this.combinations.sort((c1, c2) => (c1.combination.label.length < c2.combination.label.length) ? -1 :
            (c1.combination.label.length > c2.combination.label.length) ? 1 :
              (c1.combination.label.charCodeAt(0) < c2.combination.label.charCodeAt(0)) ? -1 :
                (c1.combination.label.charCodeAt(0) > c2.combination.label.charCodeAt(0)) ? 1 : 0);
        }
        this.combinations.splice(0, 0, none || {
          uuid: uuidv4(),
          combination: { label: 'NONE' },
          diagnosticColor: { diagnostic: {}, color: 'transparent' },
          numberOfZones: 0,
          expressions: [],
          channel: this.channel
        });
      }
      else {
        this.findCombinations(this.kitVersion?.kit, this.kitVersion?.id, this.reactionBuffer, this.analyser?.id, this.channel);
      }
    },
    computeCombinations(newCombinations, combination, zoneNames) {
      for (let index = 0; index < zoneNames.length; index++) {
        const newCombination = `${combination} + ${zoneNames[index]}`;
        const lengthBeforeFilter = this.combinations.length;
        // Remove elements found for next filters, improve performance
        this.combinations = this.combinations.filter(c => {
          if (c.combination.label === newCombination) {
            newCombinations.push(c);

            return false;
          }

          return true;
        });
        if (this.combinations.length === lengthBeforeFilter) {
          newCombinations.push({ uuid: uuidv4(), id: null, combination: { label: newCombination }, diagnosticColor: { diagnostic: {}, color: 'transparent' }, numberOfZones: newCombination.split('+').length, expressions: [], channel: this.channel });
        }
        this.computeCombinations(newCombinations, newCombination, zoneNames.slice(index + 1));
      }
    },
    findCombinations(kit, kitVersionId, reactionBuffer, analyserId, channel) {
      if (!kit?.id || !reactionBuffer?.id || !analyserId || !channel?.id || !kit.reactionBuffers?.find(rb => rb.id === reactionBuffer.id)
          || (kitVersionId && !channel.phases?.find(p => p.kitVersion.id === kitVersionId))) {
        this.combinations = [];

        return;
      }


      return new Promise((resolve, reject) => {
        let promise;
        if (kitVersionId) {
          promise = getKitCombinations(kitVersionId, reactionBuffer.id, analyserId, channel.id);
        } else {
          promise = getKitCombinationsLastVersion(kit.id, reactionBuffer.id, analyserId, channel.id);
        }
        promise
          .then(combinations => {
            this.combinations = [];
            combinations.forEach(c => {
              const expressions = [];
              c.expressions.map(e => expressions.push({ uuid: uuidv4(), value: { name: `${e.firstOperand} ${OperatorEnum[e.operator]} ${e.secondOperand}`, id: e.id, firstOperand: e.firstOperand, operator: e.operator, secondOperand: e.secondOperand } }));
              this.combinations.push({ uuid: uuidv4(), id: c.id, combination: c.combination, diagnosticColor: c.diagnosticColor, numberOfZones: c.combination.label.split('+').length, expressions, channel });
            });
            this.currentCombinations = cloneDeep(this.combinations);
            resolve(combinations);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    findExpressions() {
      if (!this.kitVersion) {
        return;
      }
      const zoneNames = this.phases.map(p => p.zones.map(z => z.name).filter(n => n !== undefined && n !== null)).filter(list => list?.length);

      return new Promise((resolve, reject) => {
        getKitAvailableExpressions(this.kitVersion.kit.id, zoneNames)
          .then(expressions => {
            this.expressions = [];
            expressions.forEach(e => this.expressions.push({ name: `${e.firstOperand} ${OperatorEnum[e.operator]} ${e.secondOperand}`, id: e.id, firstOperand: e.firstOperand, operator: e.operator, secondOperand: e.secondOperand }));
            resolve(expressions);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    disable() {
      return !this.kitVersion?.isEditable;
    },
    removeCombination(index) {
      this.combinations.splice(index, 1);
    },
    addCombination(index, name) {
      this.combinations.splice(index, 0, { uuid: uuidv4(), id: null, combination: { label: name }, diagnosticColor: { diagnostic: {}, color: 'transparent' }, numberOfZones: name.split('+').length, expressions: [], channel: this.channel });
    },
    getCombinations() {
      const combinations = cloneDeep(this.combinations);
      combinations.forEach(c => c.expressions = c.expressions.map(e => e.value));

      return combinations;
    },
    displayAddCombinationButton(combination, index) {
      if (this.disable() || combination.numberOfZones < 2) {
        return false;
      }
      const zones = combination.combination.label.split(/[^A-Z]+/);
      const filteredExpressions = this.expressions.filter(e => this.twoMatches(e.name, zones));
      const numberOfCombinations = this.combinations.filter(c => c.combination.label === combination.combination.label).length;
      const higherIndex = this.combinations.findIndex((c, i) => c.combination.label === combination.combination.label && i > index) !== -1;

      // One condition and it's opposite for each combination
      return filteredExpressions?.length
             && !higherIndex // To show only on the last line
             && numberOfCombinations < 2 ** (filteredExpressions.length / Object.keys(OperatorEnum).length);
    },
    displayRemoveCombinationButton(combination) {
      if (this.disable() || combination.numberOfZones < 2) {
        return false;
      }
      let counter = 0;
      this.combinations.forEach(c => {
        if (c.combination.label === combination.combination.label) {
          counter++;
        }
      });

      return counter > 1 ;
    },
    removeCondition(combination, uuid) {
      const index = combination.expressions.findIndex(row => row.uuid === uuid);
      combination.expressions.splice(index, 1);
    },
    addCondition(combination) {
      combination.expressions.push({ uuid: uuidv4(), value: { name: null, id: null, firstOperand: null, operator: null, secondOperand: null } });
    },
    displayAddConditionButton(combination) {
      if (this.disable() || combination.numberOfZones < 2) {
        return false;
      }
      const zones = combination.combination.label.split(/[^A-Z]+/);
      const filteredExpressions = this.expressions.filter(e => this.twoMatches(e.name, zones));

      // One expression exists for each operator per pair of zone, but only one expression can be selected per pair of zone
      return filteredExpressions?.length > combination.expressions?.length * Object.keys(OperatorEnum).length;
    },
    getFilteredExpressions(combination, currentCondition) {
      const conditionNames = combination.expressions.map(c => c.value.name).filter(c => c !== null);
      const zones = combination.combination.label.split(/[^A-Z]+/);
      const currentZones = currentCondition?.split(/[^A-Z]+/);

      return this.expressions.filter(e => (!this.oppositeCondition(conditionNames, e.name) || this.twoMatches(e.name, currentZones))
                                          && this.twoMatches(e.name, zones));
    },
    twoMatches(expression, zones) { // return true if expression matches with two zones of the list
      if (!zones?.length) {
        return false;
      }
      let counter = 0;
      zones.find(z => {
        if (expression.includes(z)) {
          counter++;
        }
      });

      return counter > 1;
    },
    oppositeCondition(conditionNames, expression) { // return true if expression is an opposite condition of one contained in conditionNames. Ex : 'A > B' & 'A < B'
      if (!conditionNames?.length) {
        return false;
      }

      return conditionNames.find(c => this.twoMatches(expression, c.split(/[^A-Z]+/))) !== undefined;
    },
    changeColor(combination, color) {
      if (color.rgba.a === 0) {
        combination.diagnosticColor.color = 'transparent';
      } else {
        combination.diagnosticColor.color = color.hex;
      }
    },
    showColorPickerSpan(index) {
      if (this.disable() || !this.isVersionModifiable) {
        return;
      }
      const cpSpan = this.$refs.cpSpan.find(span => span.id === `cpSpan${index}`);
      for (let i = 0; i < this.combinations.length; i++) {
        if (this.$refs.cpSpan[i].id !== `cpSpan${index}` && !this.$refs.cpSpan[i].hidden) {
          this.$refs.cpSpan[i].hidden = true;
        }
      }
      cpSpan.hidden = !cpSpan.hidden;
      if (!cpSpan.hidden) {
        const cp = this.$refs.colorPicker.find(colorPicker => colorPicker.$el.id === `colorPicker${index}`);
        cp.initColorPicker();
      }
    },
    mouseOverColorPicker(index) {
      if (this.disable()) {
        return;
      }
      const cpSpan = this.$refs.cpSpan.find(span => span.id === `cpSpan${index}`);
      cpSpan.hidden = false;
    },
    mouseLeaveColorPicker(index) {
      if (this.disable()) {
        return;
      }
      const cpSpan = this.$refs.cpSpan.find(span => span.id === `cpSpan${index}`);
      cpSpan.hidden = true;
    },
    gridOverlay() {
      return this.expressions.length / Object.keys(OperatorEnum).length > 5; // Overlay is needed if we can add at least 5 expressions
    },
    diagnosticDirection(index) {
      if (!this.gridOverlay()) {
        return undefined;
      }

      return this.combinations.length - index < 7 ? 'top' : 'bottom';
    }
  }
};
</script>
