<template>
  <editable-multiselect
    ref="multiselect"
    v-model="model"
    :options="options"
    :multiple="multiple"
    label="name"
    :custom-label="customLabel"
    track-by="id"
    :show-labels="showLabels"
    :placeholder="$t('formSelectLot')"
    :class="stateClass"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)">
    <template
      #singleLabel="props">
      <div>
        <app-lot
          :lot="props.option"
          class="align-middle" />
      </div>
    </template>
    <template
      #option="props">
      <div>
        <app-lot
          :lot="props.option"
          class="align-middle" />
      </div>
    </template>
    <template
      v-if="canAdd && canWriteKitConfiguration"
      #afterList>
      <div>
        <b-input
          v-model="lotName"
          class="col-lg-10 dropdown-add-input"
          @mousedown.self.stop />
        <b-button
          ref="addButton"
          variant="outline"
          class="btn--no-outline btn-icon btn-icon-plus dropdown-add-button"
          :disabled="!lotName"
          @click="addLot">
          <font-awesome-icon
            :icon="['fas', 'plus-circle']" />
        </b-button>
      </div>
    </template>
  </editable-multiselect>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import {findAllLots, findLotsByVersion, getLotsValidByVersionId} from '@/service/GenefoxService';
import Lot from '../../components/run/editor/configuration/steps/run-meta-data/Lot';
import EditableMultiselect from '@/components/common/EditableMultiselect';
import SecurityMixin from '@/mixins/SecurityMixin';
import KitVersion from "@/models/KitVersion";
import isEqual from "lodash/isEqual";

export default {
  components: {
    'app-lot': Lot,
    'editable-multiselect': EditableMultiselect
  },
  mixins: [
    DropdownMixin,
    SecurityMixin
  ],
  props: {
    value: {
      type: Array | Object,
      default: undefined
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    kitVersion: {
      type: KitVersion,
      default: null
    },
    fetchAllLot: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lotName: null
    };
  },
  watch: {
    kitVersion (newKitVersion, oldKitVersion) {
      // Set initial model value
      this.model = undefined;
      if(newKitVersion) {
        getLotsValidByVersionId(this.kitVersion.id, {}).then(lots => {
          this.loadedOptions = lots
        });
        if (this.preselectSingleOverridingOption && !isEqual(newKitVersion, oldKitVersion) && !this.model) {
          this.model = newKitVersion.lots && newKitVersion.lots.length === 1 ? newKitVersion[0] : null;
        }
      } else {
        this.loadedOptions = [];
      }
    }
  },
  computed: {
    canWriteKitConfiguration() {
      return this.hasRole('WRITE_KIT_CONFIG_ADMIN');
    },
    errorNotificationTitle() {
      return this.$t('lotDropdownLoadingErrorTitle');
    }
  },
  methods: {
    findOptions() {
      if(this.kitVersion) {
        return findLotsByVersion(this.kitVersion.id, {}).then(response => response.content);
      }
      if(this.fetchAllLot) {
        return findAllLots();
      }
      return new Promise((resolve) => resolve([]));
    },
    customLabel(value) {
      return value.id ? value.name : this.$t(value.name);
    },
    addLot() {
      this.$emit('addLot', this.lotName);
      this.lotName = null;
    }
  }
};
</script>