export default class KitReactionBufferPair {
  constructor(kitVersion, reactionBuffer) {
    this.kitVersion = kitVersion;
    this.reactionBuffer = reactionBuffer;
  }

  static build({ kitVersion, reactionBuffer }) {
    return new KitReactionBufferPair(kitVersion, reactionBuffer);
  }

  static parse(value) {
    return value ? KitReactionBufferPair.build({
      kitVersion: value.kitVersion,
      reactionBuffer: value.reactionBuffer
    }) : null;
  }
}
