<template>
  <div>
    <app-information-header
      :business-id="businessId"
      :instrument-name="instrumentName"
      :plate-id="plateId"
      :created-by-name="createdByName"
      :submitted-by-name="submittedByName"
      :interpretation-run-by-name="interpretationRunByName"
      :file-name="fileName"
      :approved-by="approvedBy"
      :rejected-by="rejectedBy"
      :ftp-sent="ftpSent" />
    <div
      v-if="!chartDataLoaded"
      class="text-center">
      <h4>{{ $t('interpretationInProgress') }}</h4>
      <b-spinner
        type="grow"
        small />
      <b-spinner
        type="grow"
        small />
      <b-spinner
        type="grow"
        small />
    </div>
    <b-container
      v-else
      class="run-interpretation-content">
      <app-run-status-update-modal
        ref="updateStatusModal"
        :title="modalTitle"
        :message="modalMessage"
        @ok="updateStatus" />
      <b-form-row>
        <b-col sm="6">
          <b-container class="px-0">
            <b-form-group>
              <h3>{{ $t('wellInterpretationConfigurationTitle') }}</h3>
            </b-form-group>
            <b-form-group class="overflow-auto">
              <transition
                name="fade"
                mode="out-in">
                <app-plate
                  v-if="selectedAnalysis"
                  :key="selectedAnalysis.channel"
                  ref="plate"
                  :wells="selectedAnalysis.wells"
                  :initial-selection-by-position="selectedWells"
                  :plate-type="plateType"
                  @select="handleWellSelection" />
                <div v-else>
                  <p class="text-center">
                    <span>{{ $t('runInterpretationNoInterpretationResultMessage1') }}</span>
                    <br>
                    <span>{{ $t('runInterpretationNoInterpretationResultMessage2') }}</span>
                  </p>
                </div>
              </transition>
            </b-form-group>
            <b-form-group v-if="hasMultipleChannel">
              <div class="d-flex justify-content-center">
                <b-form-radio-group
                  v-model="selectedAnalysis"
                  :options="interpretedAnalysisRadioGroupOptions"
                  buttons
                  button-variant="outline-secondary"
                  class="analyses-radio-group"
                  @change="handleChannelSelection" />
              </div>
            </b-form-group>
            <app-plate-caption-result @click="selectWellsByDiagnosticAndSampleType" />
            <b-button
              ref="showExportWindowButton"
              variant="outline-secondary"
              @click="showExportWindow">
              {{ $t('showExportWindowButton') }}
            </b-button>
            <template v-if="displayFtpButton">
              <b-button
                v-if="!isSendingFTP"
                ref="sendFtpButton"
                variant="outline-secondary"
                @click="sendFileFtp">
                {{ $t('runInterpretation.ftp.runInterpretationSendFileFtp') }}
              </b-button>
              <b-button
                v-if="isSendingFTP"
                class="mr-2"
                disabled>
                <b-spinner
                  small
                  type="grow" />
                Loading...
              </b-button>
            </template>
          </b-container>
        </b-col>
        <b-col sm="6">
          <transition
            appear
            name="slide-fade"
            mode="out-in">
            <b-container
              v-if="isExportWindowVisible"
              key="well-export"
              class="pr-0">
              <app-well-export
                :selected-wells="selectedWells"
                @close="hideExportWindow" />
            </b-container>
            <b-container
              v-if="selectedWells && !isExportWindowVisible"
              key="well-interpretation"
              class="pr-0">
              <app-well-interpretation
                :selected-wells="selectedWells"
                @close="selectedWells = undefined" />
            </b-container>
            <b-container
              v-if="!selectedWells && !isExportWindowVisible"
              key="run-comment"
              class="pr-0">
              <app-run-comment />
            </b-container>
          </transition>
        </b-col>
      </b-form-row>
    </b-container>
    <b-form-row class="mx-0">
      <b-container
        v-if="chartDataLoaded"
        class="d-flex bd-highlight mb-3">
        <app-cancel-run class="mr-auto p-2 bd-highlight" />
        <transition name="fade">
          <div v-if="chartDataLoaded">
            <b-button
              v-if="isSubmitButtonDisplayed"
              ref="submitResultsButton"
              class="p-2 bd-highlight mr-2"
              @click="submitResult">
              {{ $t('submitResults') | capitalizeAll }}
            </b-button>
            <template v-if="areApproveAndRejectButtonsDisplayed">
              <b-button
                v-if="isDoctor"
                ref="rejectResultsButton"
                class="p-2 bd-highlight mr-2"
                @click="rejectResult">
                {{ $t('rejectResults') | capitalizeAll }}
              </b-button>
              <b-button
                v-if="isDoctor"
                ref="approveResultsButton"
                class="p-2 bd-highlight mr-2"
                @click="approveResult">
                {{ $t('approveResults') | capitalizeAll }}
              </b-button>
            </template>
            <template v-else>
              <app-delete-button
                ref="deleteRunButton"
                :can-delete="canDelete"
                :run-id="id"
                @runDelete="routeToRunList" />
            </template>
          </div>
        </transition>
      </b-container>
    </b-form-row>
  </div>
</template>

<script>
import RunInformationHeader from '../RunInformationHeader';
import { mapFields } from 'vuex-map-fields';
import Plate from '../plate/Plate';
import PlateCaptionResult from '../plate/PlateCaptionResult';
import WellInterpretation from './well/WellInterpretation';
import CancelRun from '../CancelRun';
import RunStatusUpdateModal from './RunStatusUpdateModal';
import NotificationMixin from '@/mixins/NotificationMixin';
import SecurityMixin from '@/mixins/SecurityMixin';
import WellExport from '@/components/run/editor/interpretation/well/WellExport';
import RunComment from '@/components/run/editor/interpretation/RunComment';
import { mapGetters, mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import DeleteButton from '../../../common/DeleteButton.vue';
import { isConfigurationValidAndSendEnable, sendFileFtp } from '../../../../service/GenefoxService';

export default {
  name: 'RunInterpretation',
  components: {
    'app-information-header': RunInformationHeader,
    'app-plate': Plate,
    'app-plate-caption-result': PlateCaptionResult,
    'app-well-interpretation': WellInterpretation,
    'app-well-export': WellExport,
    'app-run-comment': RunComment,
    'app-cancel-run': CancelRun,
    'app-run-status-update-modal': RunStatusUpdateModal,
    'app-delete-button': DeleteButton
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  data() {
    return {
      selectedWells: undefined,
      newRunStatus: undefined,
      modalTitle: undefined,
      modalMessage: undefined,
      chartDataLoaded: false,
      isExportWindowVisible: false,
      isFtpConfigured: false,
      isSendingFTP: false
    };
  },
  computed: {
    ...mapState('runImport', {
      analyses: state => sortBy(state.run.analyses, 'channel'),
      plateType: state => state.run.plateType
    }),
    ...mapState('runList', ['currentSearch']),
    ...mapFields('runImport', [
      'run',
      'run.id',
      'run.instrumentName',
      'run.plateId',
      'run.createdByName',
      'run.submittedByName',
      'run.interpretationRunByName',
      'run.status',
      'run.fileName',
      'run.businessId',
      'run.approvedBy',
      'run.rejectedBy',
      'run.ftpSent',
      'selectedAnalysis',
    ]),
    ...mapGetters('chart', [
      'existsAnalyzedWellForWellIds'
    ]),
    isSubmitButtonDisplayed() {
      return this.status === 'RUN_TO_INTERPRET';
    },
    areApproveAndRejectButtonsDisplayed() {
      return this.status === 'WAIT_FOR_APPROVAL';
    },
    hasMultipleChannel() {
      return this.interpretedAnalyses.length > 1;
    },
    interpretedAnalyses() {
      return this.analyses.filter(analysis => this.existsAnalyzedWellForWellIds([ ...analysis.wells.values() ].map(well => well.id)));
    },
    interpretedAnalysisRadioGroupOptions() {
      return this.interpretedAnalyses.map((interpretedAnalysis, index) => ({
        text: `${this.$t('runInterpretationChannel')} ${index + 1}`, value: interpretedAnalysis
      }));
    },
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    },
    canDelete() {
      return this.canDeleteRun();
    },
    displayFtpButton() {
      return this.ftpSent !== true && this.isFtpConfigured && this.isDoctor && this.status === 'APPROVED';
    }
  },
  beforeMount() {
    this.$store.dispatch('chart/findRunCharts', this.id)
      .then(() => this.$store.dispatch('runImport/findRun', this.id))
      .then(() => {
        this.selectedAnalysis = this.interpretedAnalyses.length ? this.interpretedAnalyses[0] : undefined;
        this.chartDataLoaded = true;
        this.handleCurrentSearchAutoSelect();
      })
      .catch(error => {
        this.chartDataLoaded = true;
        this.showErrorNotification(this.$t('error'), error, { delay: 10000 });
      });
    if (this.isDoctor) {
      isConfigurationValidAndSendEnable().then(isValid => this.isFtpConfigured = isValid);
    }
  },
  methods: {
    handleCurrentSearchAutoSelect() {
      const currentSearch = this.currentSearch;
      if (currentSearch) {
        const wells = [...this.selectedAnalysis.wells];
        const matchingWells = wells
          .map(([key, well]) => well)
          .filter(well => well.name?.toLowerCase().includes(currentSearch.toLowerCase()));
        this.handleWellSelection(matchingWells);
      }
    },
    handleChannelSelection() {
      if (!this.selectedWells?.length || !this.selectedAnalysis) {
        this.selectedWells = undefined;

        return;
      }

      const wells = [];
      this.selectedWells.forEach(w => wells.push(this.selectedAnalysis.wells.get(w.position)));
      this.selectedWells = wells;
    },
    handleWellSelection(wells) {
      this.selectedWells = wells && wells.length ? wells : undefined;
    },
    routeToRunList() {
      this.$router.push({ name: 'run-list' });
    },
    submitResult() {
      this.newRunStatus = 'WAIT_FOR_APPROVAL';
      this.modalTitle = this.$t('modalSubmitResultsTitle');
      this.modalMessage = this.$t('modalSubmitResultsMessage');
      this.$refs.updateStatusModal.showModal();
    },
    approveResult() {
      this.newRunStatus = 'APPROVED';
      this.modalTitle = this.$options.filters.capitalizeAll(this.$t('approveResults'));
      this.modalMessage = this.$t('modalApproveResultsMessage');
      this.$refs.updateStatusModal.showModal();
    },
    rejectResult() {
      this.newRunStatus = 'REJECTED';
      this.modalTitle = this.$options.filters.capitalizeAll(this.$t('rejectResults'));
      this.modalMessage = this.$t('modalRejectResultsMessage');
      this.$refs.updateStatusModal.showModal();
    },
    showExportWindow() {
      this.isExportWindowVisible = true;
    },
    hideExportWindow() {
      this.isExportWindowVisible = false;
    },
    selectWellsByDiagnosticAndSampleType({ diagnosticColor, sampleType }) {
      if (!sampleType) {
        this.$refs.plate.selectWellsByDiagnostic(diagnosticColor);
      } else {
        this.$refs.plate.selectWellsByDiagnosticAndSampleType(diagnosticColor, sampleType);
      }
    },
    sendFileFtp() {
      this.isSendingFTP = true;
      sendFileFtp(this.id)
        .then(() => {
          this.ftpSent = true;
          this.showSuccessNotification(this.$t('runInterpretation.ftp.successNotification.title'), this.$t('runInterpretation.ftp.successNotification.message'));
        })
        .catch(() => {
          this.ftpSent = false;
          this.showErrorNotification(this.$t('runInterpretation.ftp.errorNotification.title'), this.$t('runInterpretation.ftp.errorNotification.message'));
        })
        .finally(() => this.isSendingFTP = false);
    },
    updateStatus() {
      if (this.newRunStatus === 'WAIT_FOR_APPROVAL') {
        this.$store.dispatch('runImport/submitResults', { id: this.id })
          .then(run => {
            this.run = run;
            this.$refs.updateStatusModal.hideModal();
          })
          .catch(error => {
            this.showErrorNotification(this.$t('errorUpdateStatusTitle'), error);
            this.$refs.updateStatusModal.hideModal();
          });
      } else {
        this.$store.dispatch('runImport/updateStatus', { id: this.id, status: this.newRunStatus })
          .then(run => {
            this.$refs.updateStatusModal.hideModal();
            this.run = run;
            if (run.ftpSent === false) {
              this.showErrorNotification(this.$t('runInterpretation.ftp.errorNotification.title'), this.$t('runInterpretation.ftp.errorNotification.message'));
            }
          })
          .catch(error => {
            this.showErrorNotification(this.$t('errorUpdateStatusTitle'), error);
            this.$refs.updateStatusModal.hideModal();
          });
      }
    }
  }
};

</script>
