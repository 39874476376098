import Lot from './Lot';
import Kit from '@/models/Kit';

export default class KitVersion {
  constructor(id, version, kit, lots, validityStartDate, validityEndDate, isEditable, isFirst) {
    this.id = id;
    this.version = version;
    this.kit = kit;
    this.lots = lots ? lots : [];
    this.validityStartDate = validityStartDate;
    this.validityEndDate = validityEndDate;
    this.isEditable = isEditable;
    this.isFirst = isFirst;
  }

  static build({ id, version, kit, lots, validityStartDate, validityEndDate, isEditable, isFirst }) {
    return new KitVersion(id, version, kit, lots, validityStartDate, validityEndDate, isEditable, isFirst);
  }

  static parse(value) {
    return value ? KitVersion.build({
      id: value.id,
      version: value.version,
      kit: Kit.parse(value.kit),
      lots: value.lots?.map(lot => Lot.parse(lot)),
      validityStartDate: value.validityStartDate,
      validityEndDate: value.validityEndDate,
      isEditable: value.isEditable,
      isFirst: value.isFirst
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      version: this.version !== undefined || this.version !== '' ? this.version : null,
      kit: this.kit !== undefined ? this.kit : null,
      lots: this.lots !== undefined ? this.lots : null,
      validityStartDate: this.validityStartDate !== undefined ? this.validityStartDate : null,
      validityEndDate: this.validityEndDate !== undefined ? this.validityEndDate : null,
      isEditable: this.isEditable !== undefined ? this.isEditable : null,
      isFirst: this.isFirst !== undefined ? this.isFirst : null
    };
  }
}
