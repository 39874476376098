<template>
  <div>
    <b-form-row
      v-for="fileInput in fileInputs"
      :key="fileInput.uuid"
      class="m-auto">
      <b-col lg="10">
        <b-form-group
          class="flex-grow-1">
          <b-form-file
            v-model="fileInput.file"
            :state="state"
            :placeholder="$t('formLoadFile')"
            :drop-placeholder="$t('formDropFile')"
            accept=".xml, .xlsx, .xls"
            @input="emitInputEvent" />
        </b-form-group>
      </b-col>
      <b-col lg="2">
        <b-form-group v-if="maxNumberOfInputs > 1">
          <b-button
            v-if="fileInputs.length >= maxNumberOfInputs"
            ref="removeButton"
            variant="outline"
            class="btn-icon btn-icon-minus"
            @click="removeFileInput(fileInput)">
            <font-awesome-icon :icon="['fas', 'minus-circle']" />
          </b-button>
          <b-button
            v-else
            ref="addButton"
            variant="outline"
            class="btn-icon btn-icon-plus"
            @click="addEmptyFileInput">
            <font-awesome-icon :icon="['fas', 'plus-circle']" />
          </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    maxNumberOfInputs: {
      type: Number,
      default: 1
    },
    state: {
      // Tri-state prop: true, false, null (or undefined)
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      fileInputs: [ { uuid: uuidv4(), file: null } ]
    };
  },
  methods: {
    reset() {
      this.fileInputs = [ { uuid: uuidv4(), file: null } ];
      this.emitInputEvent();
    },
    addEmptyFileInput() {
      this.fileInputs.push({ uuid: uuidv4(), file: null });
    },
    removeFileInput(fileInput) {
      const index = this.fileInputs.findIndex(element => element.uuid === fileInput.uuid);
      this.fileInputs.splice(index, 1);
      this.emitInputEvent();
    },
    emitInputEvent() {
      this.$emit('input', this.fileInputs.filter(fileInput => fileInput.file).map(fileInput => fileInput.file));
    }
  }
};
</script>