<template>
  <div>
    <div v-if="loading" class="loading d-flex justify-content-center align-items-center">
      <b-spinner
          small
          type="grow" />
      <b-spinner
          small
          type="grow" />
      <b-spinner
          small
          type="grow" />
    </div>
    <h5>{{ $t('configuration.import.kit.title') }}</h5>
    <b-row align-h="center">
      <b-col
        sm="4"
        class="m-0 p-0">
        <b-form-file
          v-model="file"
          :placeholder="$t('formLoadFile')"
          accept=".json" />
      </b-col>
      <b-col
        sm="1"
        class="m-0 px-1">
        <b-button
            v-if="loading"
            disabled>
          <b-spinner
              small
              type="grow" />
          <b-spinner
              small
              type="grow" />
          <b-spinner
              small
              type="grow" />
        </b-button>
        <b-button
            v-else
            @click="importFile"
        >
          {{ $t('importButton') }}
        </b-button>
      </b-col>
      <app-confirmation-modal
        ref="kitVersionNameModal"
        :title="$t('modal.import.title', {version: this.nameAlreadyTaken})"
        ok-button-prevent-hiding
        ok-button-spinners-enabled
        @ok="sendNewKitVersionName">
        <p>{{ $t('modal.import.message') }}</p>
        <b-form-input
          v-model="versionName" />
      </app-confirmation-modal>
    </b-row>
  </div>
</template>

<script>
import {importKitVersion} from '../../../../service/GenefoxService';
import NotificationMixin from '../../../../mixins/NotificationMixin';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';

export default {
  components: {
    'app-confirmation-modal' : ConfirmationModal
  },
  mixins: [
    NotificationMixin
  ],
  data() {
    return {
      file: undefined,
      versionName: undefined,
      nameAlreadyTaken: undefined,
      loading: false
    };
  },
  methods: {
    checkFile() {
      if (!this.file) {
        this.showErrorNotification(this.$t('configuration.import.kit.notification.error.title'),
          this.$t('configuration.import.missingFile'));
        return false;
      }
      if (this.file.type !== 'application/json') {
        this.showErrorNotification(this.$t('configuration.import.kit.notification.error.title'),
          this.$t('configuration.import.wrongExtension'));
        return false;
      }
      return true;
    },
    async importFile() {
      const isFileIsValid = this.checkFile();
      if(isFileIsValid) {
        this.loading = true;
        await this.$nextTick();
        importKitVersion(this.file)
            .then(name => {
              if (!name) {
                this.showSuccessNotification(this.$t('configuration.import.kit.notification.success.title'),
                    this.$t('configuration.import.kit.notification.success.message'));
              } else {
                this.nameAlreadyTaken = name;
                this.$refs.kitVersionNameModal.showModal();
              }
            })
            .catch(error => this.showErrorNotification(this.$t('configuration.import.kit.notification.error.title'), error))
            .finally(() => this.loading = false);
      } else {
        this.loading = false;
      }
    },
    sendNewKitVersionName() {
      const isFileIsValid = this.checkFile();
      if (!this.versionName) {
        this.showErrorNotification(this.$t('configuration.import.kit.notification.error.title'),
          this.$t('configuration.import.kit.notification.error.versionNameIsNull'));
        this.$refs.kitVersionNameModal.hideModal();
        return ;
      }
      if(isFileIsValid) {
        importKitVersion(this.file, this.versionName)
            .then(name => {
              if (!name) {
                this.showSuccessNotification(this.$t('configuration.import.kit.notification.success.title'),
                    this.$t('configuration.import.kit.notification.success.message'));
              } else {
                this.showErrorNotification(this.$t('configuration.import.kit.notification.error.title'),
                    this.$t('configuration.import.versionAlreadyExists', {version: this.versionName}));
              }
            })
            .catch(error => this.showErrorNotification(this.$t('configuration.import.kit.notification.error.title'), error))
            .finally(() => this.$refs.kitVersionNameModal.hideModal());
      } else {
        this.$refs.kitVersionNameModal.hideModal();
      }
    }
  }
};
</script>
