<template>
  <div>
    <span class="align-middle">{{ name }}</span>
    <b-badge
      pill
      :variant="isAnalyserConfigured ? 'success' : 'danger'"
      class="badge-outlined ml-1">
      {{ isAnalyserConfigured ? $t('analyserConfigured') : $t('analyserNotConfigured') }}
    </b-badge>
  </div>
</template>

<script>
export default {
  props: {
    analyser: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    isAnalyserConfigured: {
      type: Boolean,
      default: false
    }
  }
};
</script>
