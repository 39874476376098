export default {
  'en-GB': {
    app: {
      navbar: {
        title: 'Genefox',
        logoutButtonText: 'Log out'
      },
      sidebar: {
        runButtonText: 'Runs',
        aboutButtonText: 'About',
        configurationButtonText: 'Configuration',
        laboratoriesButtonText: 'Laboratories',
        laboratoryButtonText: 'Laboratory',
        reindex: 'Reindex data'
      }
    },

    common: {
      add: 'Add',
      cannot_save: 'Cannot save',
      delete: 'Delete',
      no: 'No',
      yes: 'Yes',
      loading: 'Loading...',
      ok: 'Ok'
    },

    //Common run import
    titleNewRun: 'Start New Run',

    headerRun: 'Runs',
    headerConfigurations: 'Configurations',
    noDataFound: 'No data found',
    import: 'Import',
    headerSearch: 'Search',

    //Update Status Modal
    modalSubmitResultsTitle: 'Send result to the doctor',
    modalSubmitResultsMessage: 'Do you wish to submit results?',
    modalApproveResultsMessage: 'Do you want to approve the results of this run?',
    modalRejectResultsMessage: 'Do you want to reject the results of this run?',
    errorUpdateStatusTitle: 'An error occurred during the status update',
    submitResults: 'submit results',
    approveResults: 'approve results',
    rejectResults: 'reject results',

    //New Run import page
    formLoadFile: 'Choose a file or drop it here',
    formDropFile: 'Drop file here',
    subTitleImportPage: 'Import file to start a new run',
    descriptionSubTitleImportPage: 'Select the analyser and the run export file(s) in order to start importing run results.',
    importRunSelectFile: 'Select file to import',
    importRunSelectFiles: 'Select file(s) to import',
    importRunSelectAnalyser: 'Select an analyser',

    //New Run parameters page
    formAnalyser: 'Analyzer',
    formPlateId: 'Plate ID',
    formRunDate: 'Run Date',
    formKit: 'Kit',
    formKitVersion: 'Tm version',
    formKitStatus: 'Status',
    formReactionBuffer: 'Reaction Buffer',
    formChannel: 'Channel',
    formPhase: 'Phase',
    formLot: 'Lot',
    formLotExpirationDate: 'Expiry date',
    formLotActive: 'Active',
    formRunBy: 'Run by: ',
    formSubmittedBy: 'Submitted by: ',
    formInterpretationRunBy: 'Interpreted by: ',
    formMinThreshold: 'Min threshold',
    formSlopeThreshold: 'Slope parameter',
    formMinTemp: 'Min temp',
    formMaxTemp: 'Max temp',
    formInversed: 'Inversed',
    formLabel: 'Label',
    formData: 'Data',
    formZone: 'Zone',
    formSelectKit: 'Select a kit',
    formSelectReactionBuffer: 'Select a reaction buffer',
    formSelectLot: 'Select a lot',
    formSelectAnalyser: 'Select an instrument',
    formSelectStatus: 'Select a status',
    formSelectChannel: 'Select a channel',
    formSelectPhase: 'Select a phase',
    formSelectZone: 'Select a zone',
    formSelectValue: 'Select a value',
    formSelectDiagnostic: 'Select a diagnostic',
    formSelectCondition: 'Select a condition',
    subTitlePageRun: 'Select the parameters of the new run',
    formApprovedBy: 'Approved by: ',
    formRejectedBy: 'Rejected by: ',
    successAddingLot: 'Lot has been created successfully',
    errorAddingLot: 'An error occurred while creating lot',
    errorNoLotName: 'No lot name written',
    errorNoKitSelected: 'No kit selected',
    errorTitleAddingLot: 'Can\'t add lot',
    errorTitleFetchLot: 'An error occurred during the search',

    //SampleType
    dropdownMultiple: 'Multiple',
    BLOOD: 'blood',
    DNA: 'DNA',
    NEGATIVE_TEST: 'negative control',
    POSITIVE_TEST: 'positive control',
    STANDARD: 'standard',

    //List
    listRunId: 'Run Id',
    listInstrumentName: 'Instrument Name',
    listKitReactionBufferPairs: 'Kit (Reaction Buffer)',
    listTechnician: 'Operator',
    listImportDate: 'Import Date',
    listRunStatus: 'Run Status',
    listCreatedDate: 'Run Date',
    listWellName: 'Name',
    listWellPositions: 'Positions',
    listWellSampleType: 'Sample Type',
    listWellKit: 'Kit',
    listeFileName: 'File Name',

    //Status
    RUN_TO_INTERPRET: 'Run to interpret',
    WAIT_FOR_APPROVAL: 'Wait for approval',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',

    //Run comment
    runCommentTitle: 'Run comment',
    FilterOnActiveChannel: 'Filter on active channel',

    //Well caption
    captionTitle: 'Caption',
    captionAnalysedWell: 'No sample type selected',
    captionBloodWell: 'Blood Sample',
    captionDnaWell: 'DNA Sample',
    captionPositiveTestWell: 'Positive Control',
    captionNegativeTestWell: 'Negative Control',
    captionStandardWell: 'Standard',
    captionValidPositiveTestWell: 'Valid Positive Control',
    captionBadPositiveTestWell: 'Invalid Positive Control',
    captionValidNegativeTestWell: 'Valid Negative Control',
    captionBadNegativeTestWell: 'Invalid Negative Control',
    captionValidStandardWell: 'Valid Standard',
    captionBadStandardWell: 'Invalid Standard',
    captionNonAnalysedWell: 'Not Analyzed',
    captionNegativeWell: 'Negative',
    captionHealthyWell: 'Negative Detection',
    captionUnhealthyWell: 'Positive Detection',
    captionAbnormalWell: 'Abnormal',
    captionHomozygousWildTypeWell: 'Homozygous Wild Type',
    captionHomozygousMutatedWell: 'Homozygous Mutated',
    captionHomozygousMutated13910Well: 'Homo Mutated -13910',
    captionHomozygousMutated13915Well: 'Homo Mutated -13915',
    captionHomozygousMutated14009Well: 'Homo Mutated -14009',
    captionHomozygousMutated14010Well: 'Homo Mutated -14010',
    captionHeterozygousWell : 'Heterozygous',
    captionHeterozygous13910Well : 'Heterozygous -13910',
    captionHeterozygous13915Well : 'Heterozygous -13915',
    captionHeterozygous13910_13915Well: 'Hetero -13910 -13915',
    captionHeterozygous14009Well : 'Heterozygous -14009',
    captionHeterozygous14010Well : 'Heterozygous -14010',
    captionHeterozygous14009_14010Well: 'Hetero -14009 -14010',

    templateTitle: 'Template',
    buttonSaveTemplate: 'Save as template',
    buttonLoadTemplate:'Load template',
    wellNotCorrectlyConfiguredTitle: 'Well not correctly configured',
    wellNotCorrectlyConfiguredBody: 'The following well is not fully configured',
    plateTemplateCreatedTitle:'Template creation success',
    plateTemplateCreatedBody:'Template has been successfully created',
    plateTemplateCreatedErrorTitle: 'Template creation error',
    runWellMetadataDropdown: 'Select an option',
    runWellMetadataLoadPlateTemplateTitle: 'Load plate template',
    runWellMetadataConfigurePlateTemplateTitle: 'Configure the wells template',
    runWellMetadataConfigurePlateTitle: 'Configure the selected wells',

    // Diagnostics
    HEALTHY: 'Negative detection',
    SICK: 'Positive detection',
    ABNORMAL: 'Abnormal',
    NEGATIVE: 'Negative',
    HOMOZYGOUS_WILD_TYPE: 'Homozygous Wild Type',
    HOMOZYGOUS_MUTATED: 'Homozygous Mutated',
    HETEROZYGOUS: 'Heterozygous',
    HOMOZYGOUS_MUTATED_13910: 'Homo Mutated -13910',
    HOMOZYGOUS_MUTATED_13915: 'Homo Mutated -13915',
    HOMOZYGOUS_MUTATED_14009: 'Homo Mutated -14009',
    HOMOZYGOUS_MUTATED_14010: 'Homo Mutated -14010',
    HETEROZYGOUS_13910: 'Heterozygous -13910',
    HETEROZYGOUS_13915: 'Heterozygous -13915',
    HETEROZYGOUS_13910_13915: 'Heterozygous -13910 -13915',
    HETEROZYGOUS_14009: 'Heterozygous -14009',
    HETEROZYGOUS_14010: 'Heterozygous -14010',
    HETEROZYGOUS_14009_14010: 'Heterozygous -14009 -14010',
    POSITIVE_TEST_TEST_OK: 'Valid Positive Control',
    POSITIVE_TEST_TEST_KO: 'Invalid Positive Control',
    NEGATIVE_TEST_TEST_OK: 'Valid Negative Control',
    NEGATIVE_TEST_TEST_KO: 'Invalid Negative Control',
    STANDARD_TEST_OK: 'Valid Standard',
    STANDARD_TEST_KO: 'Invalid Standard',

    // Run interpretation
    runInterpretationChannel : 'CHANNEL',
    runInterpretationNoInterpretationResultMessage1: 'No interpretation result can be shown for this run.',
    runInterpretationNoInterpretationResultMessage2: 'This can mean that (some of) the wells have been incorrectly configured.',
    runInterpretationFooterDeleteRunButtonText: 'Delete run',
    runInterpretation: {
      wellInterpretation: {
        wellSelectionFilters: {
          kitDropdownPlaceholder: 'Kit',
          kitVersionDropdownPlaceholder: 'Version',
          reactionBufferDropdownPlaceholder: 'Reaction Buffer'
        }
      },
      deletionModal: {
        title: 'Delete run',
        message: 'Do you really wish to delete this run and the associated data? This action is irreversible. (This could take a while.)',
        okButtonText: 'Yes, delete run',
        cancelButtonText: 'No, do not delete run'
      },
      deleteRun: {
        successNotification: {
          title: 'Run deleted',
          message: 'The run {0} has been deleted successfully.'
        },
        errorNotification: {
          title: 'Run not deleted'
        }
      },
      ftp: {
        runInterpretationSendFileFtp: 'Send results to FTP',
        successNotification: {
          title: 'File send to FTP',
          message: 'The file has been sent successfully to the FTP server'
        },
        errorNotification: {
          title: 'File couldn\'t be sent to FTP server',
          message: 'A problem has occurred during file transfer'
        }
      }
    },

    // Well Configure Window
    formRunConfigurationTitle: 'Plate configuration',
    formSampleType: 'Sample Type',
    formWellName: 'Well Name',
    interpretationInProgress: 'Interpretation in progress',
    wellUpdateConfirmationMessage: 'Do you want to update this value for all the selected wells?',

    formSelectWellConfigurationTemplate: 'Select a template',
    deleteTemplateButton: 'Delete template',
    formTemplateName:'Template name',
    wellConfigurationWindow: {
      formSelectWellConfigurationTarget: 'Select a target',
      expectedDiagnosticLabel: 'Expected result',
      expectedDiagnosticLabelWithChannel: 'Expected result for target {0}',
      target: 'Target'
    },
    deletionTemplateModal: {
      title: 'Delete template',
      message: 'Do you really wish to delete this template?',
      okButtonText: 'Yes, delete it',
      cancelButtonText: 'No, do not delete',
      successNotification: {
        title: 'Template deleted',
        message: 'Template {0} has been deleted successfully.'
      },
      errorNotification: {
        title: 'Template not deleted'
      }
    },

    // Well Interpretation
    wellInterpretationConfigurationTitle: 'Review interpretation results',
    wellInterpretationNoPhaseData: 'No phase data for this well',
    wellInterpretationMultipleKitOrReactionBufferSelected: 'Multiple kits and/or versions and/or reaction buffers selected, unable to render results. Please select only one kit, one reaction buffer and one version.',
    wellInterpretationUpdateDiagnosticButtonText: 'Update Suggested Result',
    wellInterpretationNotificationTitle: 'Well',
    wellInterpretationComments: 'Comments',
    wellInterpretationCommentAdded: 'Comment successfully added',
    wellInterpretationSuggestedResult: 'Updated Suggested Result',
    allPlate: 'All',

    // Well Meta Data
    wellMetaDataWellName: 'Well Name',
    wellMetaDataLot: 'Lot',
    wellMetaDataKitRB: 'Kit - RB',
    wellMetaDataSampleType: 'Sample type',

    // Diagnostic Update Form
    diagnosticUpdateFormIntroductionParagraph: 'Select the new suggested result for well {0}',
    diagnosticUpdateFormValidationButtonText: 'Update Suggested Result',
    diagnosticUpdateFormUpdateErrorNotificationTitle: 'Suggested result not updated.',
    diagnosticUpdateFormUpdateSuccessNotificationTitle: 'Suggested result successfully updated for well {0}.',
    diagnosticUpdateFormUpdateSuccessNotificationMessage: 'New suggested result: {0}',

    // Diagnostic Dropdown
    diagnosticDropdownPlaceholder: 'Select a suggested result',

    //Export Window
    showExportWindowButton: 'Export results',
    exportWindowTitle: 'Export results',
    formExportFormat: 'Export type',
    formExportFormatPlaceholder: 'Select an export type',
    formCsvParams: 'CSV format',
    formCsvDelimiter: 'Column delimiter',
    formCsvDelimiterPlaceholder: 'Select delimiter',
    formRemoveTrailingColumns: 'Show empty columns',
    formExportData: 'Export data',
    formExportByWellName: 'Group by well name',
    formExportDataPlaceholder: 'Select data to export',
    formWellSortDirectionPlaceholder: 'Sort by',
    exportButton: 'Export',
    importButton: 'Import',
    exportAsAPlate: 'Export as a plate',
    filters: 'Filters',
    EXPORT_FORMAT_PDF: 'pdf',
    EXPORT_FORMAT_XLSX: 'xlsx',
    EXPORT_FORMAT_CSV: 'csv',
    EXPORT_DATA_ALL_PLATE: 'All plate',
    EXPORT_DATA_SELECTION: 'Selected wells',
    EXPORT_DATA_WELL_NAME: 'By well name',
    ROW: 'Row',
    COLUMN: 'Column',

    //Comment Configure Window
    formComment: 'Add Comment',

    //Cancel modal
    modalDiscardChangesTitle: 'Cancel',
    modalDiscardChangesMessage: 'Do you want to exit this run?',

    modalCreateTemplateTitle: 'Create template',
    modalCreateTemplateMessage: 'A template already exist with the name {0}. Would you like to update it ?',

    //Common
    actions: 'actions',
    cancel: 'Cancel',
    next: 'Next',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    close: 'Close',
    startInterpretation: 'Start Interpretation',

    // AnalyserDropdown
    analyserDropdownLoadingErrorTitle: 'An error occurred during the analysers loading.',

    //KitDropdown
    kitDropdownLoadingErrorTitle: 'An error occurred during the kit options loading',
    KitDropdownAddKitErrorTitle: 'An error occurred while adding Kit',
    KitDropdownAddKitErrorMessage: 'You cannot add a Kit with a same name as another one',

    //KitVersionDropdown
    KvDropdownAddKvErrorTitle: 'An error occurred while adding Kit Version',
    KvDropdownAddKvErrorMessage: 'You cannot add a Kit Version with a same name as another one',

    //ReactionBufferDropdown
    RbDropdownAddRbErrorTitle: 'An error occurred while adding Reaction Buffer',
    RbDropdownAddRbErrorMessage: 'You cannot add a Reaction Buffer with a same name as another one',

    //KitStatusDropdown
    kitStatusDropdownLoadingErrorTitle: 'An error occurred during the kit status options loading',

    //ChannelDropdown
    channelDropdownLoadingErrorTitle: 'An error occurred during the channel options loading',

    //LotDropdown
    lotDropdownLoadingErrorTitle: 'An error occurred during the lot options loading',

    //SampleTypeDropdown
    sampleTypeDropdownPlaceholder: 'Select a sample type',
    sampleTypeDropdownLoadingErrorTitle: 'An error occurred during the sample type options loading',

    //Errors
    error: 'error',
    runImportError: 'An unexpected error occurred during the run file import.',
    invalidFileError: 'An error occurred during the file parsing : invalid file : {0}',
    corruptedFileError: 'An error occurred during the file parsing : corrupted file or invalid format.',
    errorCommentCreation: 'An error occurred during the comment creation.',
    errorDefaultDropdown: 'An unexpected error occurred during a dropdown options loading.',
    errorExportGeneration: 'An error occurred during the export file generation.',
    errorExportGenerationNoWellAvailable: 'No well to export.',
    errorTitleCannotAccessRunPage: 'Cannot access run page',
    errorTitleCannotSaveRunAndStartInterpretation: 'Cannot save run and start interpretation',
    errorDownloadingFileRun: 'The server didn\'t find any files for this run.',

    //Graph Zone
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',

    //Configuration
    formConfigureLot: 'Lot',
    formConfigureDiagnostic: 'Results Labelling',
    formConfigureKit: 'Kit',
    formConfigureFTP: 'FTP Server Connection',
    formConfigureImportExport: 'Import/export',
    formConfigureExport: 'Export',
    formConfigureImport: 'Import',
    errorTitleConfigureLot: 'An error occurred while updating lots',
    successTitleConfigureLot: 'Lot(s) has/have been updated successfully',
    errorTitleCreateKit: 'An error occurred while creating the kit',
    successTitleCreateKit: 'Kit has been created successfully',
    errorTitleUpdateKit: 'An error occurred while updating the kit',
    successTitleUpdateKit: 'Kit has been updated successfully',
    configurationLot: 'Configuration lot',
    formDateExpirationError: 'Lot\'s expiry date can\'t be set in the past',
    formLotNameError: 'Lot name can not be empty',
    lotDetails: 'Lot details',
    errorTitleCannotUpdateAndDeleteLotAtSameTime: 'Can not update and delete',
    errorCannotUpdateAndDeleteLotAtSameTime: 'You can not update and delete lot(s) at the same time',
    infoTitleCannotSaveWithNoKitToAddOrDelete: 'You can not save',
    errorDuplicateLabels: "There are duplicate labels",
    infoCannotSaveWithNoKitToAddOrDelete: 'You can not save if you do not have any kit to add/delete',
    formDiagnosticHeader: 'Suggested Result',
    label: 'Label',
    errorCannotSaveEmptyDiagnostic: 'You must choose at least one diagnostic, one kit and a label before saving',
    successTitleConfigureDiagnostic: 'Result(s) labelling has/have been updated successfully',
    errorConfigureDiagnostic: 'An error occurred while updating diagnostic',
    diagnosticConfigIntro: 'Configure the diagnostic label displayed in the export files',
    modalConfirmLotDeletion: {
      title: 'Delete lot',
      message: 'Are you sure you want to delete lot?',
      okButtonText: 'Yes',
      cancelButtonText: 'No'
    },
    modalConfirmExpirationDateLot: {
      title: 'Expiry date already passed',
      message: 'One or more lot(s) have an expiry date in the past. Are you sure you want to add lot(s) ?',
      okButtonText: 'Yes',
      cancelButtonText: 'No'
    },
    modalSelectedLotsExpired: {
      title: 'Expiry date already passed',
      message: 'One or more lot(s) have an expiry date in the past.',
      okButtonText: 'Ok'
    },

    //Laboratory
    laboratory: {
      name: 'Name',
      create: {
        title: 'Create laboratory',
        notification: {
          title: 'Create laboratories',
          success_message: 'All laboratories have been created',
          cannot_save_message: 'You cannot save if there is no laboratory or not all of them are named'
        }
      },
      update: {
        notification: {
          title: 'Update laboratory',
          success_message: 'Laboratory has been updated'
        }
      },
      delete: {
        title: 'Delete laboratory',
        message: 'Do you really wish to delete this laboratory ?',
        notification: {
          title: 'Delete laboratory',
          success_message: 'Laboratory has been deleted'
        }
      },
      cant_delete: {
        title: 'Cannot delete laboratory',
        message: 'You cannot delete this laboratory because users are still linked'
      },
      search: 'Search by name'
    },

    //User
    user: {
      enabled: 'Enabled',
      login: 'Login',
      lastname: 'Lastname',
      firstname: 'Firstname',
      email: 'E-mail',
      email_verified: 'E-mail verified',
      role: {
        salesAdmin: 'Sales',
        qaAdmin: 'QA',
        rdAdmin: 'R&D',
        physician: 'Physician'
      },
      update: {
        notification: {
          title: 'Update user',
          success_message: 'User has been updated'
        }
      },
      delete: {
        title: 'Delete user',
        message: 'Do you really wish to delete this user ?',
        notification: {
          title: 'Delete user',
          success_message: 'User has been deleted'
        }
      },
      search: 'Search by login, lastname, firstname and email'
    },

    //reindex data
    modalConfirmReindexTitle: 'Reindex',
    modalConfirmReindexMessage: 'Do you want to reindex all runs?',
    reindexDataSuccessTitle: 'Reindex data success',
    reindexDataSuccessMessage: 'Reindex data has started asynchronously, please check server logs',
    reindexDataErrorTitle: 'Reindex data error',
    reindexDataErrorMessage: 'An error occured during reindex data',

    //about modal
    modalAboutTitle: 'About GeneFox',

    //release note modal
    modalReleaseNoteTitle: 'Release Note',

    //configuration
    configuration: {
      kit: {
        kit: 'Kit',
        analyzer: 'Analyzer',
        reaction_buffers: 'Reaction Buffers',
        phases: 'Phases',
        zones: 'Zones',
        combinations: 'Combinations',
        combination: 'Combination',
        color: 'Color',
        diagnostic: 'Diagnostic',
        mutation: 'Mutation',
        conditions: 'Conditions'
      },
      lot: {
        save: {
          notification: {
            title: 'Validation error',
            invalid_message: 'The lot\'s configuration is not valid'
          }
        }
      },
      ftp: {
        not_configured: 'Connection is not configured, press + button to setup connection',
        sendFilesOnApproval: 'Send results when run is approved by the doctor',
        protocol: 'Protocol',
        host: 'Host',
        port: 'Port',
        warn: 'Warning: we recommend to use a SFTP server to ensure data is encrypted during the transfer',
        directory: 'Directory',
        authentication: 'Authentication',
        username: 'Username',
        password: 'Password',
        key_file: 'Key file',
        test_connection: 'Test connection',
        title: 'FTP',
        error: {
          get: 'Unable to find FTP configuration',
          save: 'Unable to save FTP configuration',
          delete: 'Unable to delete FTP configuration',
          test: 'Test connection failed'
        },
        success: {
          save: 'Successfully saved FTP configuration',
          delete: 'Successfully deleted FTP configuration',
          test: 'Successfully ran test connection'
        }
      },
      export: {
        kit: {
          title: 'Kit export',
          notification: {
            success: {
              title: 'Kit export',
              message: 'The kit configuration has been exported successfully'
            },
            error: {
              title: 'Kit export',
              message: 'Kit and version can\'t be null'
            }
          }
        },
        lot: {
          title: 'Lot export',
          notification: {
            success : {
              title : 'Lot export',
              message: 'The lots have been exported successfully'
            },
            error: {
              title: 'Lot export',
              message: 'Lot and version can\'t be null'
            }
          }
        }

      },
      import: {
        kit: {
          title: 'Kit import',
          notification: {
            success: {
              title: 'Kit import',
              message: 'Kit\'s configuration has been imported successfully'
            },
            error: {
              title: 'Kit import',
              versionNameIsNull: 'The version name is null'
            }
          }
        },
        lot: {
          title: 'Lot import',
          notification: {
            success: {
              title: 'Lot import',
              message: 'Lot(s) has/have been imported successfully'
            },
            error: {
              title: 'Lot import'
            }
          }
        },
        message: 'An error has occurred during the import',
        missingFile: 'A file (.json) have to be selected',
        wrongExtension: 'The selected file has not .json extension',
        versionAlreadyExists: 'The version name {version} already exists'
      }
    },
    errorTitleAddingRb: 'Can\'t add reaction buffer',
    errorNoRbName: 'No reaction buffer name written',
    modal: {
      kit_configuration: {
        cancel: {
          title: 'Cancel',
          message: 'Are you sure you want to discard all changes ?'
        }
      },
      phase: {
        remove: {
          title: 'Remove phase',
          message: 'Are you sure you want to remove this phase ? This will impact all your combinations.'
        }
      },
      zone: {
        remove: {
          title: 'Remove zone',
          message: 'Are you sure you want to remove this zone ? This will impact all your combinations.'
        }
      },
      ftp_configuration: {
        cancel: {
          title: 'Cancel',
          message: 'Are you sure you want to discard all changes ?'
        },
        delete: {
          title: 'Delete FTP Configuration',
          message: 'This is irreversible. Are you sure you want to delete the FTP configuration ?'
        }
      },
      import: {
        title: 'Version name {version} already exists',
        message: 'Choose another name for the version : '
      }
    },

    plate: {
      missing: {
        kit: 'Kit is not selected',
        lot: 'Lot is not selected',
        reactionBuffer: 'Reaction buffer is not selected',
        sampleType: 'Sample type is not selected',
        expectedResult: {
          common: 'Expected result is not selected',
          target: 'Expected result for type {0} is not selected'
        }
      }
    },
    analyserConfigured: 'set',
    analyserNotConfigured: 'unset',
    PASSWORD: 'Password',
    SSH_KEY: 'SSH Key',

    tooltip: {
      envelope: {
        ok: 'Results have been sent to the laboratory',
        ko: 'An error has occurred while sending results to the laboratory'
      }
    },

    VALIDATED: 'Validated',
    NOT_VALIDATED: 'Not validated',
    PERF: 'PERF',
    pageable: {
      listing: {
        name: 'Name',
        search: 'Search by name',
        disableMessageTooltip: 'You must cancel or save your change before selecting another lot'
      }
    }
  }
};
