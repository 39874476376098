import {getField, updateField} from 'vuex-map-fields';
import Keycloak from 'keycloak-js';
import cloneDeep from 'lodash/cloneDeep';

const state = {
  keycloak: new Keycloak(),
  userInfo: undefined
};

export const getters = {
  getField,
  hasRole: state => role => state.keycloak.hasResourceRole(role, process.env.VUE_APP_KEYCLOAK_RUNS_RESOURCE),
  userName: state => state.userInfo
    ? state.userInfo.preferred_username
    : '',
  userLaboratory: state => state.userInfo?.laboratories && state.userInfo?.laboratories[0] ? state.userInfo.laboratories[0]
                                                                                           : ''
};

const mutations = {
  updateField
};

export const actions = {
  setKeycloakInstance({ commit }, keycloak) {
    commit('updateField', { path: 'keycloak', value: cloneDeep(keycloak) });
    keycloak.loadUserInfo().then(response => {
      commit('updateField', { path : 'userInfo', value: cloneDeep(response) });
    });
  },
  logout({ state }) {
    state.keycloak.logout();
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
