export default class Export {
  constructor(wellIds,
              kitReactionBuffers,
              format,
              isExportAsPlate,
              sortDirection,
              csvDelimiter,
              csvShowTrailing,
              groupByWellName,
              fileName) {
    this.wellIds = wellIds;
    this.kitReactionBuffers = kitReactionBuffers;
    this.format = format;
    this.isExportAsPlate = isExportAsPlate;
    this.sortDirection = sortDirection;
    this.csvDelimiter = csvDelimiter;
    this.csvShowTrailing = csvShowTrailing;
    this.groupByWellName = groupByWellName;
    this.fileName = fileName;
  }

  static build({ wellIds, kitReactionBuffers, format, isExportAsPlate, sortDirection, csvDelimiter, csvShowTrailing, groupByWellName, fileName }) {
    return new Export(wellIds, kitReactionBuffers, format, isExportAsPlate, sortDirection, csvDelimiter, csvShowTrailing, groupByWellName, fileName);
  }
}