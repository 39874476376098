<template>
  <div>
    <app-run-list-header @select="updateSearch" />
    <div class="text-center">
      <b-table
        responsive
        striped
        hover
        show-empty
        thead-class="run-table-header"
        :fields="fields"
        :items="runs"
        :empty-filtered-text="$t('noDataFound')"
        :empty-text="$t('noDataFound')"
        :no-local-sorting="true"
        sort-by="businessId"
        :sort-desc="true"
        @sort-changed="reloadSort">
        <template #cell(fileName)="data">
          <template v-if="allowFileRunDownload && hasRole('SUPER_ADMIN')">
            <b-button
              :id="`filename-${data.index}`"
              :ref="`filename${data.index}`"
              variant="link"
              class="overflow-dot overflow-dot-xl"
              @click="downloadFileRuns(data.item.id)">
              {{ data.value }}
            </b-button>
          </template>
          <template v-else>
            <span
              :id="`filename-${data.index}`"
              class="overflow-dot overflow-dot-xl">
              {{ data.value }}
            </span>
          </template>
          <b-popover
            v-show="textOverflow(data.value, 'filename', data.index)"
            :ref="`popoverfilename${data.index}`"
            :target="`filename-${data.index}`"
            placement="top"
            triggers="hover"
            :content="`${data.value}`" />
        </template>
        <template #cell(kitReactionBufferPairs)="data">
          <span
            :id="`krb-${data.index}`"
            :ref="`krb${data.index}`"
            class="overflow-dot overflow-dot-xl">
            {{ data.value }}
          </span>
          <b-popover
            v-show="textOverflow(data.value, 'krb', data.index)"
            :ref="`popoverkrb${data.index}`"
            :target="`krb-${data.index}`"
            placement="top"
            triggers="hover focus"
            :content="`${data.value}`" />
        </template>
        <template #cell(interpretationRunByName)="data">
          <span
            :id="`irbn-${data.index}`"
            :ref="`irbn${data.index}`"
            class="overflow-dot overflow-dot-m">
            {{ data.value }}
          </span>
          <b-popover
            v-show="textOverflow(data.value, 'irbn', data.index)"
            :ref="`popoverirbn${data.index}`"
            :target="`irbn-${data.index}`"
            placement="top"
            triggers="hover focus"
            :content="`${data.value}`" />
        </template>
        <template #cell(status)="data">
          <div class="d-flex justify-content-center align-items-center">
            <span
              :id="`status-${data.index}`"
              :ref="`status${data.index}`"
              class="overflow-dot overflow-dot-m">
              {{ data.value }}
            </span>
            <b-popover
              v-show="textOverflow(data.value, 'status', data.index)"
              :ref="`popoverstatus${data.index}`"
              :target="`status-${data.index}`"
              placement="top"
              triggers="hover focus"
              :content="`${data.value}`" />
            <template v-if="data.item.ftpSent !== null">
              <span :id="`ftp-${data.index}`"
                    :ref="`ftp${data.index}`"
                    :class="data.item.ftpSent ? 'envelopeOk' : 'envelopeKo'">
                <font-awesome-icon
                  :icon="['far', 'envelope']"
                  class="ml-1" />
                <b-popover
                  :ref="`popoverftp${data.index}`"
                  :target="`ftp-${data.index}`"
                  placement="top"
                  triggers="hover focus"
                  :content="$t(`tooltip.envelope.${data.item.ftpSent ? 'ok' : 'ko'}`)" />
              </span>
            </template>
          </div>
        </template>
        <template #cell(edit)="data">
          <b-button
            v-if="data.item.interpretationRunByName"
            variant="outline"
            class="btn-icon"
            @click="checkRunParsed(data.item.id)">
            <b-row>
              <font-awesome-icon :icon="['fas', 'eye']" />
            </b-row>
          </b-button>
          <app-delete-button
            v-else
            :icon="true"
            :run-id="data.item.id"
            @runDelete="pageChange(currentPage)" />
        </template>
      </b-table>
      <b-pagination
        v-if="runs.length"
        v-model="currentPage"
        align="center"
        :total-rows="runCount"
        :per-page="runPerPage"
        @change="pageChange" />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import RunListHeader from './RunListHeader';
import { checkRunParsed, downloadFileRuns, getPublicProperties } from '@/service/GenefoxService';
import NotificationMixin from '../../mixins/NotificationMixin';
import SecurityMixin from '../../mixins/SecurityMixin';
import DeleteButton from '../common/DeleteButton.vue';

export default {
  name: 'RunList',
  components: {
    'app-run-list-header': RunListHeader,
    'app-delete-button': DeleteButton
  },
  mixins: [
    SecurityMixin,
    NotificationMixin
  ],
  data() {
    return {
      search: undefined,
      currentPage: 1,
      sort: 'businessId',
      sortDirection: 'DESC',
      allowFileRunDownload: false,
      runId: undefined
    };
  },
  computed: {
    ...mapFields('runList', [
      'runs',
      'runCount',
      'runPerPage'
    ]),
    fields() {
      return  [
        {
          key: 'businessId',
          label: this.$options.filters.capitalize(this.$t('listRunId')),
          sortable: true
        },
        {
          key: 'fileName',
          label: this.$options.filters.capitalize(this.$t('listeFileName')),
          sortable: true
        },
        {
          key: 'instrumentName',
          label: this.$options.filters.capitalize(this.$t('listInstrumentName')),
          sortable: true
        },
        {
          key: 'kitReactionBufferPairs',
          label: this.$options.filters.capitalize(this.$t('listKitReactionBufferPairs')),
          sortable: true,
          formatter: 'kitNameFormatter'
        },
        {
          key: 'interpretationRunByName',
          label: this.$options.filters.capitalize(this.$t('listTechnician')),
          sortable: true
        },
        {
          key: 'created',
          label: this.$options.filters.capitalize(this.$t('listCreatedDate')),
          sortable: true,
          formatter: 'createdDateFormatter'
        },
        {
          key: 'status',
          label: this.$options.filters.capitalize(this.$t('listRunStatus')),
          sortable: true,
          formatter: 'statusFormatter'
        },
        {
          key: 'edit',
          label: this.$options.filters.capitalize(this.$t('actions')),
          thClass: 'pl-1 pr-1'
        }
      ];
    }
  },
  mounted() {
    this.pageChange(1);

    return getPublicProperties()
      .then(properties => {
        this.allowFileRunDownload = properties.genefox.run.allowFileDownload;

        return properties;
      });
  },
  methods: {
    reloadSort(ctx) {
      this.sort = ctx.sortBy;
      this.sortDirection = ctx.sortDesc ? 'DESC' : 'ASC';
      this.pageChange(1);
    },
    createdDateFormatter(value) {
      return value ? this.$d(new Date(value)) : '';
    },
    statusFormatter(value) {
      return value ? this.$options.filters.capitalize(this.$t(value)) : '';
    },
    kitNameFormatter(value, key, run) {
      return run.usedKitReactionBufferPairs.map(pair => {
        const kitNameParts = pair.kitVersion.kit.name.split('-');
        const kit = kitNameParts[1] ? kitNameParts[1] : pair.kitVersion.kit.name;

        if (kit) {
          return `${kit} (${pair.reactionBuffer.name})`;
        }
        else {
          return '';
        }
      }).join(', ');
    },
    updateSearch(search) {
      if (search.length >= 1) {
        this.search = search;
      } else {
        this.search = undefined;
      }
      this.pageChange(1);
    },
    checkRunParsed(id) {
      checkRunParsed(id)
        .then(() => this.$router.push({ name: 'run-interpretation', params: { id } }))
        .catch(error => this.showErrorNotification(this.$t('errorTitleCannotAccessRunPage'), error));
    },
    pageChange(page) {
      if (page === 1) {
        this.setRunPerPage();
      }
      const search = this.search;
      const sort = this.sort;
      const sortDirection = this.sortDirection;
      this.$store.dispatch('runList/findRuns', { page, search, sort, sortDirection })
        .then(() => this.currentPage = page)
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
        });
    },
    setRunPerPage() {
      if (!this.$el.getElementsByClassName('run-table-header').length) {
        return;
      }
      const tableHeaderHeight = this.$el.getElementsByClassName('run-table-header')[0].clientHeight;
      const totalHeight = document.getElementsByClassName('content')[0].clientHeight - tableHeaderHeight - 56 - 38; // -56 because of navbar -38 because of paginator
      this.$store.dispatch('runList/setRunPerPage', Math.floor(totalHeight / 56.5));
    },
    textOverflow(text, ref, index) {
      const font = '16px Segoe UI';
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = font;

      return this.$nextTick(() => {
        if (!this.$refs[ref + index]) {
          return;
        }
        const overflow = context.measureText(text).width > this.$refs[ref + index].clientWidth + 5;
        if (!overflow) {
          this.$refs[`popover${ref}${index}`].$destroy();
        }
      });
    },
    downloadFileRuns(id) {
      downloadFileRuns(id)
        .then(res => {
          const FILE = window.URL.createObjectURL(new Blob([ res.data ], { type: 'application/zip' }));
          window.open(FILE);
        })
        .catch(error => this.showErrorNotification(this.$t('errorDownloadingFileRun'), error));
    }
  }
};
</script>
