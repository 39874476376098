import { getField, updateField } from 'vuex-map-fields';
import { GenefoxApi } from '../../service/http-common';
import Run from '../../models/Run';

const state = {
  runs: [],
  runCount: 0,
  runPerPage: 13,
  run: new Run(),
  currentSearch: ''
};

const getters = {
  getField
};

export const mutations = {
  updateField
};

export const actions = {
  findRuns({ commit }, { page, search , sort, sortDirection}) {
    return new Promise((resolve, reject) => {
      GenefoxApi.get('/run', {
        params: { page: page - 1, size: state.runPerPage, search, sort, sortDirection },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
      })
        .then(response => {
          const runs = response.data.listObjects.map(run => Run.parse(run));
          commit('updateField', { path: 'currentSearch', value: search });
          commit('updateField', { path: 'runCount', value: response.data.numberOfObjects });
          commit('updateField', { path: 'runs', value: runs });
          resolve(runs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  findRun({ commit }, id) {
    return new Promise((resolve, reject) => {
      GenefoxApi.get(`/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
        .then(response => {
          const run = Run.create(response.data);
          commit('updateField', { path: 'run', value: run });
          resolve(run);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setRunPerPage({commit}, runPerPage) {
    commit('updateField', { path: 'runPerPage', value: runPerPage });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
