
export default class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  static build({ x, y }) {
    return new Point(x, y);
  }

  static parse(value) {
    return value ? Point.build({
      x: value.x,
      y: value.y
    }) : null;
  }
}
