export default class Lot {
  constructor(id, name, expirationDate, active, admin, production) {
    this.id = id;
    this.name = name;
    this.expirationDate = expirationDate;
    this.active = active;
    this.admin = admin;
    this.production = production;
  }

  static build({ id, name, expirationDate, active, admin, production }) {
    return new Lot(id, name, expirationDate, active, admin, production);
  }

  static parse(value) {
    return value ? Lot.build({
      id: value.id,
      name: value.name,
      expirationDate: new Date(value.expirationDate),
      active: value.active,
      admin: value.admin,
      production: value.production
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      expirationDate: this.expirationDate !== undefined || this.expirationDate !== '' ? this.expirationDate : null,
      active: this.active !== undefined ? this.active : null,
      admin: this.admin !== undefined ? this.admin : null,
      production: this.production !== undefined ? this.production : null
    };
  }
}
