<template>
  <editable-multiselect
    ref="multiselect"
    v-model="model"
    :options="options"
    :placeholder="placeholder"
    :custom-label="customLabel"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :show-labels="showLabels"
    :class="cssClasses"
    :disabled="disabled"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)">
    <template
      v-if="canAdd && canReadKitConfig"
      #afterList>
      <span>
        <b-input
          v-model="kvName"
          class="col-lg-9 dropdown-add-input"
          @mousedown.self.stop />
        <b-button
          ref="addButton"
          variant="outline"
          class="btn--no-outline btn-icon btn-icon-plus dropdown-add-button"
          :disabled="!kvName"
          @click="addKv">
          <font-awesome-icon
            :icon="['fas', 'plus-circle']" />
        </b-button>
      </span>
    </template>
  </editable-multiselect>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import EditableMultiselect from '@/components/common/EditableMultiselect';
import {getKitVersions} from '@/service/GenefoxService';
import SecurityMixin from '@/mixins/SecurityMixin';
import KitVersion from '@/models/KitVersion';
import {getKitVersionsLinkedToLotDisabled} from '../../service/GenefoxService';

export default {
  components: {
    'editable-multiselect': EditableMultiselect
  },
  mixins: [
    DropdownMixin,
    SecurityMixin
  ],
  props: {
    kit: {
      type: Object,
      default: undefined
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      kvName: null
    };
  },
  computed: {
    canReadKitConfig() {
      return this.hasRole('READ_KIT_CONFIG_ADMIN');
    }
  },
  watch: {
    kit(newKit, oldKit) {
      if (newKit?.name === oldKit?.name) {
        return;
      }
      if (!newKit?.id) {
        this.loadedOptions = [];
        this.model = undefined;
        this.kvName = 'v1';
        this.$emit('canModify', true);

        return;
      }
      this.reloadKitVersions(newKit.id);
    }
  },
  methods: {
    customLabel(value) {
      return value?.version;
    },
    addKv() {
      if (this.options.find(o => o.version === this.kvName)) {
        this.showErrorNotification(this.$t('KvDropdownAddKvErrorTitle'), this.$t('KvDropdownAddKvErrorMessage'));

        return;
      }
      if (this.options.length > 0 && !this.options[this.options.length - 1].id) {
        this.options.pop();
      }
      const kv = new KitVersion(null, this.kvName, this.kit, [], null, null, true);
      this.options.push(kv);
      this.$emit('addKv', kv);
      this.$refs.multiselect.deactivate();
    },
    reloadKitVersions(newKitId, kv) {
      if (!newKitId) {
        this.loadedOptions = [];
        this.kvName = 'v1';

        return;
      }
      let promise;
      if (this.canReadKitConfig) {
        promise = getKitVersionsLinkedToLotDisabled(newKitId);
      } else {
        promise = getKitVersions(newKitId, true, false);
      }
      promise
        .then(kitVersions => {
          this.isLoading = false;
          this.loadedOptions = kitVersions;
          if (kitVersions.length) {
            this.$emit('isNewVersionAddable', !kitVersions[kitVersions.length - 1]?.isEditable);
            if (kitVersions.length === 1 && kitVersions[0].lots.length === 0) {
              this.$emit('canModify', true);
            } else {
              this.$emit('canModify', false);
            }
            if (!kv?.id) {
              this.model = kitVersions[kitVersions.length - 1];
              this.$emit('select', kitVersions[kitVersions.length - 1]);
            }
            if (!kitVersions[kitVersions.length - 1]?.isEditable) {
              this.kvName = `v${kitVersions.length + 1}`;
            } else {
              this.kvName = undefined;
            }
          } else {
            this.kvName = 'v1';
            this.$emit('canModify', true);
          }
        })
        .catch(error => console.error(error));
    }
  }
};
</script>
