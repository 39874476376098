<template>
  <editable-multiselect
    v-model="model"
    :options="options"
    :custom-label="customLabel"
    :placeholder="multiselectPlaceholder"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :show-labels="showLabels"
    :class="cssClasses"
    :disabled="disabled"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)">
    <template
      v-if="displayBadge"
      #singleLabel="props">
      <app-channel
        :channel="props.option"
        :channels="channels" />
    </template>
    <template
      v-if="displayBadge"
      #option="props">
      <app-channel
        :channel="props.option"
        :channels="channels" />
    </template>
  </editable-multiselect>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import EditableMultiselect from './EditableMultiselect.vue';
import Channel from './Channel.vue';

export default {
  components: {
    'editable-multiselect': EditableMultiselect,
    'app-channel': Channel
  },
  mixins: [ DropdownMixin ],
  props: {
    value: {
      type: [ Object, String ],
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    channels: {
      type: Array,
      default: () => []
    },
    displayBadge: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorNotificationTitle() {
      return this.$t('channelDropdownLoadingErrorTitle');
    },
    multiselectPlaceholder() {
      return this.placeholder !== undefined ? this.placeholder : this.$t('formSelectChannel');
    }
  },
  methods: {
    customLabel(value) {
      return value.id ? value.name : value;
    }
  }
};
</script>
