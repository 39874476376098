<template>
  <b-modal
    :id="id"
    :title="title"
    :header-text-variant="headerTextVariant"
    :ok-only="true"
    @close="handleOkButtonClick"
    @hide="handleModalHideEvent">
    <b-form-row class="mb-lg-1 mt-2">
      <b-col class="mt-1">
        <img
          src="@/assets/images/about/laCAR-logo2014HD.jpg"
          height="58"
          width="220"
          :alt="'Lacar'">
      </b-col>
      <b-col>
        <img
          src="@/assets/images/genefox-print_couleur-XL.png"
          height="58"
          width="220"
          :alt="'GeneFox'">
      </b-col>
    </b-form-row>
    <b-form-row class="mb-lg-1">
      <b-col lg="3">
        <img
          src="@/assets/images/about/REF.png"
          class="ml-auto"
          height="83"
          width="90"
          :alt="'REF'">
      </b-col>
      <b-col lg="6">
        <div
          class="mt-4"
          style="font-size: 18px;">
          GeneFox
        </div>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-lg-1">
      <b-col lg="3">
        <img
          src="@/assets/images/about/UDI.png"
          class="ml-auto"
          height="83"
          width="90"
          :alt="'UDI'">
      </b-col>
      <b-col lg="6">
        <div
          class="mt-4"
          style="font-size: 18px;">
          05430003908280
        </div>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-lg-1">
      <b-col lg="3">
        <img
          src="@/assets/images/about/SN.png"
          class="ml-auto"
          height="83"
          width="90"
          :alt="'SN'">
      </b-col>
      <b-col lg="6">
        <div
          v-if="release && getAppVersion === release.version"
          class="mt-4"
          style="font-size: 18px;">
          <a href="#"
             @click="getRelease(true)">
            v.{{ getAppVersion }}-{{ getAppReleased }}
          </a>
        </div>
        <div
          v-else
          class="mt-4"
          style="font-size: 18px;">
            v.{{ getAppVersion }}-{{ getAppReleased }}
        </div>
      </b-col>
      <b-col lg="3">
        <img
          src="@/assets/images/about/IVD.png"
          class="ml-auto"
          height="83"
          width="90"
          :alt="'IVD'">
      </b-col>
    </b-form-row>
    <b-form-row class="mb-lg-1">
      <b-col lg="3">
        <img
          src="@/assets/images/about/build-date.png"
          class="ml-auto"
          height="83"
          width="90"
          :alt="'build-date'">
      </b-col>
      <b-col lg="6">
        <div
          class="mt-4"
          style="font-size: 18px;">
          {{ getAppReleased }}
        </div>
      </b-col>
      <b-col lg="3">
        <img
          src="@/assets/images/about/CE.png"
          class="ml-auto"
          style="padding-top: 15px; padding-left: 10px;"
          height="60"
          width="70"
          :alt="'CE'">
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <img
          src="@/assets/images/about/build-place.png"
          class="ml-auto"
          height="83"
          width="90"
          :alt="'build-place'">
      </b-col>
      <b-col lg="6">
        <address>
          LaCAR MDx Technologies<br>
          <p style="font-size:15px;">
            10, Rue des Chasseurs Ardennais<br>
            4031 Liège (Belgium)<br>
            Tel: +32(0)4 287 39 44<br>
            www.lacar-mdx.com
          </p>
        </address>
      </b-col>
      <b-col>
        <img
          src="@/assets/images/about/IFU.png"
          class="ml-auto"
          height="83"
          width="90"
          :alt="'IFU'">
      </b-col>
    </b-form-row>

    <app-release-note-modal
        ref="releaseNoteModal"
        :release="release"
        :not-seen="false"
        :ok-button-name="$t('common.ok')"/>
  </b-modal>
</template>

<script>
import ModalMixin from '../../mixins/ModalMixin';
import {getLastRelease} from "@/service/GenefoxService";
import ReleaseNoteModal from "@/components/common/ReleaseNoteModal";

export default {
  components: {
    'app-release-note-modal': ReleaseNoteModal
  },
  mixins: [ ModalMixin ],
  mounted() {
    this.getRelease(false);
  },
  data() {
    return {
      release: undefined
    };
  },
  computed: {
    getAppVersion() {
      return this.release?.version;
    },
    getAppReleased() {
      return this.release?.date;
    }
  },
  methods: {
    handleOkButtonClick(event) {
      if (!this.okButtonClicked) {
        this.$emit('cancel');
        if (!this.preventAutomaticHiding) {
          this.hideModal();
        } else {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }
    },
    getRelease(showModal) {
      return new Promise((resolve, reject) => {
        getLastRelease()
          .then(release => {
            if (release) {
              this.release = release;
            }
            if (showModal) {
              this.$refs.releaseNoteModal.showModal();
            }
            resolve(release);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
</script>