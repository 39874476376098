export default class User {
  constructor(id, login, lastname, firstname, email, enabled, emailVerified, requiredActions, salesAdmin, qaAdmin, rdAdmin, physician) {
    this.id = id;
    this.login = login;
    this.lastname = lastname;
    this.firstname = firstname;
    this.email = email;
    this.enabled = enabled;
    this.emailVerified = emailVerified;
    this.requiredActions = requiredActions;
    this.salesAdmin = salesAdmin;
    this.qaAdmin = qaAdmin;
    this.rdAdmin = rdAdmin;
    this.physician = physician;
  }

  static build({ id, login, lastname, firstname, email, enabled, emailVerified, requiredActions, salesAdmin, qaAdmin, rdAdmin, physician }) {
    return new User(id, login, lastname, firstname, email, enabled, emailVerified, requiredActions, salesAdmin, qaAdmin, rdAdmin, physician);
  }

  static parse(value) {
    return value ? User.build({
                                id: value.id,
                                login: value.login,
                                lastname: value.lastname,
                                firstname: value.firstname,
                                email: value.email,
                                enabled: value.enabled,
                                emailVerified: value.emailVerified,
                                requiredActions: value.requiredActions,
                                salesAdmin: value.salesAdmin,
                                qaAdmin: value.qaAdmin,
                                rdAdmin: value.rdAdmin,
                                physician: value.physician
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      login: this.login !== undefined || this.login !== '' ? this.login : null,
      lastname: this.lastname !== undefined || this.lastname !== '' ? this.lastname : null,
      firstname: this.firstname !== undefined || this.firstname !== '' ? this.firstname : null,
      email: this.email !== undefined || this.email !== '' ? this.email : null,
      enabled: this.enabled !== undefined || this.enabled !== '' ? this.enabled : null,
      emailVerified: this.emailVerified !== undefined || this.emailVerified !== '' ? this.emailVerified : null,
      requiredActions: this.requiredActions !== undefined || this.requiredActions !== [] ? this.requiredActions : null,
      salesAdmin: this.salesAdmin !== undefined || this.salesAdmin !== '' ? this.salesAdmin : null,
      qaAdmin: this.qaAdmin !== undefined || this.qaAdmin !== '' ? this.qaAdmin : null,
      rdAdmin: this.rdAdmin !== undefined || this.rdAdmin !== '' ? this.rdAdmin : null,
      physician: this.physician !== undefined || this.physician !== '' ? this.physician : null
    };
  }
}
