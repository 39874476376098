<template>
  <div>
    <div class="text-lg-center">
      <h5>
        {{ $t('configurationLot') }}
      </h5>
    </div>
    <b-form-row>
      <b-col lg="3">
        <b-form-group :label="$t('formKit')">
          <app-kit-editable-multiselect
            ref="appKitEditableMultiselect"
            :value="kit"
            :disabled="disableListing"
            @input="changeKit" />
        </b-form-group>
      </b-col>
      <b-col lg="2">
        <b-form-group :label="$t('formKitVersion')">
          <app-kit-version-dropdown
            ref="appKitVersionDropdown"
            :value="kitVersion"
            :kit="kit"
            :disabled="disableListing"
            @input="changeKitVersion" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <div>
      <b-row class="lot-configuration">
        <b-col
          lg="3"
          class="p-1">
          <app-pageable-listing
            v-if="kitVersion"
            ref="lotListGroupItems"
            class="lot-item"
            :items="lots"
            :set-row-class-function="setClass()"
            :items-par-page="itemsPerPage"
            :total-elements="totalElements"
            :current-page="currentPage"
            :disable-table="disableListing"
            :search="search"
            :disable-message-tooltip="$t('pageable.listing.disableMessageTooltip')"
            :disable-add-button="!canWriteLotConfiguration"
            @addItem="addLot"
            @selectedItem="onSelectLot"
            @pageChange="changePage"
            @search="search = $event"/>
        </b-col>
        <b-col
          lg="9"
          class="p-1">
          <app-lot-details
            v-if="lot"
            :lot="lot"
            :kit-version="kitVersion"
            :disabled="!canWriteLotConfiguration"
            @lotSaved="save"
            @cancel="cancel" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LotDetails from './kit-lot-data/LotDetails.vue';
import Lot from '../../../models/Lot';
import cloneDeep from 'lodash/cloneDeep';
import PageableListing from '../../common/PageableListing.vue';
import {isNil} from 'lodash';
import {findLotsByVersion, saveLot} from '../../../service/GenefoxService';
import NotificationMixin from '../../../mixins/NotificationMixin';
import ValidationMixin from '../../../mixins/ValidationMixin';
import KitEditableMultiselect from '../../common/KitEditableMultiselect.vue';
import KitVersionDropdown from '../../common/KitVersionDropdown.vue';
import {mapFields} from 'vuex-map-fields';
import SecurityMixin from "@/mixins/SecurityMixin";

export default {
  components: {
    'app-lot-details': LotDetails,
    'app-pageable-listing': PageableListing,
    'app-kit-editable-multiselect': KitEditableMultiselect,
    'app-kit-version-dropdown': KitVersionDropdown
  },
  mixins: [
    NotificationMixin,
    ValidationMixin,
    SecurityMixin
  ],
  data() {
    return {
      kit: undefined,
      kitVersion: undefined,
      lots: [],
      lot: undefined,
      now: new Date(),
      search: undefined,
      itemsPerPage: 5,
      totalElements: 5,
      currentPage: 1,
      isCreatingLot: false,
      processing: false
    };
  },
  computed: {
    ...mapFields('configuration', [
      'baseLot'
    ]),
    disableListing() {
      return !!this.lot && this.lot.id && (this.lot.active !== this.baseLot.active || !this.lot.expirationDate || this.lot.expirationDate.getTime() !== this.baseLot.expirationDate.getTime());
    },
    canWriteLotConfiguration() {
      return this.hasRole('WRITE_LOT_CONFIG_ADMIN');
    }
  },
  watch: {
    lots(newLots, oldLots) {
      if (!oldLots.map(l => l.id).every(id => newLots.map(l => l.id).includes(id))) {
        this.lot = undefined;
      }
    },
    kitVersion(newVersion, oldVersion) {
      if (isNil(newVersion)) {
        this.lots = [];
      } else if (isNil(oldVersion) || newVersion.id !== oldVersion.id) {
        this.changePage(1);
      }
    }
  },
  methods: {
    changeKit(kit) {
      this.changeKitVersion(null);
      this.search = null;
      this.changePage(1);
      this.kit = kit;
    },
    changeKitVersion(kitVersion) {
      this.kitVersion = kitVersion;
      this.changeLot(undefined);
    },
    changeLot(lot) {
      this.lot = lot;
      this.baseLot = cloneDeep(lot);
    },
    save() {
      // terminate the function
      // if an async request is processing
      if (this.processing) {
        return;
      }
      this.processing = true;
      saveLot(this.kitVersion.id, this.lot)
          .then(() => {
            this.lot = undefined;
            this.changePage(1);
            this.showSuccessNotification(this.$t('formConfigureLot'), this.$t('successTitleConfigureLot'));
          })
          .catch(error => {
            this.showErrorNotification(this.$t('errorTitleConfigureLot'), error);
          })
          .finally(() => this.processing = false);
    },
    cancel() {
      this.lot = cloneDeep(this.baseLot);
    },
    isLotExpired(lot) {
      return this.now > lot?.expirationDate;
    },
    onSelectLot(lot) {
      if (this.isCreatingLot) {
        this.isCreatingLot = false;

        return;
      }
      this.changeLot(cloneDeep(lot));
    },
    addLot() {
      this.isCreatingLot = true;
      this.changeLot(cloneDeep(new Lot(null, null, null, true, false, false)));
    },
    changePage(page) {
      this.currentPage = cloneDeep(page);
      const pageObject = {
        // backend api use zero-indexed pagination
        index: page - 1,
        size: this.itemsPerPage,
        sort: []
      };
      if(!this.kitVersion) {
        this.lots = [];
        return ;
      }
      findLotsByVersion(this.kitVersion.id, pageObject, this.search)
        .then(page => {
          this.lots = page.content.map(lot => Lot.parse(lot));
          this.itemsPerPage = page.size;
          this.totalElements = page.totalElements;
        })
        .catch(error => this.showErrorNotification(this.$t('errorTitleFetchLot'), error));
    },
    setClass() {
      return (item, type) => {
        if (!item || !type) {
          return;
        }
        let style = '';
        if (!item.active) {
          style += 'lot-inactive ';
        }
        if (this.isLotExpired(item)) {
          style += 'lot-expired';
        }

        return style;
      };
    }
  }
};
</script>

