<template>
  <b-row>
    <b-col
      class="px-1"
      sm="4">
      <app-kit-editable-multiselect
        :value="selectedKit"
        :overriding-options="kits"
        :placeholder="$t('runInterpretation.wellInterpretation.wellSelectionFilters.kitDropdownPlaceholder')"
        :show-labels="false"
        :preselect-single-overriding-option="true"
        @select="setKit"
        @remove="removeKit" />
    </b-col>
    <b-col
      class="px-1"
      sm="4">
      <app-kit-version-dropdown
        :value="selectedKitVersion"
        :overriding-options="kitVersions"
        :placeholder="$t('runInterpretation.wellInterpretation.wellSelectionFilters.kitVersionDropdownPlaceholder')"
        :show-labels="false"
        :preselect-single-overriding-option="true"
        @select="setKitVersion" />
    </b-col>
    <b-col
      class="px-1"
      sm="4">
      <app-reaction-buffer-dropdown
        :value="selectedReactionBuffer"
        :allow-empty="false"
        :overriding-options="reactionBuffers"
        :placeholder="$t('runInterpretation.wellInterpretation.wellSelectionFilters.reactionBufferDropdownPlaceholder')"
        :show-labels="false"
        :preselect-single-overriding-option="true"
        @select="setReactionBuffer" />
    </b-col>
  </b-row>
</template>

<script>
import intersectionBy from 'lodash/intersectionBy';
import uniqBy from 'lodash/uniqBy';

import KitEditableMultiselect from '../../../../common/KitEditableMultiselect.vue';
import ReactionBufferDropdown from '../../../../common/ReactionBufferDropdown';
import KitReactionBufferPair from '@/models/KitReactionBufferPair';
import KitVersionDropdown from '../../../../common/KitVersionDropdown.vue';

export default {
  components: {
    'app-kit-version-dropdown': KitVersionDropdown,
    'app-kit-editable-multiselect': KitEditableMultiselect,
    'app-reaction-buffer-dropdown': ReactionBufferDropdown
  },
  props: {
    wells: {
      type: Array,
      default: () => []
    },
    allKitVersions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedKitVersion: undefined,
      selectedReactionBuffer: undefined,
      selectedKit: undefined
    };
  },
  computed: {
    kitVersions() {
      const kitVersions = uniqBy(this.wells.filter(well => well.kitVersion).map(well => well.kitVersion), 'id');

      return this.allKitVersions.filter(kitVersion => this.selectedKit?.id === kitVersion.kit.id
                                                      && kitVersions.map(kitVersionWell => kitVersionWell.id)
                                                        .includes(kitVersion.id));
    },
    reactionBuffers() {
      const wellsReactionBuffers = uniqBy(this.wells.map(well => well.reactionBuffer), 'id');

      return this.selectedKitVersion?.kit ? intersectionBy(this.selectedKitVersion.kit.reactionBuffers, wellsReactionBuffers, 'id').sort() : [];
    },
    kits() {
      return uniqBy(this.wells.filter(well => well.kitVersion).map(well => well.kitVersion.kit), 'id');
    },
    filteredWells() {
      let filteredWells = [];

      if (this.selectedKitVersion && this.selectedReactionBuffer) {
        filteredWells = this.wells.filter(well => well.kitVersion?.id === this.selectedKitVersion.id && well.reactionBuffer?.id === this.selectedReactionBuffer.id);
      }

      return filteredWells;
    }
  },
  watch: {
    kitVersions(kitVersions) {
      if (kitVersions.length === 1) {
        this.selectedKitVersion = kitVersions[0];
      } else {
        this.selectedKitVersion = undefined;
      }
    },
    reactionBuffers(reactionBuffers) {
      if (reactionBuffers.length === 1) {
        this.selectedReactionBuffer = reactionBuffers[0];
      } else {
        this.selectedReactionBuffer = undefined;
      }
    },
    kits(kits) {
      if (kits.length === 1) {
        this.selectedKit = kits[0];
      } else {
        this.selectedKit = undefined;
      }
    },
    filteredWells(filteredWells) {
      this.$emit('filteredWellsUpdate', filteredWells);
    }
  },
  mounted() {
    if (this.kits.length === 1) {
      this.selectedKit = this.kits[0];
    }
    if (this.kitVersions.length === 1) {
      this.selectedKitVersion = this.kitVersions[0];
    }
    if (this.reactionBuffers.length === 1) {
      this.selectedReactionBuffer = this.reactionBuffers[0];
    }
    this.emitInputEvent();
  },
  methods: {
    setKit(kit) {
      this.selectedKit = kit;
      this.emitInputEvent();
    },
    setKitVersion(kitVersion) {
      this.selectedKitVersion = kitVersion;
      this.emitInputEvent();
    },
    setReactionBuffer(reactionBuffer) {
      this.selectedReactionBuffer = reactionBuffer;
      this.emitInputEvent();
    },
    removeKit() {
      this.selectedReactionBuffer = undefined;
      this.selectedKitVersion = undefined;
      this.selectedKit = undefined;
    },
    emitInputEvent() {
      const kitReactionBuffers = [];

      // Send all kitVersions linked to the selected lot
      if (this.selectedKit && !this.selectedKitVersion) {
        this.kitVersions.forEach(kv => {
          kitReactionBuffers.push(KitReactionBufferPair.build({
            kitVersion: kv,
            reactionBuffer: null
          }));
        });
      }
      // Send the selected kitVersion
      else if (this.selectedKitVersion) {
        kitReactionBuffers.push(KitReactionBufferPair.build({
          kitVersion: this.selectedKitVersion,
          reactionBuffer: this.selectedReactionBuffer
        }));
      }

      this.$emit('input', kitReactionBuffers);
    }
  }
};
</script>
