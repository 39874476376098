import { getField, updateField } from 'vuex-map-fields';
import { reindexData } from '@/service/GenefoxService';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import Phase from '@/models/Phase';
import { DEFAULT_SLOPE_THRESHOLD } from '../../models/Phase';

const state = {
  kitsWithLotToAdd: [],
  kitsWithLotToDelete: [],
  kitVersion: undefined,
  kit: undefined,
  reactionBuffer: undefined,
  analyser: undefined,
  channel: {},
  phases: [ Phase.empty() ],
  isKitVersionModifiable: false,
  baseLot: undefined
};

const getters = {
  getField
};

export const mutations = {
  updateField
};

export const actions = {
  reindexData() {
    return new Promise((resolve, reject) => {
      reindexData()
        .then(_ => resolve())
        .catch(_ => reject());
    });
  },
  resetKitData({ commit }) {
    commit('updateField', { path: 'kitVersion', value: undefined });
    commit('updateField', { path: 'kit', value: undefined });
    commit('updateField', { path: 'reactionBuffer', value: undefined });
    commit('updateField', { path: 'analyser', value: undefined });
    commit('updateField', { path: 'channel', value: {} });
    commit('updateField', { path: 'phases', value: [ { uuid: uuidv4(), id: null, phaseType: null, minThreshold: null, slopeThreshold: DEFAULT_SLOPE_THRESHOLD, minQuality: null, zones: [ { uuid: uuidv4(), name: null, minThreshold: null, minQuality: null, minTemp: null, maxTemp: null, label: null, dataEnum: null } ] } ] });
  },
  setKitVersion({ commit }, { kitVersion }) {
    commit('updateField', { path: 'kitVersion', value: kitVersion });
    commit('updateField', { path: 'kit', value: kitVersion.kit });
  },
  addReactionBufferToKit({ commit }, { reactionBuffer }) {
    const kit = cloneDeep(state.kit);
    if (!kit.reactionBuffers) {
      kit.reactionBuffers = [];
    }
    kit.reactionBuffers.push(reactionBuffer);
    commit('updateField', { path: 'kit', value: kit });
    commit('updateField', { path: 'reactionBuffer', value: reactionBuffer });
  },
  setReactionBuffer({ commit }, { reactionBuffer }) {
    commit('updateField', { path: 'reactionBuffer', value: reactionBuffer });
  },
  setChannel({ commit }, { channel }) {
    commit('updateField', { path: 'channel', value: channel });
  },
  setChannelPhases({ commit }, { phases }) {
    const channel = cloneDeep(state.channel);
    channel.phases = phases;
    commit('updateField', { path: 'channel', value: channel });
  },
  setPhases({ commit }, { phases }) {
    commit('updateField', { path: 'phases', value: phases });
  },
  addNewPhase({ commit }, { filteredPhaseTypes }) {
    const phases = cloneDeep(state.phases);
    phases.push({ uuid: uuidv4(), id: null, phaseType: filteredPhaseTypes.length === 1 ? filteredPhaseTypes[0] : null, minThreshold: null, slopeThreshold: DEFAULT_SLOPE_THRESHOLD, minQuality: null, inversed: false, zones: [ { uuid: uuidv4(), name: null, minThreshold: null, minQuality: null, minTemp: null, maxTemp: null, label: null, dataEnum: null } ] });
    commit('updateField', { path: 'phases', value: phases });
  },
  removePhase({ commit }, { uuid }) {
    const phases = cloneDeep(state.phases);
    const index = phases.findIndex(row => row.uuid === uuid);
    phases.splice(index, 1);
    commit('updateField', { path: 'phases', value: phases });
  },
  setPhaseType({ commit }, { uuid, phaseType, analyser }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === uuid);
    phase.phaseType = phaseType;
    phase.analyser = analyser;
    commit('updateField', { path: 'phases', value: phases });
  },
  setPhaseMinThreshold({ commit }, { uuid, minThreshold }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === uuid);
    phase.minThreshold = minThreshold;
    commit('updateField', { path: 'phases', value: phases });
  },
  setPhaseSlopeThreshold({ commit }, { uuid, slopeThreshold }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === uuid);
    phase.slopeThreshold = slopeThreshold;
    commit('updateField', { path: 'phases', value: phases });
  },
  setPhaseInversed({ commit }, { uuid, inversed }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === uuid);
    phase.inversed = inversed;
    commit('updateField', { path: 'phases', value: phases });
  },
  addNewZone({ commit }, { phaseUuid }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === phaseUuid);
    phase.zones.push({ uuid: uuidv4(), name: null, minThreshold: null, minQuality: null, minTemp: null, maxTemp: null, label: null, dataEnum: null });
    commit('updateField', { path: 'phases', value: phases });
  },
  removeZone({ commit }, { phaseUuid, zoneUuid }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === phaseUuid);
    const index = phase.zones.findIndex(row => row.uuid === zoneUuid);
    phase.zones.splice(index, 1);
    commit('updateField', { path: 'phases', value: phases });
  },
  setZoneName({ commit }, { phaseUuid, zoneUuid, name }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === phaseUuid);
    const zone = phase.zones.find(row => row.uuid === zoneUuid);
    zone.name = name;
    commit('updateField', { path: 'phases', value: phases });
  },
  setZoneMinTemp({ commit }, { phaseUuid, zoneUuid, minTemp }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === phaseUuid);
    const zone = phase.zones.find(row => row.uuid === zoneUuid);
    zone.minTemp = minTemp;
    commit('updateField', { path: 'phases', value: phases });
  },
  setZoneMaxTemp({ commit }, { phaseUuid, zoneUuid, maxTemp }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === phaseUuid);
    const zone = phase.zones.find(row => row.uuid === zoneUuid);
    zone.maxTemp = maxTemp;
    commit('updateField', { path: 'phases', value: phases });
  },
  setZoneLabel({ commit }, { phaseUuid, zoneUuid, label }) {
    const phases = cloneDeep(state.phases);
    const phase = phases.find(p => p.uuid === phaseUuid);
    const zone = phase.zones.find(row => row.uuid === zoneUuid);
    zone.label = label;
    commit('updateField', { path: 'phases', value: phases });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
