<template>
  <editable-multiselect
    ref="multiselect"
    v-model="model"
    :options="options"
    :custom-label="customLabel"
    :placeholder="multiselectPlaceholder"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :show-labels="showLabels"
    :class="cssClasses"
    :disabled="disabled"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)">
    <template
      v-if="displayBadge"
      #singleLabel="props">
      <app-reaction-buffer
        :reaction-buffer="props.option"
        :name="customLabel(props.option)"
        :is-reaction-buffer-configured="props.option.configured" />
    </template>
    <template
      v-if="displayBadge"
      #option="props">
      <app-reaction-buffer
        :reaction-buffer="props.option"
        :name="customLabel(props.option)"
        :is-reaction-buffer-configured="props.option.configured" />
    </template>
    <template
      v-if="addable"
      slot="afterList">
      <span>
        <b-input
          v-model="rbName"
          class="col-lg-10 dropdown-add-input"
          @mousedown.self.stop />
        <b-button
          ref="addButton"
          variant="outline"
          class="btn--no-outline btn-icon btn-icon-plus dropdown-add-button"
          :disabled="!rbName"
          @click="addRb">
          <font-awesome-icon
            :icon="['fas', 'plus-circle']" />
        </b-button>
      </span>
    </template>
  </editable-multiselect>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import SecurityMixin from '@/mixins/SecurityMixin';
import EditableMultiselect from '@/components/common/EditableMultiselect';
import ReactionBufferComponent from './ReactionBuffer.vue';

export default {
  components: {
    'editable-multiselect': EditableMultiselect,
    'app-reaction-buffer' : ReactionBufferComponent
  },
  mixins: [
    DropdownMixin,
    SecurityMixin
  ],
  props: {
    value: {
      type: Object,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    reactionBufferConfigured: {
      type: Array,
      default: () => []
    },
    displayBadge: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rbName: null
    };
  },
  computed: {
    canWriteKitConfig() {
      return this.hasRole('WRITE_KIT_CONFIG_ADMIN');
    },
    multiselectPlaceholder() {
      return this.placeholder !== undefined ? this.placeholder : this.$t('formSelectReactionBuffer');
    },
    addable() {
      return this.canAdd && this.canWriteKitConfig && !this.options.find(rb => !rb.id);
    }
  },
  methods: {
    customLabel(value) {
      return value.name;
    },
    addRb() {
      if (this.options.find(o => o.name === this.rbName)) {
        this.showErrorNotification(this.$t('RbDropdownAddRbErrorTitle'), this.$t('RbDropdownAddRbErrorMessage'));

        return;
      }
      this.$emit('addRb', this.rbName);
      this.rbName = null;
      this.$refs.multiselect.deactivate();
    }
  }
};
</script>
