<template>
  <div>
    <b-link
      class="align-middle"
      :disabled="disabled"
      @click="openCancelModal">
      {{ $t('cancel') }}
    </b-link>
    <app-confirmation-modal
      ref="cancelModal"
      :title="$t('modalDiscardChangesTitle')"
      :message="$t('modalDiscardChangesMessage')"
      :ok-button-name="$t('yes')"
      :cancel-button-name="$t('no')"
      ok-button-prevent-hiding
      ok-button-spinners-enabled
      @ok="cancel" />
  </div>
</template>

<script>
import ConfirmationModal from '../../common/ConfirmationModal';
import { mapFields } from 'vuex-map-fields';
import { deleteRun } from '../../../service/GenefoxService';
import NotificationMixin from '@/mixins/NotificationMixin';

export default {
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapFields('runImport', [
      'run'
    ])
  },
  methods: {
    openCancelModal() {
      this.$refs.cancelModal.showModal();
    },
    closeCancelModal() {
      this.$refs.cancelModal.hideModal();
    },
    cancel() {
      // If run has not been interpreted, delete run before redirecting to home page
      if (this.run.id && !this.run.interpretationRunByName) {
        deleteRun(this.run.id)
          .then(() => {
            this.$router.push({ name: 'run-list' });
          })
          .catch(error => {
            this.closeCancelModal();
            this.showErrorNotification(this.$t('runInterpretation.deleteRun.errorNotification.title'), error);
          });
      }
      else {
        this.$router.push({ name: 'run-list' });
      }
    }
  }
};
</script>
