import Channel from "@/models/Channel";
import Combination from "@/models/Combination";
import DiagnosticColor from "@/models/DiagnosticColor";
import Expression from "@/models/Expression";

export default class CombinationDiagnostic {
  constructor(id, channel, combination, diagnosticColor, expressions) {
    this.id = id;
    this.channel = channel;
    this.combination = combination;
    this.diagnosticColor = diagnosticColor;
    this.expressions = expressions;
  }

  static build({ id, channel, combination, diagnosticColor, expressions }) {
    return new CombinationDiagnostic(id, channel, combination, diagnosticColor, expressions);
  }

  static parse(value) {
    return value ? new CombinationDiagnostic(
      value.id,
      Channel.parse(value.channel),
      Combination.parse(value.combination),
      DiagnosticColor.parse(value.diagnosticColor),
      value.expressions?.map(e => Expression.parse(e))
    ) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      channel: this.channel  !== undefined || this.channel !== '' ? this.channel : null,
      combination: this.combination  !== undefined || this.combination !== '' ? this.combination : null,
      diagnosticColor: this.diagnosticColor  !== undefined || this.diagnosticColor !== '' ? this.diagnosticColor : null,
      expressions: this.expressions  !== undefined || this.expressions !== '' ? this.expressions : null
    };
  }
}
