<template>
  <multiselect
    v-model="model"
    :options="options"
    :placeholder="$t('sampleTypeDropdownPlaceholder')"
    :custom-label="customLabel"
    :show-labels="showLabels" />
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import { findAllSampleTypes } from '../../service/GenefoxService';

export default {
  mixins: [
    DropdownMixin
  ],
  computed: {
    errorNotificationTitle() {
      return this.$t('sampleTypeDropdownLoadingErrorTitle');
    }
  },
  methods: {
    findOptions() {
      return findAllSampleTypes();
    },
    customLabel (value) {
      return this.$options.filters.capitalize(this.$t(value));
    }
  }
};
</script>
