import InterpretedWellDiagnostic from './InterpretedWellDiagnostic';
import CharData from './CharData';
import ConstantPhaseInfo from "@/models/ConstantPhaseInfo";

export default class InterpretedWell {
  constructor(wellId, position, sampleType, interpretedWellDiagnostics, data, constantPhaseInfos) {
    this.wellId = wellId;
    this.position = position;
    this.sampleType = sampleType;
    this.interpretedWellDiagnostics = interpretedWellDiagnostics;
    this.data = data;
    this.constantPhaseInfos = constantPhaseInfos;
  }

  static build({ wellId, position, sampleType, interpretedWellDiagnostics, data, constantPhaseInfos }) {
    return new InterpretedWell(wellId, position, sampleType, interpretedWellDiagnostics, data, constantPhaseInfos);
  }

  static parse(value) {
    return value ? InterpretedWell.build({
      wellId: value.well.id,
      position: value.position,
      sampleType: value.sampleType,
      interpretedWellDiagnostics: value.interpretedWellDiagnostics?.map(iwd => InterpretedWellDiagnostic.parse(iwd)),
      data: value.data?.map(d => CharData.parse(d)),
      constantPhaseInfos: value.constantPhaseInfos?.map(cpi => ConstantPhaseInfo.parse(cpi))
    }) : null;
  }
}
