<template>
  <app-confirmation-modal
    ref="runStatusUpdateModal"
    :title="title"
    :message="message"
    :ok-button-name="$t('yes')"
    ok-button-prevent-hiding
    ok-button-spinners-enabled
    :cancel-button-name="$t('no')"
    @ok="$emit('ok')" />
</template>

<script>
import NotificationMixin from '../../../../mixins/NotificationMixin';
import ConfirmationModal from '../../../common/ConfirmationModal';

export default {
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin
  ],
  props: {
    title: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      default: undefined
    }
  },
  methods: {
    showModal() {
      this.$refs.runStatusUpdateModal.showModal();
    },
    hideModal() {
      this.$refs.runStatusUpdateModal.hideModal();
    }
  }
};
</script>
