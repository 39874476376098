<template>
  <span>
    <span class="mr-1">{{ label }}</span>
    <b-badge
      v-if="status"
      pill
      :variant="badge"
      class="badge-outlined">{{ $t(status) }}</b-badge>
  </span>
</template>

<script>
import KitVersion from '@/models/KitVersion';

export default {
  props: {
    kit: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      statuses: {
        VALIDATED: { badge: 'success' },
        NOT_VALIDATED: { badge: 'info' },
        PERF: { badge: 'danger' },
        default: { badge: 'secondary' }
      }
    };
  },
  computed: {
    label() {
      // manage kit version
      if (this.kit instanceof KitVersion) {
        return this.kit.kit.id ? this.kit.kit.name : this.$t(this.kit.kit.name);
      }

      return this.kit.id ? this.kit.name : this.$t(this.kit.name);
    },
    status() {
      if (this.kit instanceof KitVersion) {
        return this.kit.kit.status;
      }

      return this.kit.status;
    },
    badge() {
      let status;
      if (this.kit instanceof KitVersion) {
        status = this.statuses[this.kit.kit.status];
      } else {
        status = this.statuses[this.kit.status];
      }

      if (!status) {
        return this.statuses.default.badge;
      }

      return status.badge;
    }
  }
};
</script>