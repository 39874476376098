<template>
  <b-container class="diagnostic-update-form-container">
    <p class="text-center mb-4">
      {{ $t('diagnosticUpdateFormIntroductionParagraph', [well.position]) }}
    </p>
    <b-form-group>
      <app-diagnostic-dropdown
        v-model="selectedDiagnostic"
        :overriding-options="rbChannelDiagnosticColors"
        :sample-type="well.sampleType"
        :translated="true" />
    </b-form-group>
    <b-form-row class="mt-4">
      <b-col sm="12">
        <div class="d-flex flex-row align-items-center justify-content-around">
          <b-link
            class="fa-pull-left align-middle"
            @click="$emit('cancel')">
            {{ $t('cancel') }}
          </b-link>
          <b-button
            :disabled="!isModificationAllowed"
            @click="updateDiagnostic">
            {{ $t('diagnosticUpdateFormValidationButtonText') }}
          </b-button>
        </div>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import DiagnosticDropdown from '../../../../common/DiagnosticDropdown';
import Well from '../../../../../models/Well';
import { mapActions, mapGetters } from 'vuex';
import NotificationMixin from '../../../../../mixins/NotificationMixin';
import {mapFields} from "vuex-map-fields";

export default {
  components: {
    'app-diagnostic-dropdown': DiagnosticDropdown
  },
  mixins: [
    NotificationMixin
  ],
  props: {
    runId: {
      type: Number,
      required: true
    },
    well: {
      type: Well,
      required: true
    }
  },
  data() {
    return {
      initialDiagnostic: undefined,
      selectedDiagnostic: undefined
    };
  },
  computed: {
    ...mapGetters('chart', [
      'getDiagnosticByWellId'
    ]),
    ...mapFields('runImport', [
      'rbChannelDiagnosticColors'
    ]),
    isModificationAllowed() {
      return this.initialDiagnostic !== this.selectedDiagnostic;
    }
  },
  watch: {
    well(newValue) {
      if (newValue) {
        this.initDiagnostic(newValue);
      }
    }
  },
  created() {
    this.initDiagnostic(this.well);
  },
  methods: {
    ...mapActions('chart', [
      'updateWellDiagnostic'
    ]),
    initDiagnostic(well) {
      const diagnosticColor = this.getDiagnosticByWellId(well.id);
      this.initialDiagnostic = diagnosticColor;
      this.selectedDiagnostic = diagnosticColor;
      this.$store.dispatch('runImport/getRbChannelDiagnosticColors', {kitId: well.kitVersion.kit.id, kitVersionId: well.kitVersion.id, rbId: well.reactionBuffer.id, wellId: well.id, currentDiagnostic: diagnosticColor});
    },
    updateDiagnostic() {
      this.updateWellDiagnostic({ runId: this.runId, wellId: this.well.id, diagnosticColor: this.selectedDiagnostic })
        .then(interpretedWell => this.$emit('updated', interpretedWell))
        .catch(error => this.showErrorNotification(this.$t('diagnosticUpdateFormUpdateErrorNotificationTitle') , error));
    }
  }
};
</script>

<style scoped>
.diagnostic-update-form-container {
  padding-top: 4em;
}
</style>
