<template>
  <div>
    <b-form-row>
      <b-card
        header-bg-variant="white"
        class="w-100">
        <template #header>
          <h5 class="my-0 text-center">
            {{ $t('lotDetails') }}
          </h5>
        </template>
        <b-card-body>
          <b-form-row class="justify-content-center">
            <b-col lg="2">
              <b-form-group
                :label="$t('formLotActive')">
                <b-form-checkbox
                  v-model="lot.active"
                  :disabled="disabled"/>
              </b-form-group>
            </b-col>
            <b-col lg="5">
              <b-form-group :label="$t('formLot')">
                <b-form-input
                  ref="lotInput"
                  v-model="lot.name"
                  :state="validateState('lot.name')"
                  :disabled="!!lot.id || disabled" />
                <b-form-invalid-feedback>
                  {{ $t('formLotNameError') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="5">
              <b-form-group :label="$t('formLotExpirationDate')"
              :disabled="disabled">
                <date-picker
                  v-model="lot.expirationDate"
                  format="DD-MM-YYYY"
                  :model-config="modelConfig"
                  timezone="UTC"
                  :disabled-dates="disabled">
                  <template #default="{ inputValue, inputEvents }">
                    <b-form-group
                      :state="validateState('lot.expirationDate')"
                      :invalid-feedback="$t('formDateExpirationError')">
                      <input
                        :class="`form-control ${validationClass}`"
                        :value="inputValue"
                        v-on="inputEvents">
                    </b-form-group>
                  </template>
                </date-picker>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-card-body>
        <b-row align-h="between">
          <b-button
            ref="cancelButton"
            :disabled="disabled"
            @click="cancel">
            {{ $t('cancel') }}
          </b-button>
          <b-button
            ref="saveButton"
            :disabled="saveDisable"
            @click="save">
            {{ $t('save') }}
          </b-button>
        </b-row>
      </b-card>
    </b-form-row>
  </div>
</template>

<script>
import Lot from '../../../../models/Lot';
import {afterDate} from '../../../validation/afterDate';
import ValidationMixin from '../../../../mixins/ValidationMixin';
import NotificationMixin from '../../../../mixins/NotificationMixin';
import {isNil} from 'lodash';
import {mapFields} from 'vuex-map-fields';
import {required} from 'vuelidate/lib/validators';

export default {
  mixins: [
    ValidationMixin,
    NotificationMixin
  ],
  props: {
    lot: {
      type: Object,
      default: new Lot()
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      now: new Date(),
      modelConfig: {
        type: Date(),
        timeAdjust: '00:00:00'
      },
      expirationDate: undefined
    };
  },
  computed: {
    ...mapFields('configuration', [
      'baseLot'
    ]),
    saveDisable() {
      return !this.hasLotChanged();
    },
    validationClass() {
      return isNil(this.validateState('lot.expirationDate')) ? '' : this.validateState('lot.expirationDate') ? 'is-valid' : 'is-invalid';
    }
  },
  watch: {
    lot: {
      handler(newLot, oldLot){
        if (!newLot || newLot.id === oldLot?.id) {
          return ;
        }
        this.expirationDate = this.lot?.expirationDate;
        this.resetValidation();
      },
      immediate: true
    }
  },
  validations() {
    return {
      lot: {
        expirationDate: {
          afterNow: value => isNil(value) ? false : new Date(value).getTime() !== new Date(this.expirationDate).getTime() ? afterDate(this.now)(value) : true
        },
        name: { required }
      }
    };
  },
  methods: {
    save() {
      if (!this.isValid()) {
        this.showErrorNotification(this.$t('configuration.lot.save.notification.title'), this.$t('configuration.lot.save.notification.invalid_message'));

        return;
      }
      this.$emit('lotSaved');
    },
    cancel() {
      this.resetValidation();
      this.$emit('cancel');
    },
    hasLotChanged() {
      if (!this.lot?.id) {
        return this.lot.name !== this.baseLot.name && this.lot.expirationDate?.getTime() !== this.baseLot.expirationDate?.getTime();
      }

      return this.lot.id === this.baseLot.id
          && (this.lot.name !== this.baseLot.name
              || this.lot.active !== this.baseLot.active
              || (!this.lot.expirationDate || this.lot.expirationDate.getTime() !== this.baseLot.expirationDate.getTime()));
    },
  }
};

</script>