<template>
  <editable-multiselect
    ref="multiselect"
    v-model="model"
    :options="options"
    :placeholder="multiselectPlaceholder"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :show-labels="showLabels"
    :class="cssClasses"
    :disabled="disabled"
    :multiple="multiple"
    track-by="id"
    label="name"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)">
    <template #singleLabel="{ option }">
      <app-kit-option
        v-if="option && option.id"
        :kit="option" />
      <template v-else>
        {{ option.name }}
      </template>
    </template>
    <template #option="{ option }">
      <app-kit-option
        v-if="option && option.id"
        :kit="option" />
      <template v-else>
        {{ option.name }}
      </template>
    </template>
    <template
      v-if="addable"
      #afterList>
      <span>
        <b-input
          v-model="kitName"
          class="col-lg-10 dropdown-add-input"
          @mousedown.self.stop />
        <b-button
          ref="addButton"
          variant="outline"
          class="btn--no-outline btn-icon btn-icon-plus dropdown-add-button"
          :disabled="!kitName"
          @click="addKit">
          <font-awesome-icon
            :icon="['fas', 'plus-circle']" />
        </b-button>
      </span>
    </template>
  </editable-multiselect>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import {findAllKits, findAllKitsByAnalyserId} from '@/service/GenefoxService';
import EditableMultiselect from '@/components/common/EditableMultiselect';
import KitOption from '@/components/common/KitOption';
import SecurityMixin from '@/mixins/SecurityMixin';
import Kit from '@/models/Kit';

export default {
  components: {
    'editable-multiselect': EditableMultiselect,
    'app-kit-option': KitOption
  },
  mixins: [
    DropdownMixin,
    SecurityMixin
  ],
  props: {
    value: {
      type: Array | Object,
      default: undefined
    },
    analyserId: {
      type: Number,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      kitName: null
    };
  },
  computed: {
    canWriteKitConfig() {
      return this.hasRole('WRITE_KIT_CONFIG_ADMIN');
    },
    errorNotificationTitle() {
      return this.$t('kitDropdownLoadingErrorTitle');
    },
    multiselectPlaceholder() {
      return this.placeholder !== undefined ? this.placeholder : this.$t('formSelectKit');
    },
    addable() {
      return this.canAdd && this.canWriteKitConfig && !this.options.find(k => !k.id);
    }
  },
  methods: {
    findOptions() {
      return this.analyserId ? findAllKitsByAnalyserId(this.analyserId) : findAllKits(false, false);
    },
    addKit() {
      if (this.options.find(o => o.name === this.kitName)) {
        this.showErrorNotification(this.$t('KitDropdownAddKitErrorTitle'), this.$t('KitDropdownAddKitErrorMessage'));

        return;
      }
      this.$emit('addKit', this.kitName);
      const kit = new Kit(null, this.kitName, false, false, [], [], [], null);
      this.options.push(kit);
      this.model = kit;
      this.kitName = null;
      this.$refs.multiselect.deactivate();
    }
  }
};
</script>
