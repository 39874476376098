import ConstantZoneInfo from "@/models/ConstantZoneInfo";

export default class ConstantPhaseInfo {
  constructor(id, type, threshold, constantZoneInfos) {
    this.id = id;
    this.type = type;
    this.threshold = threshold;
    this.constantZoneInfos = constantZoneInfos;
  }

  static build({id, type, threshold, constantZoneInfos}) {
    return new ConstantPhaseInfo(id, type, threshold, constantZoneInfos);
  }

  static parse(value) {
    return value ? ConstantPhaseInfo.build({
                                             id: value.id,
                                             type: value.type,
                                             threshold: value.threshold,
                                             constantZoneInfos: value.constantZoneInfos.map(czi => ConstantZoneInfo.parse(czi))
                                           }) : null;
  }
}