<template>
  <div>
    <app-kit-configuration
      ref="appKitConfiguration"
      :disabled="!canWriteKitConfiguration"/>
    <hr>
    <app-phase-configuration
      ref="appPhaseConfiguration"
      @zonesChanged="zonesChanged($event)"
      :disabled="!canWriteKitConfiguration"/>
    <hr>
    <app-combination-configuration
      ref="appCombinationConfiguration"
      :disabled="!canWriteKitConfiguration"/>
    <hr>
    <b-form-row
      class="form-button">
      <b-button
        class="mr-2"
        :disabled="disable() || !canWriteKitConfiguration"
        @click="cancel">
        {{ $t('cancel') }}
      </b-button>
      <b-button
        v-if="!saveRunning"
        :disabled="disable() || !canWriteKitConfiguration"
        @click="save">
        {{ $t('save') }}
      </b-button>
      <b-button
        v-if="saveRunning"
        disabled>
        <b-spinner
          small
          type="grow" />
        Loading...
      </b-button>
      <app-confirmation-modal
        ref="cancelModal"
        :title="$t('modal.kit_configuration.cancel.title')"
        :message="$t('modal.kit_configuration.cancel.message')"
        :ok-button-spinners-enabled="true"
        ok-button-variant="outline-danger"
        header-text-variant="danger"
        @ok="cancelConfirmed" />
    </b-form-row>
  </div>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';
import {createKit, updateKit} from '@/service/GenefoxService';
import PhaseConfiguration from '@/components/configuration/tabs/kit-configuration/PhaseConfiguration';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import KitToSave from '@/models/KitToSave';
import KitConfiguration from '@/components/configuration/tabs/kit-configuration/KitConfiguration';
import CombinationConfiguration from '@/components/configuration/tabs/kit-configuration/CombinationConfiguration';
import {mapFields} from 'vuex-map-fields';
import SecurityMixin from "@/mixins/SecurityMixin";

export default {
  name: 'ConfigurationKit',
  components: {
    'app-kit-configuration': KitConfiguration,
    'app-phase-configuration': PhaseConfiguration,
    'app-combination-configuration': CombinationConfiguration,
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  data() {
    return {
      saveRunning: false
    };
  },
  computed: {
    ...mapFields('configuration', [
      'kitVersion'
    ]),
    canWriteKitConfiguration() {
      return this.hasRole('WRITE_KIT_CONFIG_ADMIN');
    }
  },
  methods: {
    resetData() {
      this.$refs.appKitConfiguration.resetData();
      this.$refs.appPhaseConfiguration.resetData();
      this.$refs.appCombinationConfiguration.resetData();
    },
    disable() {
      if (this.$refs.appKitConfiguration && this.$refs.appKitConfiguration.status !== this.$refs.appKitConfiguration.currentStatus) {
        return false;
      }
      return this.kitVersion?.id ? !this.kitVersion?.isEditable : false;
    },
    cancel() {
      this.$refs.cancelModal.showModal();
    },
    cancelConfirmed() {
      this.$refs.appKitConfiguration.cancel();
    },
    save() {
      this.saveRunning = true;
      if (!this.$refs.appKitConfiguration.kit?.id) {
        this.createKit();
      } else {
        this.updateKit();
      }
    },
    createKit() {
      const kitToSave = this.getKitData();
      createKit(kitToSave)
        .then(kts => {
          this.showSuccessNotification(this.$t('formConfigureKit'), this.$t('successTitleCreateKit'));
          this.kitSaved(kts);
          this.$refs.appKitConfiguration.$refs.appKitEditableMultiselect.initOptions();
        })
        .catch(error => {
          this.showErrorNotification(this.$t('errorTitleCreateKit'), error);
          this.saveRunning = false;
        });
    },
    updateKit() {
      const kitToSave = this.getKitData();
      updateKit(this.$refs.appKitConfiguration.kit.id, kitToSave)
        .then(kitSaved => {
          this.showSuccessNotification(this.$t('formConfigureKit'), this.$t('successTitleUpdateKit'));
          this.kitSaved(kitSaved);
        })
        .catch(error => {
          this.showErrorNotification(this.$t('errorTitleUpdateKit'), error);
          this.saveRunning = false;
        });
    },
    getKitData() {
      return new KitToSave(this.$refs.appKitConfiguration.kitVersion,
        this.$refs.appKitConfiguration.reactionBuffer,
        this.$refs.appKitConfiguration.analyser,
        this.$refs.appKitConfiguration.status,
        this.$refs.appKitConfiguration.getChannels(),
        this.$refs.appCombinationConfiguration.getCombinations());
    },
    kitSaved(kitToSave) {
      this.$refs.appKitConfiguration.kitSaved(kitToSave);
      const currentChannel = kitToSave.channels?.find(c => c.name === this.$refs.appKitConfiguration.channel.name);
      this.$refs.appPhaseConfiguration.initPhases(currentChannel?.phases.filter(p => p.analyser.id === kitToSave.analyser.id && p.kitVersion.id === kitToSave.kitVersion.id));
      this.saveRunning = false;
      this.$refs.appKitConfiguration.findConfiguredAnalyser();
      this.$refs.appKitConfiguration.findRb();
    },
    zonesChanged(zones) {
      this.$refs.appCombinationConfiguration.initCombinations(zones);
      this.$refs.appCombinationConfiguration.findExpressions();
    }
  }
};
</script>