import Comment from './Comment';
import DiagnosticColor from "@/models/DiagnosticColor";

export default class InterpretedWellDiagnostic {
  constructor(id, stateDiagnostic, comment, diagnosticColor) {
    this.id = id;
    this.stateDiagnostic = stateDiagnostic;
    this.diagnosticColor = diagnosticColor;
    this.comment = comment;
  }

  static build({ id, stateDiagnostic, comment, diagnosticColor }) {
    return new InterpretedWellDiagnostic(id, stateDiagnostic, comment, diagnosticColor);
  }

  static parse(value) {
    return value ? InterpretedWellDiagnostic.build({
      id: value.id,
      stateDiagnostic: value.stateDiagnostic,
      diagnosticColor: DiagnosticColor.parse(value.diagnosticColor),
      comment: Comment.parse(value.comment)
    }) : null;
  }
}
