<template>
  <div class="mt-3">
    <h6> {{ $t('captionTitle') }}</h6>
    <b-jumbotron
      bg-variant="transparent"
      border-variant="dark"
      class="init-jumbotron p-3">
      <b-row>
        <b-col>
          <app-well
            forced-sample-type="BLOOD"
            class="align-middle" /> <span class="align-middle">{{ $t('captionBloodWell') }}</span>
          <br>
          <app-well
            forced-sample-type="DNA"
            class="align-middle" /> <span class="align-middle">{{ $t('captionDnaWell') }}</span>
          <br>
          <app-well class="align-middle" /> <span class="align-middle">{{ $t('captionAnalysedWell') }}</span>
        </b-col>
        <b-col>
          <app-well
            forced-sample-type="POSITIVE_TEST"
            class="align-middle" /> <span class="align-middle">{{ $t('captionPositiveTestWell') }}</span>
          <br>
          <app-well
            forced-sample-type="STANDARD"
            class="align-middle" /> <span class="align-middle">{{ $t('captionStandardWell') }}</span>
          <br>
          <app-well
            forced-sample-type="NEGATIVE_TEST"
            class="align-middle" /> <span class="align-middle">{{ $t('captionNegativeTestWell') }}</span>
        </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
import Well from '@/components/run/editor/plate/Well';

export default {
  components: {
    'app-well': Well
  }
};
</script>
