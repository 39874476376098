import Laboratory from '@/models/Laboratory';
import { getField, updateField } from 'vuex-map-fields';
import { GenefoxApi } from '@/service/http-common';
import {
  createLaboratoryUser,
  deleteLaboratory,
  deleteLaboratoryUser,
  updateLaboratory,
  updateLaboratoryUser
} from '@/service/GenefoxService';
import User from '@/models/User';

const state = {
  laboratories: [],
  laboratoryCount: 0,
  laboratoryPerPage: 14,
  laboratory: null,
  laboratoryUsers: [],
  laboratoryUsersCount: 0,
  laboratoryUsersPerPage: 11,
  laboratoryUser: null
};

const getters = {
  getField,
  hasUsers: state => state.laboratoryUsersCount > 0
};

export const mutations = {
  updateField
};

export const actions = {
  findLaboratories({ commit }, { page, search, sort, sortDirection }) {
    if (sortDirection) {
      sort += `,${sortDirection}`;
    }

    return new Promise((resolve, reject) => {
      GenefoxApi.get(`/laboratory${search && search !== '' ? '/search'  : ''}`, { params: { page: page - 1, size: state.laboratoryPerPage, search, sort }, headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
        .then(response => {
          const laboratories = response.data.content.map(laboratory => Laboratory.parse(laboratory));
          commit('updateField', { path: 'laboratoryCount', value: response.data.totalElements });
          commit('updateField', { path: 'laboratories', value: laboratories });
          resolve(laboratories);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  findLaboratory({ dispatch }, name) {
    return new Promise((resolve, reject) => {
      GenefoxApi.get(`/laboratory/${name}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
        .then(response => {
          const laboratory = Laboratory.parse(response.data);
          resolve(dispatch('selectLaboratory', laboratory));
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  selectLaboratory({ commit }, laboratory) {
    const lab = Laboratory.parse(laboratory);

    return new Promise(resolve => {
      commit('updateField', { path: 'laboratory', value: lab });
      commit('updateField', { path: 'laboratoryUsers', value: [] });
      commit('updateField', { path: 'laboratoryUsersCount', value: 0 });
      commit('updateField', { path: 'laboratoryUser', value: null });
      resolve(lab);
    });
  },
  updateLaboratory({ commit }, { id, laboratory }) {
    return new Promise((resolve, reject) => {
      updateLaboratory(id, laboratory)
        .then(lab => {
          commit('updateField', { path: 'laboratory', value: lab });
          resolve(lab);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteLaboratory({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteLaboratory(id)
        .then(() => {
          commit('updateField', { path: 'laboratory', value: null });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  findLaboratoryUsers({ commit }, { page, search }) {
    return new Promise((resolve, reject) => {
      GenefoxApi.get(`/user/laboratory/${state.laboratory.id}`, { params: { page: page - 1, size: state.laboratoryUsersPerPage, search }, headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
        .then(response => {
          const laboratoryUsers = response.data.content.map(user => User.parse(user));
          commit('updateField', { path: 'laboratoryUsersCount', value: response.data.totalElements });
          commit('updateField', { path: 'laboratoryUsers', value: laboratoryUsers });
          resolve(laboratoryUsers);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  selectLaboratoryUser({ commit }, laboratoryUser) {
    const user = User.parse(laboratoryUser);

    return new Promise(resolve => {
      commit('updateField', { path: 'laboratoryUser', value: user });
      resolve(user);
    });
  },
  createLaboratoryUser({ commit }, { laboratoryUser }) {
    return new Promise((resolve, reject) => {
      createLaboratoryUser(state.laboratory.id, laboratoryUser)
        .then(user => {
          commit('updateField', { path: 'laboratoryUser', value: user });
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateLaboratoryUser({ commit }, { id, laboratoryUser }) {
    return new Promise((resolve, reject) => {
      updateLaboratoryUser(state.laboratory.id, id, laboratoryUser)
        .then(user => {
          commit('updateField', { path: 'laboratoryUser', value: user });
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteLaboratoryUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteLaboratoryUser(state.laboratory.id, id)
        .then(() => {
          commit('updateField', { path: 'laboratoryUser', value: null });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setLaboratoriesPerPage({ commit }, laboratoriesPerPage) {
    commit('updateField', { path: 'laboratoryPerPage', value: laboratoriesPerPage });
  },
  setUsersPerPage({ commit }, usersPerPage) {
    commit('updateField', { path: 'laboratoryUsersPerPage', value: usersPerPage });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};