<template>
  <div
    v-if="laboratoryUser"
    class="pl-4 pt-4 pr-4">
    <b-row>
      <b-form-group
        class="col-6 col-l"
        :label="$t('user.login')">
        <b-form-input
          v-model="login"
          :disabled="!!laboratoryUser.id" />
      </b-form-group>
      <b-form-group class="col-3">
        <b-form-checkbox
          v-model="enabled"
          :disabled="isCurrentUser()"
          class="form-checkbox">
          {{ $t('user.enabled') }}
        </b-form-checkbox>
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group
        :label="$t('user.lastname')"
        class="col-6 col-l">
        <b-form-input v-model="lastname" />
      </b-form-group>
      <b-form-group
        :label="$t('user.firstname')"
        class="col-6 col-r">
        <b-form-input v-model="firstname" />
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group
        :label="$t('user.email')"
        class="col-6 col-l">
        <b-form-input v-model="email" />
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group
        v-if="isAdminRolesDisplayed()"
        class="col-3">
        <b-form-checkbox
          v-model="salesAdmin"
          :disabled="!hasRole('SALES_ADMIN') || isCurrentUser() || laboratoryDifferent()">
          {{ $t('user.role.salesAdmin') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="isAdminRolesDisplayed()"
        class="col-3">
        <b-form-checkbox
          v-model="qaAdmin"
          :disabled="!hasRole('QA_ADMIN') || isCurrentUser() || laboratoryDifferent()">
          {{ $t('user.role.qaAdmin') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="isAdminRolesDisplayed()"
        class="col-3">
        <b-form-checkbox
          v-model="rdAdmin"
          :disabled="!hasRole('R&D_ADMIN') || isCurrentUser() || laboratoryDifferent()">
          {{ $t('user.role.rdAdmin') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group :class="isAdminRolesDisplayed() ? 'col-3' : ''">
        <b-form-checkbox
          :checked="isBoxPhysicianChecked"
          :disabled="isCurrentUser() || isBoxPhysicianDisabled"
          @change="updatePhysician($event)">
          {{ $t('user.role.physician') }}
        </b-form-checkbox>
      </b-form-group>
    </b-row>
    <b-row
      class="align-end">
      <b-button
        ref="deleteButton"
        variant="outline-danger"
        class="mt-4 mr-2 delete-button"
        :disabled="!laboratoryUser.id || (!hasRole('WRITE_ALL_USER') && !hasRole('WRITE_OWN_LABORATORY_USER')) || isCurrentUser()"
        @click="showLaboratoryUserDeletionModal">
        {{ $t('common.delete') }}
      </b-button>
      <b-button
        ref="saveButton"
        class="mt-4"
        @click="save">
        {{ $t('save') }}
      </b-button>
      <app-confirmation-modal
        ref="laboratoryUserDeletionModal"
        :title="$t('user.delete.title')"
        :message="$t('user.delete.message')"
        :ok-button-name="$t('common.yes')"
        ok-button-prevent-hiding
        ok-button-spinners-enabled
        ok-button-variant="outline-danger"
        :cancel-button-name="$t('common.no')"
        header-text-variant="danger"
        @ok="deleteSelectedLaboratoryUser" />
    </b-row>
  </div>
</template>

<script>
import ConfirmationModal from '@/components/common/ConfirmationModal';
import NotificationMixin from '@/mixins/NotificationMixin';
import User from '@/models/User';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import SecurityMixin from '@/mixins/SecurityMixin';

export default {
  name: 'LaboratoryUser',
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  computed: {
    ...mapFields('laboratory', [
      'laboratory',
      'laboratoryUser',
      'laboratoryUser.id',
      'laboratoryUser.login',
      'laboratoryUser.lastname',
      'laboratoryUser.firstname',
      'laboratoryUser.email',
      'laboratoryUser.emailVerified',
      'laboratoryUser.enabled',
      'laboratoryUser.salesAdmin',
      'laboratoryUser.qaAdmin',
      'laboratoryUser.rdAdmin',
      'laboratoryUser.physician'
    ]),
    isBoxPhysicianDisabled() {
      return this.salesAdmin || this.qaAdmin || this.rdAdmin;
    },
    isBoxPhysicianChecked() {
      return this.salesAdmin || this.qaAdmin || this.rdAdmin || this.physician;
    }
  },
  methods: {
    ...mapActions('laboratory', [
      'createLaboratoryUser',
      'updateLaboratoryUser',
      'deleteLaboratoryUser'
    ]),
    showLaboratoryUserDeletionModal() {
      this.$refs.laboratoryUserDeletionModal.showModal();
    },
    save() {
      let promise;
      if (!this.laboratoryUser.id) {
        promise = this.createLaboratoryUser({ id: this.laboratoryUser.id, laboratoryUser: User.parse(this.laboratoryUser) });
      } else {
        promise = this.updateLaboratoryUser({ id: this.laboratoryUser.id, laboratoryUser: User.parse(this.laboratoryUser) });
      }
      promise
        .then(() => {
          this.showSuccessNotification(this.$t('user.update.notification.title'), this.$t('user.update.notification.success_message'));
          this.$emit('updated');
        })
        .catch(error => {
          this.showErrorNotification(this.$t('user.update.notification.title'), error);
        });
    },
    deleteSelectedLaboratoryUser() {
      this.deleteLaboratoryUser(this.laboratoryUser.id)
        .then(() => {
          this.showSuccessNotification(this.$t('user.delete.notification.title'), this.$t('user.delete.notification.success_message'));
          this.$emit('deleted');
        })
        .catch(error => {
          this.showErrorNotification(this.$t('user.delete.notification.title'), error);
        });
    },
    isCurrentUser() {
      return this.userName() === this.login;
    },
    laboratoryDifferent() {
      return this.laboratory.id !== this.laboratoryName();
    },
    isAdminRolesDisplayed() {
      return this.hasRole('SALES_ADMIN') || this.hasRole('QA_ADMIN') || this.hasRole('R&D_ADMIN');
    },
    updatePhysician(event) {
      this.physician = event;
    }
  }
};
</script>