<template>
  <div :class="`h-100 pageable-listing`">
    <div :class="`table-tooltip ${disableTable ? '' : 'd-none'}`">
      <span>{{ disableMessageTooltip }}</span>
    </div>
    <div class="p-0 border-right h-100">
      <b-form-input
        ref="searchInput"
        class="form-control"
        type="text"
        :placeholder="$t('pageable.listing.search') | capitalize"
        :value="search"
        :disabled="disableTable"
        @input="updateSearch" />
      <b-table
        ref="pageableListing"
        responsive
        hover
        show-empty
        selectable
        :class="`${disableTable ? 'listing-items-disable' : ''}`"
        :thead-class="`${displayHeader ? '' : 'd-none'} items-table-header`"
        :tbody-tr-class="setRowClassFunction"
        :select-mode="'single'"
        :fields="fields"
        :items="items"
        :empty-filtered-text="$t('noDataFound')"
        :empty-text="$t('noDataFound')"
        :no-local-sorting="true"
        :sort-by="displayHeader ? propertyName : null"
        :primary-key="propertyKey"
        @row-selected="selectedItem"
        @sort-changed="reloadSort">
        <template
          v-if="!displayHeader"
          #head()>
          <div />
        </template>
      </b-table>
      <b-button
        id="addItem"
        class="btn-sm list-add-button"
        variant="success"
        :disabled="disableTable || disableAddButton"
        @click.prevent="addItem">
        <font-awesome-icon :icon="['fas', 'plus']" />
        {{ $t('common.add') }}
      </b-button>
      <b-pagination
        v-if="items.length"
        :value="currentPage"
        align="center"
        :total-rows="totalElements"
        :per-page="itemsPerPage"
        :disabled="disableTable"
        @change="pageChange" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: []
    },
    itemsPerPage: {
      type: Number,
      default: 5
    },
    totalElements: {
      type: Number,
      default: 5
    },
    propertyName: {
      type: String,
      default: 'name'
    },
    propertyKey: {
      type: String,
      default: 'id'
    },
    displayHeader: {
      type: Boolean,
      default: false
    },
    setRowClassFunction: {
      type: Function,
      default: () => {
      }
    },
    disableTable: {
      type: Boolean,
      default: false
    },
    disableMessageTooltip: {
      type: String,
      default: ''
    },
    currentPage: {
      type: Number,
      default: 1
    },
    search: {
      type: String,
      default: undefined
    },
    disableAddButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sort: 'name',
      sortDirection: 'ASC',
      timer: undefined,
      item: undefined
    };
  },
  computed: {
    fields() {
      return [
        {
          key: this.propertyName,
          label: this.$options.filters.capitalize(this.propertyName),
          sortable: true
        }
      ];
    }
  },
  methods: {
    reloadSort(ctx) {
      this.sort = ctx.sortBy;
      this.sortDirection = ctx.sortDesc ? 'DESC' : 'ASC';
      this.pageChange(1);
    },
    updateSearch(search) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.$emit('search', search.length >= 1 ? search : undefined);
      this.timer = setTimeout(() => this.pageChange(1), 1000);
    },
    selectedItem(items) {
      if (!this.disableTable) {
        this.item = items[0];
        this.$emit('selectedItem', items[0]);
      }
    },
    addItem() {
      this.$emit('addItem');
      this.item ? this.selectItem(this.item) : this.selectedItem([]);
    },
    pageChange(page) {
      const search = this.search;
      const sort = this.sort;
      const sortDirection = this.sortDirection;
      this.$emit('pageChange', page, search, sort, sortDirection);
    },
    selectItem(item) {
      if (item && item[this.propertyName]) {
        const uniqueId = item[this.propertyName];
        const tableId = this.$refs.pageableListing.$el.querySelector('table').id;
        const rowId = `${tableId}__row_${uniqueId}`;
        document.getElementById(rowId.replaceAll(/\s+/g, '_')).click();
      }
    }
  }
};
</script>


