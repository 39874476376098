<template>
  <div class="position-relative">
    <b-button
      :class="phaseVisible ? 'collapse-button' : 'collapse-button collapsed'"
      :aria-expanded="phaseVisible ? 'true' : 'false'"
      aria-controls="collapse-phase"
      @click="phaseVisible = !phaseVisible">
      <i
        v-if="phaseVisible"
        class="fa fa-chevron-up"
        aria-hidden="true" />
      <i
        v-if="!phaseVisible"
        class="fa fa-chevron-down"
        aria-hidden="true" />
    </b-button>
    <h5 :class="phaseVisible ? 'collapse-title-hidden' : 'collapse-title-show'">
      {{ $t('configuration.kit.phases') + " - " + $t('configuration.kit.zones') }}
    </h5>
    <b-collapse
      id="collapse-phase"
      v-model="phaseVisible">
      <b-form-row
        v-for="(phase, index) in phases"
        :key="phase.uuid">
        <b-col
          lg="3"
          class="pr-xl-3">
          <div
            v-if="index === 0"
            class="text-lg-center">
            <h5>
              {{ $t('configuration.kit.phases') }}
            </h5>
          </div>
          <b-form-row>
            <b-col :lg="isVersionModifiable ? 8 : 12">
              <b-form-group :label="$t('formPhase')">
                <app-phase-dropdown
                  :value="phase.phaseType"
                  :overriding-options="filteredPhases()"
                  :disabled="disable() || !isVersionModifiable"
                  :placeholder="$t('formSelectPhase')"
                  @input="phaseTypeChanged(phase.uuid, $event)" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                v-if="isVersionModifiable && !disabled"
                :label="'Remove / Add'">
                <b-button
                  ref="removeButton"
                  :disabled="(phases.length < 2 || disable())"
                  variant="outline"
                  class="btn--no-outline btn-icon btn-icon-minus"
                  @click="checkRemovePhaseInputLine(phase)">
                  <font-awesome-icon
                    :icon="['fas', 'minus-circle']" />
                </b-button>
                <b-button
                  v-if="index === phases.length - 1 && filteredPhases().length >= 1"
                  ref="addButton"
                  :disabled="disable()"
                  variant="outline"
                  class="btn--no-outline btn-icon btn-icon-plus"
                  @click="addPhaseInputLine">
                  <font-awesome-icon
                    :icon="['fas', 'plus-circle']" />
                </b-button>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col lg="8" class="row align-items-end">
              <b-form-group :label="$t('formMinThreshold')" class="col-7">
                <b-form-input
                  :value="phase.minThreshold"
                  :disabled="disable()"
                  type="number"
                  @input="minThresholdChanged(phase.uuid, $event)" />
              </b-form-group>
              <b-form-group :label="$t('formInversed')" class="col-5">
                <b-form-checkbox
                  :checked="phase.inversed"
                  :disabled="disable() || phase.phaseType === 'ANNEAL'"
                  class="form-phase-checkbox"
                  switch
                  size="lg"
                  @input="inversedChanged(phase.uuid, $event)">
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col lg="4" class="row align-items-end">
              <b-form-group :label="$t('formSlopeThreshold')">
                <b-form-input :value="phase.slopeThreshold" :disabled="disable()" type="number"
                  @input="slopeThresholdChanged(phase.uuid, $event)" />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-col>
        <b-col
          lg="9"
          class="pl-xl-5">
          <div
            v-if="index === 0"
            class="text-lg-center">
            <h5>
              {{ $t('configuration.kit.zones') }}
            </h5>
          </div>
          <app-zone-configuration
            ref="appZoneConfiguration"
            :phase-uuid="phase.uuid"
            :zones="phase.zones"
            :used-zones="usedZones()"
            :disabled="disable()"
            @changed="zonesChanged()" />
        </b-col>
      </b-form-row>
    </b-collapse>
    <app-confirmation-modal
      ref="removePhaseModal"
      :title="$t('modal.phase.remove.title')"
      :message="$t('modal.phase.remove.message')"
      :ok-button-name="$t('yes')"
      :cancel-button-name="$t('no')"
      @ok="removePhaseInputLine()" />
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import {PhaseTypeEnum} from '@/enums/PhaseType.enum';
import ZoneConfiguration from '@/components/configuration/tabs/kit-configuration/ZoneConfiguration';
import PhaseDropdown from '@/components/common/PhaseDropdown';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {mapFields} from 'vuex-map-fields';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import {DEFAULT_SLOPE_THRESHOLD} from '../../../../models/Phase'

export default {
  components: {
    'app-phase-dropdown': PhaseDropdown,
    'app-zone-configuration': ZoneConfiguration,
    'app-confirmation-modal': ConfirmationModal
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapFields('configuration', [
      'kit',
      'kitVersion',
      'reactionBuffer',
      'analyser',
      'channel',
      'phases',
      'isKitVersionModifiable'
    ]),
    isVersionModifiable() {
      return this.kitVersion?.kit?.id ? this.kitVersion?.id && this.kitVersion.isEditable ? this.isKitVersionModifiable : false : true;
    }
  },
  beforeMount() {
    this.phases = [ {
      uuid: uuidv4(),
      id: null,
      phaseType: null,
      minThreshold: null,
      slopeThreshold: DEFAULT_SLOPE_THRESHOLD,
      minQuality: null,
      inversed: false,
      zones: [ {
        uuid: uuidv4(),
        name: null,
        minThreshold: null,
        minQuality: null,
        minTemp: null,
        maxTemp: null,
        label: null,
        dataEnum: null
      } ]
    } ];
  },
  data() {
    return {
      phaseVisible: true,
      currentPhases: [],
      PhaseTypeEnum,
      removePhaseUuid: undefined
    };
  },
  mounted() {
    this.currentPhases = cloneDeep(this.phases);
  },
  methods: {
    resetData() {
      this.currentPhases = [];
    },
    initPhases(phases) {
      if (!phases?.length) {
        this.phases = [ {
          uuid: uuidv4(),
          id: null,
          phaseType: null,
          minThreshold: null,
          slopeThreshold: DEFAULT_SLOPE_THRESHOLD,
          minQuality: null,
          inversed: false,
          kitVersion: undefined,
          analyser: undefined,
          zones: [ {
            uuid: uuidv4(),
            name: null,
            minThreshold: null,
            minQuality: null,
            minTemp: null,
            maxTemp: null,
            label: null,
            dataEnum: null
          } ]
        } ];
        this.currentPhases = cloneDeep(this.phases);
        this.zonesChanged();

        return;
      }

      phases.forEach(p => {
        p.uuid = uuidv4();
        p.zones.forEach(z => z.uuid = uuidv4());
      });

      this.$store.dispatch('configuration/setPhases', { phases });
      this.currentPhases = cloneDeep(this.phases);
      this.zonesChanged();
    },
    usedZones() {
      return this.phases.flatMap(p => p.zones).map(z => z.name).filter(n => n !== undefined && n !== null);
    },
    addPhaseInputLine() {
      this.$store.dispatch('configuration/addNewPhase', { filteredPhaseTypes: this.filteredPhases() });
    },
    checkRemovePhaseInputLine(phase) {
      this.removePhaseUuid = phase.uuid;
      const zones = phase.zones.filter(z => z.name);
      if (zones?.length) {
        this.$refs.removePhaseModal.showModal();
      } else {
        this.removePhaseInputLine();
      }
    },
    removePhaseInputLine() {
      this.$store.dispatch('configuration/removePhase', { uuid: this.removePhaseUuid });
      this.zonesChanged();
    },
    disable() {
      return this.disabled
          || !this.kit
          || !this.kitVersion
          || this.kitVersion.lots?.filter(l => l.active)?.length > 0
          || !this.analyser
          || !this.reactionBuffer
          || !this.channel?.name
          || this.channel.name === ''
          || !this.kitVersion?.isEditable;
    },
    edited() {
      return !isEqual(this.currentPhases, this.phases);
    },
    filteredPhases() {
      const usedPhases = this.phases.map(p => p.phaseType);

      return PhaseTypeEnum.filter(p => !usedPhases.includes(p)).slice(0, 5);
    },
    zonesChanged() {
      const zones = this.phases.flatMap(p => p.zones).map(z => z.name).filter(n => n !== undefined && n !== null);
      this.$emit('zonesChanged', zones);
    },
    phaseTypeChanged(uuid, value) {
      if (value === 'ANNEAL') {
        this.$store.dispatch('configuration/setPhaseInversed', { uuid, inversed: false });
      }
      this.$store.dispatch('configuration/setPhaseType', { uuid, phaseType: value, analyser: this.analyser });
    },
    minThresholdChanged(uuid, value) {
      this.$store.dispatch('configuration/setPhaseMinThreshold', { uuid, minThreshold: value });
    },
    slopeThresholdChanged(uuid, value) {
      this.$store.dispatch('configuration/setPhaseSlopeThreshold', { uuid, slopeThreshold: value });
    },
    inversedChanged(uuid, value) {
      this.$store.dispatch('configuration/setPhaseInversed', { uuid, inversed: value });
    }
  }
};
</script>
