<template>
  <multiselect
    v-model="template"
    track-by="name"
    label="name"
    :searchable="true"
    :options="availableTemplates"
    :placeholder="$t('formSelectWellConfigurationTemplate')"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)" />
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    ...mapState('template', [
      'availableTemplates'
    ]),
    template: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    customLabel (value) {
      return this.$options.filters.capitalize(this.$t(value));
    }
  }
};
</script>
